import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET_PROFILING_INFO } from "../../../axios/apiendPoint";

const initialState = {
  isLoading: false,
  profileData: {},
};

export const fetchProfileData = createAsyncThunk(
  "profile/fetchProfileData",
  async ({ axiosGet, setIsOpen, toggle }) => {
    try {
      const response = await axiosGet(GET_PROFILING_INFO);
      if (response) {
        setIsOpen(true);
        return response?.data;
      } else toggle();
    } catch (error) {
      toggle();
      return error.response?.data || error.message;
    }
  },
);

export const profileDataSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetProfileData: (state) => {
      state.isLoading = false;
      state.profileData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProfileData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.profileData = action.payload;
      })
      .addCase(fetchProfileData.rejected, (state, action) => {
        state.isLoading = false;
        console.error("Error fetching profile data:", action.payload);
      });
  },
});

export const { resetProfileData } = profileDataSlice.actions;
export default profileDataSlice.reducer;
