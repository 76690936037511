import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../../../components/Modal/CustomModal/CustomModal";
import {
  toggleQuestionModal,
  toggleReadTheoryModal,
  toggleRegulationModal,
} from "./store";
import CustomButton from "../../../../../components/Button/CustomButton/CustomButton";

const TrainingRuleModal = () => {
  const dispatch = useDispatch();

  const trainingModalDetails = useSelector(
    (state) => state.trainingModalReducer,
  );
  const { isRegulationModalOpen } = trainingModalDetails || {};

  const toggle = () => dispatch(toggleRegulationModal());

  const handleToGoBack = () => {
    toggle();
    dispatch(toggleReadTheoryModal());
  };

  const handleToOpenQuestionModal = () => {
    toggle();
    dispatch(toggleQuestionModal());
  };

  return (
    <Fragment>
      <CustomModal
        isOpen={isRegulationModalOpen}
        // toggle={toggle}
        width="649px"
        modalbody={
          <Fragment>
            <div className="modal_header">Rules and Regulations</div>
            <ul className="d-flex flex-column align-items-start training_rule_regulation_list">
              <li className="list-item-rules">
                You are not allowed to move forward until you have answered a
                question.
              </li>
              <li className="list-item-rules">
                You can not re-attempt a question once you have submitted the
                answer.
              </li>
              <li className="list-item-rules">
                Each question has only one correct answer.
              </li>
              <li className="list-item-rules">
                Your test results will be shown after the test has been
                completed.
              </li>
              <li className="list-item-rules">
                The passing and failing is upon GroMo's discretion.
              </li>
              <li className="list-item-rules">No Negative marking.</li>
            </ul>
            <hr className="footer_line" />
            <div className="d-flex justify-content-between align-items-center">
              <CustomButton
                buttonName="Back"
                handleClick={handleToGoBack}
                className="training_rule_back_btn"
                btnNameClass="trainging_rule_back_name"
                alignCenter
              />
              <CustomButton
                buttonName="Start Now"
                handleClick={handleToOpenQuestionModal}
                alignCenter
              />
            </div>
          </Fragment>
        }
      />
    </Fragment>
  );
};

export default TrainingRuleModal;
