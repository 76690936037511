import React from "react";
import "./index.scss";
import BookingCoin from "../../assets/lottie/bookingCoin.json";
import { ReactComponent as DownloadIcon } from "../../assets/images/common/download_file.svg";
import LottiePlayer from "../../components/LottiePlayer/LottiePlayer";
import { getInsurerLogo } from "../../constants/InsurerLogoMap";

const BookingTableMweb = ({ bookingList, handleClick }) => {
  return (
    <div className="mweb-booking-card-top">
      {bookingList.map((item, idx) => (
        <div
          key={idx}
          className={`mweb-booking-card-main ${
            idx === bookingList.length - 1 ? "last-card-Mbooking" : ""
          }`}
        >
          <div
            className="mweb-booking-card-inner"
            style={{
              height: "132px",
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: "20px",
              margin: "2% 3%",
              cursor: "pointer",
            }}
            onClick={() => handleClick(item)}
          >
            <div
              className="mweb-bookingcard-sec-1"
              style={{
                marginTop: "2%",
                display: "flex",
                //   alignItems: "center",
                //   justifyContent: "space-evenly",
                // gap: "30px",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="mweb-leftside-booking">
                <div
                  className="mweb-product-image"
                  style={{ width: "30px", height: "30px" }}
                >
                  <img
                    src={getInsurerLogo(item?.insurerId)}
                    alt={item?.insurerName}
                    className="logo_image"
                  />
                </div>
                <div
                  className="mweb-vechale-details"
                  style={{
                    fontSize: "10px",
                    fontWeight: "600",
                    lineHeight: "12px",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  {item?.registrationNumber}
                  <br />

                  <span
                    style={{ color: "rgba(102, 102, 102, 1)", fontSize: "8px" }}
                  >
                    {item?.make} {item?.model}
                  </span>
                </div>
              </div>
              <div className="mweb-booking-coin">
                <div className="coin-container">
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      borderRadius: "17px",
                      border: "1px solid",
                      borderColor:
                        item?.status === "BOOKED" ? "#32C053" : "#FF0000",
                      minWidth: "62px",
                      color: item?.status === "BOOKED" ? "#32C053" : "#FF0000",
                      height: "24px",
                      padding: "4px 8px",
                      justifyContent: "space-between",
                      paddingRight: "8px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "24px",
                        backgroundColor:
                          item?.status === "BOOKED" ? "#32C053" : "#FF0000",
                        borderRadius: "50%",
                        right: "8px",
                        height: "24px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          transform: "translate(-26%, -25%)",
                        }}
                      >
                        <LottiePlayer
                          animationData={BookingCoin}
                          height={50}
                          width={50}
                        />
                      </div>
                    </div>
                    <span>{item?.activityPoint ?? "0"}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mweb-bookingcard-sec-2">
              <div className="mweb-customer-details" style={{ width: "60%" }}>
                <span className="mweb-customer-details-text">Customer</span>
                <span className="mweb-customer-details-text-2">
                  {item?.customerName}
                </span>
              </div>
              <div className="mweb-customer-details">
                <span className="mweb-customer-details-text">Booking Id</span>
                <span className="mweb-customer-details-text-2">
                  {item?.bookingId}
                </span>
              </div>

              <div className="mweb-customer-details">
                <span className="mweb-customer-details-text">Premium</span>
                <span className="mweb-customer-details-text-2">
                  {item?.premium || "-"}
                </span>
              </div>
            </div>
          </div>
          <div className="m-web-booking-card-footer">
            <div
              className="mweb-status"
              style={{
                fontSize: "10px",
                color: "rgba(51, 51, 51, 1)",
                fontWeight: "400",
              }}
            >
              Status: {""}
              <span
                style={{
                  fontSize: "10px",
                  fontWeight: "600",
                  color: item.color
                    ? item.color
                    : item.status === "BOOKED"
                      ? "rgba(50, 192, 83, 1)"
                      : item.status === "CANCELLED"
                        ? "#e82727"
                        : "black",
                }}
              >
                {item.status === "BOOKED"
                  ? "Booked"
                  : item?.status === "CANCELLED"
                    ? "Cancelled"
                    : ""}
              </span>
            </div>
            <div>
              <DownloadIcon width={14} height={14} />
              <a
                href={item?.policyCopyUrl}
                target="_blank"
                rel="noreferrer"
                style={{
                  outline: "none",
                  textDecoration: "none",
                  border: "none",
                }}
              >
                <span
                  style={{
                    fontSize: "10px",

                    color: "black",
                  }}
                >
                  {" "}
                  Download PDF
                </span>
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BookingTableMweb;
