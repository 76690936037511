import React, { useEffect, useState } from "react";
import "./index.scss";
import LogoFull from "../../assets/logo/logo_full.svg";
import usericon from "../../assets/images/navbar/Ellipse 1006.svg";
import bellicon from "../../assets/images/navbar/Mobbell.svg";
import hamburger from "../../assets/images/navbar/MobHamburger.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { getCurrentPageName } from "../../constants/Constants";
import { Heart } from "react-feather/dist";
import Information from "../Navbar/Information";
import MobileSidebarMenu from "./MobileNavbarModal/MobileSidebarMenu";
import { useSelector } from "react-redux";

const MobileNavbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const url = getCurrentPageName(location.pathname);
  const { profilePic } = useSelector((state) => state.dashboardModalReducer);

  // State to track if user is in favorites page
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    // Update isFavorite based on current route
    setIsFavorite(location.pathname.includes("grid/favorites"));
  }, [location.pathname]);

  const handleGoToFavorites = () => {
    // Toggle between grid and favorites pages
    navigate(isFavorite ? "/grid" : "/grid/favorites");
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  return (
    <div className="navbar-main">
      <div className="navbar-inner">
        <div className="navbar-logo">
          <div className="icon-toggle">
            <span style={{ position: "relative" }}>
              <img
                src={
                  profilePic ? `data:image/jpeg;base64,${profilePic}` : usericon
                }
                alt="User Icon"
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              />
            </span>
            <span
              onClick={toggleModal}
              style={{ position: "absolute", top: "19px", left: "27px" }}
            >
              <img src={hamburger} alt="Menu" />
            </span>
          </div>
          <div>
            <span className="bima-image">
              <img
                src={LogoFull}
                alt="Company Logo"
                style={{ width: "107px", height: "24px" }}
              />
            </span>
          </div>
        </div>

        <div className="fav-info d-flex align-items-center justify-content-between py-0 my-0 gap-2">
          {location.pathname.includes("grid") && <Information />}
          {location.pathname.includes("grid") && (
            <button
              className="icon_button"
              onClick={handleGoToFavorites}
              style={{
                background: "transparent",
                border: "none",
                cursor: "pointer",
                padding: 0,
              }}
            >
              <Heart
                color={isFavorite ? "#df0000" : "gray"}
                fill={isFavorite ? "#df0000" : "transparent"}
              />
            </button>
          )}
          <div>
            <img
              src={bellicon}
              alt="Notifications"
              style={{ height: "26px", paddingLeft: "4px" }}
            />
          </div>
        </div>
      </div>
      <MobileSidebarMenu isOpen={isModalOpen} toggle={toggleModal} />
    </div>
  );
};

export default MobileNavbar;
