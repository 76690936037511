import React, { Fragment, useState, useEffect } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewQuoteRequestPayload,
  newQuoteRequestHeader,
  policyTypeOptions,
  uploadFileAllowedFormats,
} from "../../constants/Constants";
import NewQuoteForm from "./NewQuoteForm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
  fetchNewQuoteInsurersAddOnsList,
  setShowRequestNewQuoteModal,
} from "./Store/store";
import { fetchQuoteRequestData } from "../QuoteRequest/Store/store";
import useAxiosPrivate from "../../axios/services";
import { toast } from "react-hot-toast";
import { SEND_NEW_QUOTE_REQUEST_FORM } from "../../axios/apiendPoint";
import VerifiedModal from "../../components/Modal/VerifiedModal/VerifiedModal";
import CustomButton from "../../components/Button/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { navRoutes } from "../../router/Url";
import BlurOverlay from "../../components/BlurOverlay/BlurOverlay";

const RequestNewQuoteModal = () => {
  const dispatch = useDispatch();
  const { axiosGet2, axiosFormDataPost2 } = useAxiosPrivate();
  const navigate = useNavigate();

  const showNewQuoteRequestModal = useSelector(
    (state) => state.newQuoteRequestReducer.showRequestNewQuoteModal,
  );

  const getQuoteRequestDetails = () =>
    dispatch(fetchQuoteRequestData({ axiosGet: axiosGet2 }));

  const [activeHeader, setActiveHeader] = useState({});
  const [veichleState, setVeichleState] = useState(["Vehicle is New"]);
  const [veichleType, setVeichleType] = useState(["Comprehensive Policy"]);
  const [previousPolicyFlag, setPreviousPolicyFlag] = useState(["No"]);
  const [claimPolicyFlag, setClaimPolicyFlag] = useState(["No"]);

  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [createdQuoteId, setCreatedQuoteId] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const getInsurersList = () => dispatch(fetchNewQuoteInsurersAddOnsList({ axiosGet: axiosGet2, params: veichleType[0] === "Comprehensive Policy" ? "policyType=COMP" : "policyType=TP" }));

  const newVeichleSchema = yup.object().shape({
    name: yup.string().required("Customer name is required"),
    mobileNumber: yup
      .string()
      .required("Customer number is required")
      .matches(/^[6789]\d{9}$/, "Invalid mobile number"),
    email: yup
      .string()
      .required("Customer email is required")
      .test("email", "Invalid email", (value) => {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
      }),
    rtoCode: yup.string().required("RTO code is required"),
    selectInsurer: yup
      .array()
      .required("Please select at least one insurer")
      .of(
        yup.object().shape({
          label: yup.string().required("Please select at least one insurer"),
          value: yup.string().required("Please select at least one insurer"),
        }),
      )
      .min(1, "Please select at least one insurer"),
    idv:
      veichleType[0] === "Comprehensive Policy"
        ? yup.string().required("IDV is required")
        : yup.string().nullable(),
    addOns: yup
      .array()
      .nullable()
      .of(
        yup.object().shape({
          label: yup.string().required("Please select at least one insurer"),
          value: yup.string().required("Please select at least one insurer"),
        }),
      ),
    manufacturingYear: yup.string().required("Manufacturing year is required"),
    INVOICE_COPY: yup
      .mixed()
      .required("Please select a file")
      .test("fileType", "Invalid file format", (value) => {
        if (!value) return false;
        const supportedFormats = uploadFileAllowedFormats;
        return supportedFormats.includes(value.type);
      })
      .test("fileSize", "The file is too large", (value) => {
        return value && value.size <= 5242880; // 5MB maximum file size
      }),
    PERMIT:
      activeHeader?.key === "BUS"
        ? yup
            .mixed()
            .optional()
            .nullable()
            .test("fileType", "Invalid file format", (value) => {
              if (!value) return true;
              const supportedFormats = uploadFileAllowedFormats;
              return supportedFormats.includes(value.type);
            })
            .test("fileSize", "The file is too large", (value) => {
              if (!value) return true;
              return value && value.size <= 5242880; // 5MB maximum file size
            })
        : yup.string().nullable(),
  });

  const oldVeichleSchema = yup.object().shape({
    name: yup.string().required("Customer name is required"),
    mobileNumber: yup.string().required("Customer number is required"),
    email: yup
      .string()
      .required("Customer email is required")
      .test("email", "Invalid email", (value) => {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
      }),
    registrationNumber: yup
      .string()
      .required("Registration number is required"),
    registrationDate: yup
      .date()
      .nullable()
      .required("Registration date is required"),
    manufacturingYear: yup.string().required("Manufacturing year is required"),
    selectInsurer: yup
      .array()
      .required("Please select at least one insurer")
      .of(
        yup.object().shape({
          label: yup.string().required("Please select at least one insurer"),
          value: yup.string().required("Please select at least one insurer"),
        }),
      )
      .min(1, "Please select at least one insurer"),
    addOns: yup
      .array()
      .nullable()
      .of(
        yup.object().shape({
          label: yup.string().required("Please select at least one insurer"),
          value: yup.string().required("Please select at least one insurer"),
        }),
      ),
    idv:
      veichleType[0] === policyTypeOptions.COMPREHENSIVE ||
      veichleType[0] === policyTypeOptions.SAOD
        ? yup.string().required("IDV is required")
        : yup.string().nullable(),
    // pyp can be true or false, but it is required
    isPyp: yup.boolean().required(),
    pypExpiryDate:
      previousPolicyFlag[0] === "Yes"
        ? yup
            .date("Expiry Date is Required")
            .typeError("Expiry Date is Required")
            .required("Expiry Date is Required")
        : yup.string().nullable(),
    ncb:
      previousPolicyFlag[0] === "Yes" &&
      claimPolicyFlag[0] === "No" &&
      (veichleType[0] === policyTypeOptions.COMPREHENSIVE ||
        veichleType[0] === policyTypeOptions.SAOD)
        ? yup
            .number("NCB is Required")
            .typeError("NCB is Required")
            .required("NCB is required")
            .min(0, "NCB can't be less than 0")
            .max(100, "NCB can't be more than 100")
        : yup.string().nullable(),
    RC: yup
      .mixed()
      .required("Please select a file")
      .test("fileType", "Invalid file format", (value) => {
        if (!value) return false;
        const supportedFormats = uploadFileAllowedFormats;
        return supportedFormats.includes(value.type);
      })
      .test("fileSize", "The file is too large", (value) => {
        return value && value.size <= 5242880; // 5MB maximum file size
      }),
    PYP:
      previousPolicyFlag[0] === "Yes"
        ? yup
            .mixed()
            .required("Please select a file")
            .test("fileType", "Invalid file format", (value) => {
              if (!value) return false;
              const supportedFormats = uploadFileAllowedFormats;
              return supportedFormats.includes(value.type);
            })
            .test("fileSize", "The file is too large", (value) => {
              return value && value.size <= 5242880;
            })
        : yup.string().nullable(),
    PERMIT:
      activeHeader?.key === "BUS"
        ? yup
            .mixed()
            .optional()
            .nullable()
            .test("fileType", "Invalid file format", (value) => {
              if (!value) return true;
              const supportedFormats = uploadFileAllowedFormats;
              return supportedFormats.includes(value.type);
            })
            .test("fileSize", "The file is too large", (value) => {
              if (!value) return true;
              return value && value.size <= 5242880; // 5MB maximum file size
            })
        : yup.string().nullable(),
  });

  const {
    control: controlOld,
    handleSubmit: handleSubmitOld,
    formState: { errors: errorsOld },
    reset: resetOld,
    setValue: setValueOld,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(oldVeichleSchema),
    defaultValues: {
      name: "",
      mobileNumber: "",
      email: "",
      registrationNumber: "",
      registrationDate: "",
      selectInsurer: null,
      addOns: null,
      idv: null,
      isPyp: false,
      pypExpiryDate: null,
      INVOICE_COPY: null,
      OTHER_DOC: null,
      PYP: null,
      RC: null,
      PERMIT: null,
      manufacturingYear: "",
    },
  });

  const {
    control: controlNew,
    handleSubmit: handleSubmitNew,
    formState: { errors: errorsNew },
    reset: resetNew,
    setValue: setValueNew,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(newVeichleSchema),
    defaultValues: {
      name: "",
      mobileNumber: "",
      email: "",
      rtoCode: "",
      selectInsurer: null,
      addOns: null,
      idv: null,
      INVOICE_COPY: null,
      OTHER_DOC: null,
      PERMIT: null,
      manufacturingYear: "",
    },
  });

  const handleToReset = () => {
    resetNew();
    resetOld();
    setActiveHeader(newQuoteRequestHeader[0]);
    setVeichleState(["Vehicle is New"]);
    setVeichleType(["Comprehensive Policy"]);
    setPreviousPolicyFlag(["No"]);
    setClaimPolicyFlag(["No"]);
  };

  const handleSetValues = (field, value) => {
    if (veichleState[0] === "Vehicle is New") {
      setValueNew(field, value);
    } else {
      setValueOld(field, value);
    }
  };

  const handleToSubmit = async (data) => {
    const selectedInsurers = data?.selectInsurer?.map((aItem) => {
      return aItem.value;
    });

    const finalAddOns = data?.addOns?.map((item) => {
      return item.value;
    });
    const payload = {
      product: activeHeader.key,
      vehicleType: veichleState[0] === "Vehicle is New" ? "NEW" : "OLD",
      policyType:
        veichleType[0] === "Comprehensive Policy"
          ? "COMP"
          : veichleType[0] === "Third Party Policy"
            ? "TP"
            : "SAOD",
      ...data,
      insurerList: selectedInsurers,
      addOns: finalAddOns,
    };

    if (veichleState[0] !== "Vehicle is New") {
      payload.pypExpiryDate = data.pypExpiryDate;
      payload.isPyp = previousPolicyFlag[0] === "No" ? false : true;
      if (
        veichleType[0] === policyTypeOptions.COMPREHENSIVE ||
        veichleType[0] === policyTypeOptions.SAOD
      ) {
        payload.pypClaim = claimPolicyFlag[0] === "No" ? false : true;
      }
    }

    const formData = createNewQuoteRequestPayload(payload);

    const response = await axiosFormDataPost2(
      SEND_NEW_QUOTE_REQUEST_FORM,
      formData,
    );
    if (response?.success) {
      toast.success(response?.msg);
      setCreatedQuoteId(response?.data?.quoteId);
      setIsFormSubmited(true);
      handleToReset();
      getQuoteRequestDetails();
    }
  };

  const handleToNavigateToQuoteList = () => {
    setIsFormSubmited(false);
    setCreatedQuoteId("");
    dispatch(setShowRequestNewQuoteModal());
    navigate(navRoutes.quoteRequestPage);
  };

  useEffect(() => {
    setActiveHeader(newQuoteRequestHeader[0]);
  }, [showNewQuoteRequestModal]);

  useEffect(() => {
    if (showNewQuoteRequestModal) {
      if (activeHeader?.key && veichleType[0]) {
        let params = `product=${activeHeader.key}&policyType=${veichleType[0] === "Comprehensive Policy" ? "COMP" : "TP"}`;
        dispatch(
          fetchNewQuoteInsurersAddOnsList({
            axiosGet: axiosGet2,
            params: params,
          }),
        );
      }
    }
  }, [showNewQuoteRequestModal, veichleType, activeHeader]);

  return (
    <Fragment>
      {showNewQuoteRequestModal && (
        <>
          <div
            className={
              isFormSubmited
                ? "requestNewQuoteModalContainer submittedModal"
                : "requestNewQuoteModalContainer"
            }
          >
            {isFormSubmited ? (
              <div>
                <VerifiedModal
                  verifiedText="Quote Request Raised Successfully!"
                  verifiedDescription={
                    <div>
                      <span style={{ color: "#808080" }}>Ticket Id :- </span>
                      {createdQuoteId}
                    </div>
                  }
                />

                {/* <div>Ticket Id :- {ticked.id} </div> */}
                <div className="d-flex align-items-center justify-content-center">
                  <CustomButton
                    buttonName="Go To My Requests"
                    handleClick={handleToNavigateToQuoteList}
                    alignCenter
                  />
                </div>
              </div>
            ) : (
              <Fragment>
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <div className="modal_header">
                    {newQuoteRequestHeader?.map((item, index) => (
                      <div
                        key={index}
                        className={`d-flex align-items-center justify-content-center flex-column header_items cursor_pointer
                    ${activeHeader?.id === index ? "active_header_item" : ""}`}
                        onClick={() => setActiveHeader(item)}
                      >
                        {/* <img src={item.image} alt={item.id} height={"12px"} width={"20px"} /> */}
                        <item.image />
                        <div
                          className={
                            activeHeader?.id === index
                              ? "active_veichle_name"
                              : "veichle_name"
                          }
                        >
                          {item.name}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <NewQuoteForm
                  veichleState={veichleState}
                  setVeichleState={setVeichleState}
                  handleSubmit={
                    veichleState[0] === "Vehicle is old"
                      ? handleSubmitOld
                      : handleSubmitNew
                  }
                  handleToSubmit={handleToSubmit}
                  errors={
                    veichleState[0] === "Vehicle is old" ? errorsOld : errorsNew
                  }
                  control={
                    veichleState[0] === "Vehicle is old"
                      ? controlOld
                      : controlNew
                  }
                  reset={handleToReset}
                  resetField={handleSetValues}
                  setVeichleType={setVeichleType}
                  veichleType={veichleType}
                  previousPolicyFlag={previousPolicyFlag}
                  setPreviousPolicyFlag={setPreviousPolicyFlag}
                  claimPolicyFlag={claimPolicyFlag}
                  setClaimPolicyFlag={setClaimPolicyFlag}
                  activeHeader={activeHeader}
                />
              </Fragment>
            )}
          </div>
          <BlurOverlay
            isBlur={true}
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="100%"
          />
        </>
      )}
    </Fragment>
  );
};

export default RequestNewQuoteModal;
