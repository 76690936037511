/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./index.scss";
import CircularProgressBar from "../../../components/CircularProgressBar/CircularProgressBar";
import CustomButton from "../../../components/Button/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";

// Images Import
import Upload from "../../../assets/images/common/upload_file.svg";
import CheckCircle from "../../../assets/images/common/check_circle.svg";
import ErrorOutline from "../../../assets/images/common/error_outline.svg";
import { ChevronRight } from "react-feather";
import {
  dashboardUserPageStates,
  modalButtonNameObj,
  partnerCompletionPercentages,
} from "../../../constants/Constants";
import MarksheetModal from "../../../components/Modal/DocumentUploadModal/MarksheetModal";
import AadharModal from "../../../components/Modal/AadharModal/AadharModal";
import BankDetailsModal from "../../../components/Modal/BankDetailsModal/BankDetailsModal";
import GstDetailsModal from "../../../components/Modal/GstModal/GstModal";
import NocModal from "../../../components/Modal/NocModal/NocModal";
import VerificationPendingModal from "../../../components/Modal/VerificationPendingModal/VerificationPendingModal";
import { setIsAadharModalDetailsToggle } from "./modalSectionStore/store";
import AadharDetails from "../../../components/Modal/AadharModal/AadharDetails";
import { isMobileDevice } from "../../../utils/utils";
import Arrow from "../../../assets/images/Dashboard/tabler_arrow-up.svg";

const ModalSection = () => {
  const dispatch = useDispatch();
  // States
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isNocModalOpen, setIsNocModalOpen] = useState(false);
  const [isVerificationPendingModalOpen, setIsVerificationModalOpen] =
    useState(false);
  const modalDetails = useSelector(
    (state) => state.dashboardModalReducer.modalStateDetails,
  )?.data;
  const currentModal = useSelector(
    (state) => state.dashboardModalReducer.currentModal,
  );

  const { page, states = [], name = null } = modalDetails || {};

  //------------------------------------------------- Function starts from here
  // Function to toggle modal
  const toggleModal = () => {
    if (page === dashboardUserPageStates.ADDRESS_DETAILS_PAGE) {
      dispatch(setIsAadharModalDetailsToggle());
    } else {
      setIsModalOpen(!isModalOpen);
    }
  };

  // function to render steps
  const renderStepsList = () => {
    return (
      <div className="  col-12 inner-model-items  d-flex flex-wrap align-items-center justify-content-start w-100 ">
        {states?.map((item, index) => (
          <div
            className="  d-flex align-items-center justify-content-center steps_content  "
            key={index}
          >
            <img
              src={item.status === "Fail" ? ErrorOutline : CheckCircle}
              alt="circle"
              className="me-1"
            />{" "}
            <span className="step_name">{item.stateName}</span>
            {index !== states?.length - 1 && (
              <div className="each_modal_item">
                <ChevronRight />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderCurrentModal = () => {
    if (isModalOpen) {
      switch (currentModal) {
        case "MARKSHEET_UPLOAD_PAGE":
          return <MarksheetModal isOpen={isModalOpen} toggle={toggleModal} />;
        case "AADHAAR_VERIFY_PAGE":
          return <AadharModal isOpen={isModalOpen} toggle={toggleModal} />;
        case "BANK_VERIFICATION_PAGE":
          return <BankDetailsModal isOpen={isModalOpen} toggle={toggleModal} />;
        case "CANCEL_CHEQUE_UPLOAD_PAGE":
          return <BankDetailsModal isOpen={isModalOpen} toggle={toggleModal} />;
        case "GST_REGISTRATION_PAGE":
          return <GstDetailsModal isOpen={isModalOpen} toggle={toggleModal} />;

        default:
          return null;
      }
    }
  };

  //------------------------------------------------- Function ends here

  useEffect(() => {
    renderCurrentModal();
  }, [isModalOpen]);

  useEffect(() => {
    if (page === "NOC_UPLOAD_PAGE") {
      setIsNocModalOpen(true);
      // setIsVerificationModalOpen(false);
      // setIsModalOpen(false);
    }
    if (page === "NOC_VERIFICATION_PAGE") {
      setIsNocModalOpen(false);
      setIsVerificationModalOpen(true);
      // setIsNocModalOpen(false);
      // setIsVerificationModalOpen(false);
      // setIsModalOpen(false);
    }
    if (page === "ADDRESS_DETAILS_PAGE") {
      dispatch(setIsAadharModalDetailsToggle());
    }
  }, [page]);

  return (
    <div className="modal_section_container card w-100 d-flex flex-wrap align-items-center justify-content-between gap-4">
      {/* Left section */}
      <div className="set-model row w-100 d-flex align-items-start justify-content-between">
        <div className="col-12 px-0 mx-0">
          <div className="modal_info d-flex align-items-start justify-content-between gap-5 w-100">
            <div className="d-flex align-items-start justify-content-start">
              <CircularProgressBar
                percentage={partnerCompletionPercentages[page] ?? 30}
                text={`${partnerCompletionPercentages[page] ?? 30}%`}
              />
              <div className="p-0  ml_24 user_details row">
                <div className="col-12">
                  {!isMobileDevice() && (
                    <span className="user_name_headeing"> Hey {name}</span>
                  )}
                  <br />
                  <span className="complete_profile_heading">
                    Please complete your Profile to become a verified partner.
                  </span>
                </div>
                {isMobileDevice() && modalButtonNameObj[page] ? (
                  <span
                    type="button"
                    onClick={toggleModal}
                    className="finish-setting-mweb mt-2"
                    style={{
                      color: "#0691FC",
                      fontSize: "14px",
                      cursor: "pointer",
                      lineHeight: "21px",
                      fontWeight: "400",
                    }}
                  >
                    {modalButtonNameObj[page]}
                    <img src={Arrow} alt="arrow" />
                  </span>
                ) : null}
                {isMobileDevice() ? null : (
                  <div className="col-lg-12 steps_position">
                    {renderStepsList()}
                  </div>
                )}
              </div>
            </div>

            {/* Right Section */}
            {modalButtonNameObj[page] ? (
              <CustomButton
                buttonName={
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={Upload} alt="upload" className="me-1" />{" "}
                    {modalButtonNameObj[page]}
                  </div>
                }
                handleClick={toggleModal}
                className="partner_journey_upload_btn"
              />
            ) : null}
          </div>
        </div>
      </div>

      {renderCurrentModal()}

      {/* Aadhar Details Page */}
      <AadharDetails />

      {/* Noc Modal */}
      <NocModal isOpen={isNocModalOpen} />

      {/* Verification Pending Modal */}
      <VerificationPendingModal isOpen={isVerificationPendingModalOpen} />
    </div>
  );
};

export default ModalSection;
