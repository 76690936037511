import React from "react";
import { Check } from "react-feather";

const CustomField = ({ options, setvalue, value, ismulti }) => {
  //   For single check
  const handleSingleChecked = (id) => setvalue([id]);

  //   For Multi select
  const handleClick = (id) => {
    if (value?.includes(id)) {
      setvalue(value?.filter((item) => item !== id));
    } else {
      setvalue([...value, id]);
    }
  };
  return (
    <div className="d-flex gap-1 flex-wrap align-items-center">
      {options?.map((item, index) => {
        return (
          <div
            onClick={() =>
              ismulti ? handleClick(item) : handleSingleChecked(item)
            }
            size={12}
            key={index}
            color={value?.includes(item) ? "#0691FC" : "#F2F2F2"}
            className={
              value?.includes(item)
                ? "custom_badge cursor_pointer mb-3 active_badge"
                : "custom_badge cursor_pointer mb-3"
            }
          >
            {value?.includes(item) && (
              <Check size={15} color="white" className="custom_badge_check" />
            )}
            {item}
          </div>
        );
      })}
    </div>
  );
};

export default CustomField;
