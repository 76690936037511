import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AllRoutes } from "./routes";

const Router = ({ isPartnerRegistered }) => {
  const isCookiePresent = document.cookie.includes("token");
  if (!(!process.env.NODE_ENV || process.env.NODE_ENV === "development")) {
    if (!isCookiePresent) {
      // Case 1 - Redirect to a specific link if the cookie is not present or not valid
      window.location.href = `${process.env.REACT_APP_LANDING_WEBSITE_URL}`;
      return null; // Return null to prevent rendering the React components
    }
  }

  return (
    <Routes>
      {AllRoutes.map((item, index) => {
        return (
          <Route
            key={index}
            path={item.path}
            element={
              item.isRegisteredRequired ? (
                isPartnerRegistered ? (
                  item.element
                ) : (
                  <Navigate to="/dashboard" replace />
                )
              ) : (
                item.element
              )
            }
          />
        );
      })}
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
};

export default Router;
