import axios from "axios";
import { BASE_URL, BASE_URL2, JARVIS_BASE_URL } from "./apiendPoint";

// const customAxios = axios.create({
//   baseURL: BASE_URL,
// });

export default axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  // withCredentials: true,
});

export const axiosPrivate2 = axios.create({
  baseURL: BASE_URL2,
  headers: { "Content-Type": "application/json" },
  // withCredentials: true,
});

export const axiosJarvis = axios.create({
  baseURL: JARVIS_BASE_URL,
  headers: { "Content-Type": "application/json" },
  // withCredentials: true,
});

// export const axiosLogout = axios.create({
//   baseURL: AUTH_BASE_URL,
//   withCredentials: true,
// });
