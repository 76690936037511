import React from "react";
import "./index.scss";
import { productTypeImageObj } from "../../../constants/Constants";
import { ChevronRight } from "react-feather";

const MappingCardMweb = ({ data }) => {
  return (
    <div className="mapping-card-mweb">
      <div className="mapping-header-mweb">
        <div className="vehicle-container-mweb">
          <div className="vehicle-image-mweb">
            {productTypeImageObj[data.product]}
          </div>
          <div className="mapping-number-mweb">
            <span className="mapping-registration-mweb">
              {data.registrationNumber}
            </span>
            <span className="mapping-product-mweb">{data.product}</span>
          </div>
        </div>
        <div className="view-more-container-mweb">
          <span className="view-more-mweb">View more</span>
          <div className="chevron-container">
            <ChevronRight size={20} color="#0691FC" />
          </div>
        </div>
      </div>
      <div className="mapping-details-mweb">
        <div className="mapping-item-mweb">
          <span className="label-mweb">Mapping Id </span>
          <span className="value-mweb">
            {/* {new Date(data.mappingCreationTime).toLocaleDateString()} */}
            {data.mappingId}
          </span>
        </div>
        <div className="mapping-item-mweb">
          <span className="label-mweb">Policy Number</span>
          <span className="mapping-policy-mweb">{data.policyNumber}</span>
        </div>
        <div className="mapping-item-mweb">
          <span className="label-mweb">Date</span>
          <span className="value-mweb">
            {new Date(data.mappingCreationTime).toLocaleDateString()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MappingCardMweb;
