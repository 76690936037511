import React, { Fragment, useState } from "react";
import "./index.scss";
import CustomModal from "../CustomModal/CustomModal";
import Noc from "../../../assets/images/modal/noc.svg";
import { useDispatch, useSelector } from "react-redux";
import { Info } from "react-feather";
import UploadButton from "../../Button/UploadButton/UploadButton";
import useAxiosPrivate from "../../../axios/services";
import { UPLOAD_NOC_DOCUMENTS } from "../../../axios/apiendPoint";
import { toast } from "react-hot-toast";
import { fetchdashboardModalStates } from "../../../pages/Dashboard/ModalSection/modalSectionStore/store";
import VerifiedModal from "../VerifiedModal/VerifiedModal";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const NocModal = (props) => {
  const { isOpen } = props || {};
  const dispatch = useDispatch();
  const { axiosGet, axiosFormDataPost } = useAxiosPrivate();
  //eslint-disable-next-line
  const [fileData, setFileData] = useState(null);
  const [currentNoc, setCurrentNoc] = useState(null);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const modalDetails = useSelector(
    (state) => state.dashboardModalReducer.modalStateDetails,
  )?.data;

  const {
    documentRequiredForUpload = [],
    header,
    subHeader,
  } = modalDetails || {};

  const handleToSetEventName = (name) => setCurrentNoc(name);

  // Function for dynamic modal header
  const renderNocHeader = () => {
    return (
      <>
        <p className="modal_header m-0 p-0 mt-3">{header}</p>
        {subHeader && <p className="noc_description ">{subHeader}</p>}
      </>
    );
  };

  //  Render documents for upload
  const renderNocUploads = () => {
    return (
      <div>
        {documentRequiredForUpload?.map((item, index) => (
          <div
            className="noc-cards d-flex align-items-center justify-content-between m-32"
            key={index}
          >
            {/* <div className="">
              <p className="noc_type m-0">{nocModalDocumentsObj[item.documentType]}</p>
              {item.documentDeclineReason && <p className="noc_type_error m-0 p-0">{item.documentDeclineReason}</p>}
            </div> */}
            <div className="noc-cards-inner-items">
              <p className="noc_type m-0">
                {item.name
                  ? ` ${item.documentType} | ${item.name}`
                  : item.documentType}
              </p>
              {item.documentDeclineReason && (
                <p className="noc_type_error m-0 p-0">
                  {item.documentDeclineReason}
                </p>
              )}
            </div>
            <UploadButton
              handleSubmit={(file) => handleSubmit(file)}
              setFileData={setFileData}
              extrHandleClick={() => handleToSetEventName(item.documentType)}
            />
          </div>
        ))}
      </div>
    );
  };

  // Function to close NOC Modal
  const handleToCloseNocModal = () => {
    setSubmitFlag(false);
  };

  // Function to hit api
  const handleSubmit = async (fileToUpload) => {
    if (fileToUpload && currentNoc) {
      const formData = new FormData();
      formData.append("documentType", currentNoc);
      formData.append("file", fileToUpload);

      const response = await axiosFormDataPost(UPLOAD_NOC_DOCUMENTS, formData);
      if (response?.success) {
        toast.success(response?.msg);
        setFileData(null);
        setCurrentNoc(null);
        dispatch(fetchdashboardModalStates({ axiosGet }));
      } else {
        setFileData(null);
        setCurrentNoc(null);
        dispatch(fetchdashboardModalStates({ axiosGet }));
      }
    } else {
      toast.error("Please upload the document");
    }
  };

  return (
    <div className="noc_modal_container">
      <CustomModal
        isOpen={isOpen}
        width="571px"
        modalbody={
          submitFlag ? (
            <VerifiedModal
              closeBtn
              handleClick={handleToCloseNocModal}
              verifiedText="Marksheet and NOC’s Submitted Successfully!"
              verifiedDescription="We will verify the documents and update you in 2 hours"
            />
          ) : (
            <Fragment>
              <div className="d-flex flex-column align-items-center justify-content-center mb-3">
                <img src={Noc} alt="noc" />
                {/* Render Header a/c to options */}
                {renderNocHeader()}
              </div>

              {/* Render Uploads a/c to options */}
              {renderNocUploads()}

              <div className="noc_info_content mb-3">
                <span
                  className="view_label me-1"
                  onClick={toggle}
                  style={{ cursor: "pointer", color: "#007bff" }}
                >
                  <Info size={15} className="me-2" />
                  Click here
                </span>
                to know about how to easily get NOC from Insurers and Point Of
                Sales
                {/* Modal for NOC information */}
                <Modal isOpen={modal} toggle={toggle} centered>
                  <ModalHeader toggle={toggle}>NOC Information</ModalHeader>
                  <ModalBody>
                    <div
                      className="option-heading"
                      style={{ textAlign: "start" }}
                    >
                      Option 1:
                    </div>
                    <div className="options-inner-items">
                      <ul>
                        <li className="options-text">
                          Self-Deactivation Link:
                          <a
                            href="https://www.itrex.in/USN/SelfDeactivatePAN.aspx"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://www.itrex.in/USN/SelfDeactivatePAN.aspx
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div
                      className="option-heading"
                      style={{ textAlign: "start" }}
                    >
                      Option 2:
                    </div>
                    <div className="options-inner-items">
                      <ul>
                        <li className="options-text">
                          Send an email to
                          <a href="mailto:complaints@irdai.gov.in">
                            {" "}
                            complaints@irdai.gov.in
                          </a>
                          {""} and the support email ID of the existing
                          intermediary with your {""}
                          <strong>PAN Number</strong> and{" "}
                          <strong>POSP Details</strong> to get the{" "}
                          <strong>NOC.</strong>
                        </li>
                      </ul>
                    </div>
                  </ModalBody>
                </Modal>
              </div>

              {/* <CustomButton alignCenter fullWidth handleClick={handleToSubmitNoc} disabled={documentRequiredForUpload.length ? true : false} /> */}
            </Fragment>
          )
        }
      />
    </div>
  );
};

export default NocModal;
