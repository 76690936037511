import "./Notes.scss";
import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import Icon from "../../assets/images/notes/post-it 1.svg";

const Notes = ({ notes }) => {
  const [open, setOpen] = useState("");

  const toggle = (id) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  return (
    <div>
      <Accordion
        flush
        open={open}
        toggle={toggle}
        className="notes-accordion-main"
      >
        <AccordionItem className="set-notes-header">
          <AccordionHeader className="notes-header" targetId="1">
            <span className="notes-logo">
              <img src={Icon} alt="icon" />
            </span>
            <span style={{ marginLeft: "19px", color: "#6B5000" }}>
              Notes for you
            </span>
          </AccordionHeader>

          <AccordionBody
            accordionId="1"
            className={`acco-body ${open === "1" ? "open" : ""}`}
          >
            <div>
              <ul className="acco-body-contain ">
                {notes &&
                  notes.map((item, index) => (
                    <li
                      key={index}
                      style={{
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: "400",
                        textAlign: "start",
                      }}
                    >
                      {item}
                    </li>
                  ))}
              </ul>
            </div>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default Notes;
