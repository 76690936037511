import React from "react";
import "./index.scss";
import { useDispatch } from "react-redux";
import Upload from "../../assets/images/QuoteRequest/Upload.svg";
import {
  toggleMappingRequestDocModal,
  setCurrentMappingRequestData,
} from "./Store/store";

const MappingRequestHoverItems = ({ item }) => {
  const dispatch = useDispatch();

  // Function to toggle modal a/c to modal name
  const handleToToggleModal = (e, event) => {
    e.stopPropagation(); // This prevents the parent's click event from firing
    dispatch(setCurrentMappingRequestData(item));
    if (event === "upoad_doc") dispatch(toggleMappingRequestDocModal());
  };

  return (
    <div className="more_info">
      {item?.mappingState === "DOCUMENT_REUPLOAD" && (
        <div
          className="btn_block cursor_pointer"
          onClick={(e) => handleToToggleModal(e, "upoad_doc")}
        >
          <img src={Upload} alt="upload" />
          <p className="mb-0 btn_title">Upload Docs</p>
        </div>
      )}
    </div>
  );
};

export default MappingRequestHoverItems;
