import React, { useEffect, useState } from "react";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../Store/store";
import useAxiosPrivate from "../../../axios/services";
import BookingsCard from "./BookingsCard";
import MappingsCard from "./MappingsCard";
import QuotesCard from "./QuotesCard";
import { isMobileDevice } from "../../../utils/utils";
import DatePicker from "../../../components/DatePicker/Datepicker";

const DashboardData = () => {
  // set default empty state for dashboardData and isLoading
  const { dashboardData = {} } = useSelector(
    (state) => state.dashboardRootReducer,
  );
  const { modalStateDetails } = useSelector(
    (state) => state.dashboardModalReducer,
  );
  const { page } = modalStateDetails?.data || {};
  const dispatch = useDispatch();
  const { axiosGet2 } = useAxiosPrivate();
  useEffect(() => {
    dispatch(fetchDashboardData({ axiosGet: axiosGet2 }));
  }, []);

  const [dateSelected, setDateSelected] = useState(null);

  useEffect(() => {
    if (dateSelected) {
      const date = new Date(dateSelected);
      dispatch(
        fetchDashboardData({
          axiosGet: axiosGet2,
          params: `month=${date.getMonth()}&year=${date.getFullYear()}`,
        }),
      );
    }
  }, [dateSelected]);
  const handleDateChange = (date) => {
    setDateSelected(date);
  };

  return (
    <div
      className={`dashboard-wrapper ${page === "PROFILE_COMPLETED_PAGE" ? "profile-completed-mweb" : ""}`}
    >
      <div className="date_container">
        <div className="user-name">
          {isMobileDevice() ? (
            <span
              style={{
                fontSize: "16px",
                lineHeight: "21px",
                fontWeight: "600",
                color: "#000000",
              }}
            >
              Dashboard
            </span>
          ) : (
            <>👋 Hi {dashboardData?.customerName}, sell and earn more</>
          )}
        </div>
        <div className="date_picker_container">
          <DatePicker
            placeholder="Month-Year"
            options={{
              plugins: [
                new monthSelectPlugin({
                  shorthand: true,
                  dateFormat: "m/Y",
                  altInput: true,
                  altFormat: "m/Y",
                  theme: "light",
                }),
              ],
              static: true,
              disableMobile: true,
            }}
            className="flat_picker_date"
            style={{
              width: isMobileDevice() ? "160px" : "250px",
              height: isMobileDevice() ? "32px" : "",
            }}
            value={dateSelected || null}
            onChange={handleDateChange}
          />
        </div>
      </div>
      <div className="main-div">
        {/* <div> */}
        <BookingsCard
          type={"Bookings"}
          cardDetails={dashboardData.bookingDetails}
          recentDetails={dashboardData.bookingList}
        />
        <MappingsCard
          type={"Mappings"}
          cardDetails={dashboardData.mappingDetails}
          recentDetails={dashboardData.mappingList}
        />
        <QuotesCard
          type={"QuoteRequest"}
          cardDetails={dashboardData.quoteDetails}
          recentDetails={dashboardData.quoteList}
        />
      </div>
      {/* <Notes/> */}
    </div>
  );
};

export default DashboardData;
