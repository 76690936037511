import React from "react";
import Select from "react-select"; // Importing react-select
import "./DropDown.scss"; // Assuming custom styles are in this file

const DropDown = ({
  options,
  onSelect,
  className,
  height = "40px",
  width = "100%",
  fontSize = "14px",
  placeholder = "",
  ...props
}) => {
  // Custom styles for the react-select components

  const customStyles = (height, width, fontSize) => ({
    control: (provided) => ({
      ...provided,
      height: height,
      width: width,
      border: "1px solid #cccccc",
      borderRadius: "8px",
      fontSize: fontSize,
      fontWeight: "400",
      fontFamily: "Poppins",
      "&:hover": {
        border: "1px solid #4D9AFF",
      },
      boxShadow: "none",
      "&:focus": {
        border: "1px solid #4D9AFF",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      flexWrap: "nowrap",
      "& > div": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "#828282",
        fontSize: fontSize,
      },
    }),
  });

  return (
    <div className="dropdown-container">
      <Select
        id="selectCustom"
        name="selectCustom"
        onChange={onSelect}
        options={options}
        className={`custom-select ${className || ""}`}
        styles={customStyles(height, width, fontSize)}
        placeholder={placeholder}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        {...props}
      />
    </div>
  );
};

export default DropDown;
