import React, { useState, Fragment } from "react";
import "./index.scss";
import CustomModal from "../CustomModal/CustomModal";
// import DragDropFile from "../../DragDropFile/DragDropFile";
import useAxiosPrivate from "../../../axios/services";
import { UPLOAD_MARKSHEET } from "../../../axios/apiendPoint";
import { toast } from "react-hot-toast";
import CustomButton from "../../Button/CustomButton/CustomButton";
import VerifiedModal from "../VerifiedModal/VerifiedModal";
import AadharModal from "../AadharModal/AadharModal";
import { useDispatch } from "react-redux";
import { fetchdashboardModalStates } from "../../../pages/Dashboard/ModalSection/modalSectionStore/store";
import DragAndDropFileUpload from "../../DragDropFile/DragDrop";

const MarksheetModal = (props) => {
  const dispatch = useDispatch();
  const { isOpen, toggle: toggleMarksheetMOdal } = props || {};

  const { axiosFormDataPost, axiosGet } = useAxiosPrivate();

  // state
  const [isMarksheetSuccess, setIsMarksheetSuccess] = useState(false);
  const [isAadhaarModalOpen, setIsAadhaarModalOpen] = useState(false);
  const [marksheetFile, setMarksheetFile] = useState(null);

  const toggleAadharModal = () => setIsAadhaarModalOpen(!isAadhaarModalOpen);

  // function to upload document
  const handleToUploadMarksheet = async (file) => {
    if (file) {
      setMarksheetFile(file);
      const formData = new FormData();
      formData.append("file", file);
      const response = await axiosFormDataPost(UPLOAD_MARKSHEET, formData);
      if (response?.success) {
        toast.success(response?.msg);
        setIsMarksheetSuccess(true);
      }
      setMarksheetFile(null);
      dispatch(fetchdashboardModalStates({ axiosGet }));
    } else {
      setMarksheetFile(null);
    }
  };

  // function to open aadhar modal
  const handleToOpenVerifyAadharModal = async () => {
    toggleAadharModal();
  };

  return (
    <Fragment>
      <CustomModal
        isOpen={isOpen}
        toggle={toggleMarksheetMOdal}
        modaltitle={
          isMarksheetSuccess ? null : "Upload your higher education marksheet"
        }
        width={"736px"}
        modalbody={
          isMarksheetSuccess ? (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <VerifiedModal verifiedText="Marksheet uploaded Successfully!" />

              <div className="verify_aadhar_btn">
                <CustomButton
                  buttonName="Verify your Aadhar"
                  handleClick={handleToOpenVerifyAadharModal}
                />
              </div>
            </div>
          ) : (
            // <DragDropFile sentence="Drag & drop your 10th marksheet or" setFileData={setMarksheetFile} />
            <DragAndDropFileUpload
              fileData={marksheetFile}
              setFileData={handleToUploadMarksheet}
            />
          )
        }
      />
      {/* <AadharModal isOpen={isModalOpen} toggle={toggleModal} /> */}
      <AadharModal isOpen={isAadhaarModalOpen} toggle={toggleAadharModal} />
    </Fragment>
  );
};

export default MarksheetModal;
