import React from "react";
import "./index.scss";
//import car bike and bus svg from assets/images/NewQuoteRequest
import { ReactComponent as Car } from "../../../assets/images/NewQuoteRequest/Car.svg";
import { ReactComponent as Bike } from "../../../assets/images/NewQuoteRequest/Bike.svg";
import { ReactComponent as Bus } from "../../../assets/images/NewQuoteRequest/Bus.svg";
import { ReactComponent as MappingIcon } from "../../../assets/images/sidebar/mappingRequestIcon.svg";
import { ReactComponent as NotFound } from "../../../assets/images/common/notFound.svg";
import { formatDate, isMobileDevice } from "../../../utils/utils";
import { ReactComponent as Tick } from "../../../assets/images/Dashboard/tick.svg";
import { navRoutes } from "../../../router/Url";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Arrow } from "../../../assets/images/Dashboard/arrow.svg";

const MappingsCard = ({ type, cardDetails, recentDetails = {} }) => {
  const [isActive, setIsActive] = React.useState("Car");
  const handleClick = (prop) => {
    setIsActive(prop);
  };
  const navigate = useNavigate();
  const handleViewAll = () => {
    navigate(navRoutes.mappingRequestPage);
  };

  return (
    <div className="main-container">
      <div className="card-container">
        <div className="card-upperhalf">
          <div className="card-top">
            <div
              className="card-content"
              style={{ display: "flex", gap: "6px", alignItems: "center" }}
            >
              <div
                style={{
                  background: "#E4ECFD",
                  borderRadius: "25px",
                  width: "40px",
                  height: "40px",
                  // placeContent: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MappingIcon color={"#0039AA"} />
              </div>
              <div>Total {type}</div>
            </div>
            {isMobileDevice() && (
              <div>
                <span className="view-all" onClick={handleViewAll}>
                  {" "}
                  View All
                  <Arrow />
                </span>
              </div>
            )}
          </div>
          <div className="card-mid">
            <div> {cardDetails?.totalMappingRaised} </div>
            <span>raised</span> /
            <div className="green"> {cardDetails?.totalMappingMapped} </div>
            <span>mapped</span>
          </div>
        </div>
        <div className="card-bottom">
          <div
            className={!isMobileDevice() && isActive == "Car" ? "active" : ""}
            onClick={() => {
              if (!isMobileDevice()) {
                handleClick("Car");
              }
            }}
          >
            <Car />
            <div className="mapping-green w_full">
              {" "}
              {cardDetails?.carMappingRaised}&nbsp;
              <span> / {cardDetails?.carMappingMapped} </span>
            </div>
          </div>
          <div
            className={!isMobileDevice() && isActive == "TW" ? "active" : ""}
            onClick={() => {
              if (!isMobileDevice()) {
                handleClick("TW");
              }
            }}
          >
            <Bike />
            <div className="mapping-green w_full">
              {cardDetails?.twMappingRaised} /&nbsp;
              <span>{cardDetails?.twMappingMapped}</span>
            </div>
          </div>
          <div
            className={!isMobileDevice() && isActive == "CV" ? "active" : ""}
            onClick={() => {
              if (!isMobileDevice()) {
                handleClick("CV");
              }
            }}
          >
            <Bus />
            <div className="mapping-green w_full">
              {cardDetails?.cvMappingRaised}&nbsp;
              <span> / {cardDetails?.cvMappingMapped}</span>
            </div>
          </div>
        </div>
      </div>
      {!isMobileDevice() && (
        <div className="recent-bookings">
          <h2>Recent Motor {type}</h2>
          <div className="booking-items">
            {recentDetails["recent" + isActive + type]?.map((item, idx) => (
              <div className="booking-item" key={`mapping-card-${idx}`}>
                <div className="booking-details">
                  <div className="img">
                    {isActive == "Car" ? (
                      <Car />
                    ) : isActive == "TW" ? (
                      <Bike />
                    ) : (
                      <Bus />
                    )}
                  </div>
                  <div className="registration-data">
                    <div className="policy-number">
                      {item.registrationNumber}
                    </div>
                    <div className="status">
                      <Tick />
                      {item.status}
                    </div>
                  </div>
                </div>
                <div className="issued-date">{formatDate(item.timestamp)}</div>
              </div>
            ))}
            {recentDetails["recent" + isActive + type] === null ||
            recentDetails["recent" + isActive + type]?.length === 0 ? (
              <div className="notfound-card">
                <NotFound />
                <span>No new Mappings</span>
              </div>
            ) : (
              <span className="view-all" onClick={handleViewAll}>
                {" "}
                View All
                <Arrow />
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MappingsCard;
