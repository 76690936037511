import React from "react";

const CertificateFooter = () => {
  return (
    <div>
      <div style={{ width: "100%", border: "1px solid black" }}></div>
      <div>
        <h6 style={{ margin: "5px", fontWeight: "500", textAlign: "center" }}>
          BimaGuide [A brand of In2Fin Insurance Brokers Pvt Ltd.]
        </h6>
      </div>
      <div>
        <p
          style={{
            margin: "5px",
            fontSize: "12px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Registration No: 946,Registration Code No. IRDAI/DB 1069/2023, Valid
          From 11/03/2024 to 10/03/2027
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          alignItems: "center",
          fontSize: "12px",
          fontWeight: "500",
        }}
      >
        <span>
          <span style={{ fontSize: "12px", color: "black" }}>📞 </span>
          +91 8010593538
        </span>
        <span>
          <span style={{ fontSize: "12px", color: "black" }}>📧 </span>
          support@bimaguide.com
        </span>
        <span>
          <span style={{ fontSize: "12px", color: "black" }}>🌐 </span>
          www.bimaguide.com
        </span>
        <span>
          <span style={{ fontSize: "12px", color: "black" }}>🏢 </span>
          CIN: U66220DL2023PTC417094
        </span>
      </div>
      <div>
        <p
          style={{
            margin: "5px",
            fontSize: "12px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          <span style={{ fontSize: "12px", color: "black" }}>📍 </span>
          Corp Office : 3rd Floor, Innov8, 301-329, Orchid Centre, Golf Course
          Road, Sector 53, Gurugram, Haryana, 122002
        </p>
      </div>
      <div>
        <p
          style={{
            margin: "5px",
            fontSize: "12px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          <span style={{ fontSize: "12px", color: "black" }}>📍 </span>
          Regd. Office : F-84-B, Gali No. 4, Laxmi Nagar, East Delhi, New Delhi
          110092 | License Category: Direct (Life & General)
        </p>
      </div>
    </div>
  );
};

export default CertificateFooter;
