import React from "react";
import "./index.scss";
import QuoteDynamicHeader from "../../../../../components/QuoteDynamicHeader/QuoteDynamicHeader";
import Verified from "../../../../../assets/lottie/verified3.json";

const QuoteSuccessModal = ({ title, subTitle }) => {
  return (
    <div className="mt_12 payment_container">
      <p className="component_section_name mb_12">Payment Details</p>
      <div className="card_background p_40 background_dark_blue">
        <QuoteDynamicHeader
          title={title}
          subTitle={subTitle}
          titleClass="payment_validating_title"
          subTitleClass="payment_validating_details"
          noCard
          customImage
          lottieImage={Verified}
        />
      </div>
    </div>
  );
};

export default QuoteSuccessModal;
