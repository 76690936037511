import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  ModalHeader,
} from "reactstrap";
import "./mwebbooking.scss";
import { ReactComponent as Car } from "../../assets/images/Booking/Car.svg";
import { ReactComponent as PolicyImg } from "../../assets/images/Booking/policy.svg";
import { ReactComponent as DownloadIcon } from "../../assets/images/common/download_file.svg";
import { ReactComponent as PremimumIcon } from "../../assets/images/BookingMweb/premimum.svg";
import ArrowBack from "../../assets/images/navbar/arrow-back-icon.svg";
import { ChevronDown, ChevronUp } from "react-feather";
const MWebBookingModal = ({
  handleClose,
  headerData,
  vehicleDetails,
  policyDetails,
  policyCopyUrl,
  businessType,
  premiumDetails,
  isOpen,
}) => {
  const [open, setOpen] = useState(["1"]);

  const toggleAccordion = (id) => {
    setOpen((prevOpen) =>
      prevOpen.includes(id)
        ? prevOpen.filter((item) => item !== id)
        : [...prevOpen, id],
    );
  };

  return (
    <Modal
      className="m-web-booking-modal"
      isOpen={isOpen}
      toggle={handleClose}
      style={{
        width: "100%",
        margin: "0px",
        borderRadius: "0px",
      }}
    >
      <ModalHeader className="mweb-booking-header">
        <div
          className="Arrow-back-icon"
          style={{ position: "absolute", left: "10px", top: "16px" }}
          onClick={handleClose}
        >
          <img
            src={ArrowBack}
            alt="ArrowBack"
            style={{ width: "18px", height: "18px" }}
          />
        </div>
        <div
          className="mweb-booking-headers-headings"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "rgba(24, 24, 24, 1)",
            }}
          >
            {headerData?.registrationNumber}
          </span>
          <span
            style={{
              fontWeight: "400",
              fontSize: "12px",
              color: "rgba(24, 24, 24, 1)",
            }}
          >
            {headerData?.makeModel}
          </span>
        </div>
      </ModalHeader>
      <ModalBody className="mweb-booking-body">
        <Accordion
          open={open}
          toggle={toggleAccordion}
          className="accordion-main"
        >
          <div className="mweb-booking-accordion-sections">
            <div>
              <AccordionItem
                style={{
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  color: "green",
                }}
              >
                <AccordionHeader targetId="1" style={{ height: "70px" }}>
                  <div
                    style={{ fontWeight: "600" }}
                    className="mweb-accordion-header"
                  >
                    <span
                      style={{
                        background: "rgba(232, 243, 252, 1)",
                        width: "40px",
                        height: "40px",
                        borderRadius: "25px",
                      }}
                    >
                      <Car
                        style={{
                          color: "#0691FC",
                          marginLeft: "8px",
                          marginTop: "8px",
                        }}
                        color="#0691FC"
                        width={24}
                        height={24}
                      />
                    </span>

                    <span className="mweb-details-heading">
                      Vehicle Details
                    </span>
                    <span className="mweb-accordion-header-icon">
                      {open.includes("1") ? (
                        <ChevronUp size={20} />
                      ) : (
                        <ChevronDown size={20} />
                      )}
                    </span>
                  </div>
                </AccordionHeader>
                <AccordionBody accordionId="1">
                  <div className="mweb-acco-details">
                    {vehicleDetails.map(
                      (item, index) =>
                        item.value && (
                          <div className="inner-details-section" key={index}>
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  color: "rgba(128, 128, 128, 1)",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                }}
                              >
                                {" "}
                                {item?.key}
                              </span>

                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "rgba(24, 24, 24, 1)",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {item.value}
                              </span>
                            </div>
                          </div>
                        ),
                    )}
                  </div>
                </AccordionBody>
              </AccordionItem>
            </div>

            {/* policy Details  */}
            <div>
              <AccordionItem
                style={{
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  color: "green",
                }}
              >
                <AccordionHeader targetId="2" style={{ height: "70px" }}>
                  <div
                    style={{ fontWeight: "600" }}
                    className="mweb-accordion-header"
                  >
                    <span
                      style={{
                        background: "rgba(232, 243, 252, 1)",
                        width: "40px",
                        height: "40px",
                        borderRadius: "25px",
                      }}
                    >
                      <PolicyImg
                        style={{
                          color: "#0691FC",
                          marginLeft: "8px",
                          marginTop: "8px",
                        }}
                        color="#0691FC"
                        width={24}
                        height={24}
                      />
                    </span>

                    <span className="mweb-details-heading">Policy details</span>
                    <span className="business_type">{businessType}</span>
                    <span className="mweb-accordion-header-icon">
                      {open.includes("2") ? (
                        <ChevronUp size={20} />
                      ) : (
                        <ChevronDown size={20} />
                      )}
                    </span>
                  </div>
                </AccordionHeader>
                <AccordionBody accordionId="2">
                  <div className="mweb-acco-details">
                    {policyDetails.map(
                      (item, index) =>
                        item.value && (
                          <div className="inner-details-section" key={index}>
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  color: "rgba(128, 128, 128, 1)",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                }}
                              >
                                {" "}
                                {/* Product */} {item?.key}
                              </span>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "rgba(24, 24, 24, 1)",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {item.value}
                              </span>
                            </div>
                          </div>
                        ),
                    )}
                  </div>
                  <div className="m-web-booking-modal-card-footer">
                    <span
                      style={{
                        color: "rgba(51, 51, 51, 1)",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      <a
                        href={policyCopyUrl}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "rgba(51, 51, 51, 1)",
                        }}
                      >
                        {" "}
                        Download
                      </a>
                    </span>
                    <span>
                      <DownloadIcon width={20} height={20} />
                    </span>
                  </div>
                </AccordionBody>
              </AccordionItem>
            </div>
            {/* premium breakup */}
            <div>
              <AccordionItem
                style={{
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  color: "green",
                }}
              >
                <AccordionHeader targetId="3" style={{ height: "70px" }}>
                  <div
                    style={{ fontWeight: "600" }}
                    className="mweb-accordion-header"
                  >
                    <span
                      style={{
                        background: "rgba(232, 243, 252, 1)",
                        width: "40px",
                        height: "40px",
                        borderRadius: "25px",
                      }}
                    >
                      <PremimumIcon
                        style={{
                          color: "#0691FC",
                          marginLeft: "8px",
                          marginTop: "8px",
                        }}
                        color="#0691FC"
                        width={24}
                        height={24}
                      />
                    </span>

                    <span className="mweb-details-heading">
                      Premium breakup
                    </span>
                    <span className="mweb-accordion-header-icon">
                      {open.includes("3") ? (
                        <ChevronUp size={20} />
                      ) : (
                        <ChevronDown size={20} />
                      )}
                    </span>
                  </div>
                </AccordionHeader>
                <AccordionBody accordionId="3">
                  <div className="mweb-acco-details">
                    <div
                      className=""
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "space-between",
                        width: "100%",
                        gap: "20px",
                      }}
                    >
                      <div className="premimum-section-items">
                        <span
                          style={{
                            color: "rgba(128, 128, 128, 1)",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          OD
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "rgba(24, 24, 24, 1)",
                          }}
                        >
                          {premiumDetails?.odPremium}
                        </span>
                      </div>

                      <div className="premimum-section-items">
                        <span
                          style={{
                            color: "rgba(128, 128, 128, 1)",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          TP
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "rgba(24, 24, 24, 1)",
                          }}
                        >
                          {premiumDetails?.odPremium}
                        </span>
                      </div>
                      <div
                        className="premimum-section-items"
                        style={{ marginBottom: "20px" }}
                      >
                        <span
                          style={{
                            color: "rgba(128, 128, 128, 1)",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          NET
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "rgba(24, 24, 24, 1)",
                          }}
                        >
                          {premiumDetails?.netPremium}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="m-web-premimum-footer">
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "rgba(24, 24, 24, 1)",
                        }}
                      >
                        APE
                      </span>

                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "rgba(24, 24, 24, 1)",
                        }}
                      >
                        {premiumDetails?.totalPremium}
                      </span>
                    </div>
                  </div>
                </AccordionBody>
              </AccordionItem>
            </div>
          </div>
        </Accordion>
      </ModalBody>
    </Modal>
  );
};

export default MWebBookingModal;
