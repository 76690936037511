import React from "react";
import "./index.scss";
import { useDispatch } from "react-redux";
import Fill from "../../assets/images/QuoteRequest/Fill.svg";
import Upload from "../../assets/images/QuoteRequest/Upload.svg";
import View from "../../assets/images/QuoteRequest/ViewQuote.svg";
import {
  setCurrentQuoteData,
  toggleFillInspectionModal,
  toggleQuoteDocModal,
  toggleViewQuoteModal,
} from "./Store/store";

const QuoteRequestHoverItems = ({ item }) => {
  const dispatch = useDispatch();

  // Function to toggle modal a/c to modal name
  const handleToToggleModal = (e, event) => {
    e.stopPropagation(); // This prevents the parent's click event from firing
    dispatch(setCurrentQuoteData(item));
    switch (event) {
      case "upoad_doc":
        dispatch(toggleQuoteDocModal());
        break;
      case "view_quote":
        dispatch(toggleViewQuoteModal());
        break;
      case "fill_details":
        dispatch(toggleFillInspectionModal());
        break;
      default:
        break;
    }
  };

  return (
    <div className="more_info">
      {item?.quoteState === "DOCUMENT_REUPLOAD" && (
        <div
          className="btn_block cursor_pointer"
          onClick={(e) => handleToToggleModal(e, "upoad_doc")}
        >
          <img src={Upload} alt="upload" />
          <p className="mb-0 btn_title">Upload Docs</p>
        </div>
      )}

      {item?.quoteState === "QUOTE_SHARED" && (
        <div
          className="btn_block cursor_pointer m-24"
          onClick={(e) => handleToToggleModal(e, "view_quote")}
        >
          <img src={View} alt="view" />
          <p className="mb-0 btn_title">View Quotes</p>
        </div>
      )}

      {item?.quoteState === "FILL_CUSTOMER_DETAILS" && (
        <div
          className="btn_block cursor_pointer"
          onClick={(e) => handleToToggleModal(e, "fill_details")}
        >
          <img src={Fill} alt="edit" />
          <p className="mb-0 btn_title">Fill Details</p>
        </div>
      )}
    </div>
  );
};

export default QuoteRequestHoverItems;
