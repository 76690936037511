import React, { useCallback, useEffect, useState } from "react";
import MappingRequestTable from "./MappingRequestTable";
import { useSelector, useDispatch } from "react-redux";

import { fetchMappingRequestData } from "./Store/store";
import useAxiosPrivate from "../../axios/services";
import "./index.scss";
import "flatpickr/dist/flatpickr.css";
import DocUploadModal from "./DocUploadModal";
import { debounce, formatDate, isMobileDevice } from "../../utils/utils";
import { Search } from "react-feather";
import FormInput from "../../components/FormInput/FormInput";
import DatePicker from "../../components/DatePicker/Datepicker";
import DropDown from "../../components/DropDown/DropDown";
import CustomTabing from "../../components/CustomTabing/CustomTabing";
import { CustomPagination } from "../../components/Pagination/Pagination";
import { ReactComponent as NotFound } from "../../assets/images/common/notFound.svg";
import { Product } from "../../constants/Constants";
import Loader from "../../components/Loader/Loader";
import MobileTabing from "../../components/MobileTabing";
import MappingTableMweb from "./MappingTableMweb";

const MappingRequest = () => {
  const { axiosGet2 } = useAxiosPrivate();
  const dispatch = useDispatch();
  const { isLoading, pagination, mappingList, mappingBucket } = useSelector(
    (state) => state.mappingRequestReducer,
  );

  const [currentPage, setCurrentPage] = useState(pagination?.curPage || 1);
  const [product, setProduct] = useState("");
  const [search, setSearch] = useState("");
  const [currentBucket, setCurrentBucket] = useState(
    mappingBucket?.curBucket || "",
  );
  const [filterByDate, setFilterByDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [debouncedSearch, setDebouncedSearch] = useState("");

  // Debounce the search input
  const debounceSearch = useCallback(
    debounce((searchValue) => {
      if (searchValue.length >= 3) {
        setDebouncedSearch(searchValue);
      } else {
        setDebouncedSearch(""); // Clear search if less than 3 characters
      }
    }, 500),
    [],
  );

  const handleSearch = (evt) => {
    const searchValue = evt.target.value;
    const validInput = searchValue.replace(/[^a-zA-Z0-9-\s]/g, "").slice(0, 15);
    setSearch(validInput);
    debounceSearch(validInput);
  };
  const handleDateChange = (date) => {
    if (date && date.length == 2) {
      setFilterByDate({ startDate: date[0], endDate: date[1] });
    }

    if (date && date.length === 0) {
      setFilterByDate({ startDate: "", endDate: "" });
    }
  };

  const handleProductSelect = (selectedOption) => {
    setProduct(
      selectedOption?.value === "No Product" ? "" : selectedOption?.value,
    ); // Set product or empty string
  };

  // page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleTabChange = (newValue) => {
    if (!isLoading) {
      setCurrentBucket(newValue);
      setCurrentPage(1); // Reset to page 1 when bucket changes
    }
  };

  // const getMappingRequestList = () =>
  //   dispatch(fetchMappingRequestData({ axiosGet: axiosGet2 }));

  // useEffect(() => {
  //   getMappingRequestList();
  // }, []);

  useEffect(() => {
    let searchParams = {};
    if (currentBucket) {
      searchParams["curBucket"] = currentBucket;
    }

    if (currentPage) {
      searchParams["curPage"] = currentPage;
    }
    if (debouncedSearch) {
      searchParams["searchQuery"] = debouncedSearch;
    }
    if (product) {
      searchParams["product"] = product;
    }

    if (filterByDate.startDate && filterByDate.endDate) {
      searchParams["startDate"] = formatDate(filterByDate.startDate);
      searchParams["endDate"] = formatDate(filterByDate.endDate);
    }
    searchParams = Object.entries(searchParams).map(
      ([key, value]) => `${key}=${value.toString()}`,
    );
    searchParams = searchParams.join("&");
    dispatch(fetchMappingRequestData({ axiosGet: axiosGet2, searchParams }));
  }, [currentBucket, currentPage, filterByDate, debouncedSearch, product]);

  return (
    <div className="mapping-request-main-container">
      {isMobileDevice() ? (
        <div className="mobile-mapping-request-card-container">
          <MobileTabing
            value={mappingBucket?.curBucket}
            handleChange={handleTabChange}
            tabList={mappingBucket?.bucketList || []}
          />
          <div style={{ width: "75%" }}>
            <div className="mapping-filters-container-mweb">
              <div
                className=""
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                 <div style={{ width: "170px" }}>
              <FormInput
                icon={<Search size={24} color="#0691fc" strokeWidth={1.5} />}
                value={search}
                onChange={handleSearch}
                placeholder="Search"
                style={{ height: "40px",width:"170px" }}
              />
            </div>
                <div style={{ width: "170px" }}>
                  <DropDown
                    options={Product}
                    isClearable={true}
                    placeholder="Select"
                    width="170px"
                    fontSize="16px"
                    onSelect={handleProductSelect}
                  />
                </div>
                <div style={{ width: "170px" }}>
                  <DatePicker
                    placeholder="Date Range"
                    options={{ mode: "range" }}
                    value={
                      filterByDate.startDate && filterByDate.endDate
                        ? [filterByDate.startDate, filterByDate.endDate]
                        : ""
                    }
                    style={{width:'170px'}}
                    onChange={handleDateChange}
                  />
                </div>
              </div>
            </div>
            <MappingTableMweb data={mappingList} isLoading={isLoading} />
            {/* <CustomPagination
              currentPage={currentPage}
              totalPage={pagination?.totalPage || 1}
              handlePageChange={handlePageChange}
            /> */}
          </div>
        </div>
      ) : (
        <div className="mapping-request-card-container">
          {/* Header Section */}
          <div className="filters_container">
            <div style={{ width: "400px" }}>
              <FormInput
                icon={<Search size={24} color="#0691fc" strokeWidth={1.5} />}
                value={search}
                onChange={handleSearch}
                placeholder="Type to Search"
                style={{ height: "40px" }}
              />
            </div>
            <div
              className=""
              style={{ display: "flex", gap: "20px", alignItems: "center" }}
            >
              <label htmlFor="partner-dropdown" className="filter-label">
                Filters :
              </label>
              <div>
                <DropDown
                  options={Product}
                  isClearable={true}
                  placeholder="Select Product"
                  onSelect={handleProductSelect}
                />
              </div>
              <div>
                <DatePicker
                  placeholder="Date Range"
                  options={{ mode: "range" }}
                  value={
                    filterByDate.startDate && filterByDate.endDate
                      ? [filterByDate.startDate, filterByDate.endDate]
                      : ""
                  }
                  onChange={handleDateChange}
                />
              </div>
            </div>
          </div>

          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh", // Full height of the viewport
              }}
            >
              {" "}
              <Loader />
            </div>
          ) : (
            <>
              <div className="custom-tabing-wrapper">
                <CustomTabing
                  value={mappingBucket?.curBucket}
                  handleChange={handleTabChange}
                  tabList={mappingBucket?.bucketList || []}
                />
              </div>

              {/* Table Section */}

              {mappingList?.length !== 0 ? (
                <>
                  <MappingRequestTable
                    data={mappingList}
                    isLoading={isLoading}
                  />
                </>
              ) : (
                <div className="notfound-card">
                  <NotFound />
                  <span>No Data Found </span>
                </div>
              )}
              {/* pagination */}
              <CustomPagination
                currentPage={currentPage}
                totalPage={pagination?.totalPage || 1}
                handlePageChange={handlePageChange}
              />
            </>
          )}
          <DocUploadModal />
        </div>
      )}
    </div>
  );
};

export default MappingRequest;
