import React from "react";
import { Info } from "react-feather";
import { useSelector } from "react-redux";

const AgencyDetails = () => {
  const details = useSelector(
    (state) => state.quoteRequestReducer.partnerQuoteMainModalDetails,
  );
  const { inspectionDetails = [] } = details || {};

  if (!inspectionDetails || inspectionDetails.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="agency_container">
        <div className="agency_heading mb_12 mt_24"> Agency Details</div>
        <div className="inspection_container p_12">
          <Info size={15} className="" />
          <div className="inspection_heading">
            Please share the third party inspection details with the customer
          </div>
        </div>
        <div className="agency_content ">
          <div className="d-flex gap-2 align-items-center p_12 flex-wrap">
            {inspectionDetails?.map((item, index) => (
              <div
                className="d-flex flex-column justify-content-between align-items-between"
                key={index}
                style={{ flex: 1, minHeight: "45px" }}
              >
                <div className="agency_key">
                  {item && item.key === "Agency Mobile Number"
                    ? "Mobile Number"
                    : item?.key}
                </div>
                <div className="agency_value">{item?.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgencyDetails;
