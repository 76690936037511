import React from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { Eye } from "react-feather";
import { ReactComponent as PIN } from "../../../../assets/images/NewQuoteRequest/PIN.svg";

const QuoteDocuments = () => {
  const details = useSelector(
    (state) => state.quoteRequestReducer.partnerQuoteMainModalDetails,
  );
  const { documents = [] } = details || {};

  return (
    <div className="quote_documents_container">
      <p className="component_section_name mb_12">Documents</p>

      <div className="documnets_list">
        {documents?.map((item, index) => (
          <div className="document_item" key={index}>
            <div className="d-flex align-items-center gap-3">
              {/* <img src={PIN} alt="attach" height={8} /> */}
              <PIN height={8} />
              <div className="doc_name">{item.documentName}</div>
            </div>

            <a href={item?.documentUrl} target="_blank" rel="noreferrer">
              <Eye size={16} color="#0691FC" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuoteDocuments;
