import React from "react";
import BlurOverlay from "../../../components/BlurOverlay/BlurOverlay";
import { ReactComponent as LockIcon } from "../../../assets/images/Dashboard/lock.svg";
import { isMobileDevice } from "../../../utils/utils";

const LockScreen = () => {
  const isMobile = isMobileDevice();
  return (
    <div className="lock-screen">
      <div className="lock-screen-content">
        <LockIcon color="#DF0000" />
        <h2>Dashboard is locked</h2>
        <p> Complete your profile to start your journey with BimaGuide</p>
      </div>
      {!isMobile && (
        <BlurOverlay
          position="absolute"
          left="-24px"
          width="calc(100% + 48px)"
          height="calc(100% + 24px)"
          minHeight="calc(100vh - 240px)"
          blurClass="show"
        />
      )}
      {isMobile && (
        <BlurOverlay
          position="absolute"
          minHeight="calc(100vh - 240px)"
          blurClass="show"
        />
      )}
    </div>
  );
};

export default LockScreen;
