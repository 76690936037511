import { navRoutes } from "../../router/Url";

// Description: This file contains the search box reducer.
const initialState = {
  showSearchBox: false,
  seachboxValue: "",
  placeholder: "Search ...",
  pagesWithSearchBox: [navRoutes.quoteRequestPage, navRoutes.bookingsPage],
};
export const SEARCH_BOX_VALUE = "SEARCH_BOX_VALUE";
export const SEARCH_BOX_PLACEHOLDER = "SEARCH_BOX_PLACEHOLDER";

export const setSearchBoxValue = (payload) => ({
  type: SEARCH_BOX_VALUE,
  payload,
});

export const setSearchBoxPlaceHolder = (payload) => ({
  type: SEARCH_BOX_PLACEHOLDER,
  payload,
});

export const searchBoxReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_BOX_VALUE:
      return {
        ...state,
        seachboxValue: action.payload,
      };
    case SEARCH_BOX_PLACEHOLDER:
      return {
        ...state,
        placeholder: action.payload,
      };
    default:
      return state;
  }
};
