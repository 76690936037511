import React, { forwardRef } from "react";
import "./FormInput.scss";

const FormInput = forwardRef(
  (
    {
      field,
      icon,
      placeholder,
      isDisabled,
      defaultValue,
      handleChange,
      inputMode,
      onChange,
      maxLength,
      ...props
    },
    ref,
  ) => {
    const handleOnChange = (e) => {
      const fieldOnChange = onChange ? onChange : field.onChange;

      if (maxLength && e.target.value.length > maxLength) {
        return;
      }
      if (inputMode === "alpha") {
        const re = /^[A-Za-z\s]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
          fieldOnChange(e);
        }
      } else if (inputMode === "numeric") {
        const re = /^(?!0\d)\d+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
          fieldOnChange(e);
        } else {
          // remove all other characters except numbers
          let val = e.target.value.replace(/[^0-9]/g, "");
          fieldOnChange({
            target: {
              value: val,
              name: e.target.name,
            },
          });
        }
      } else if (inputMode === "email") {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (e.target.value === "" || re.test(e.target.value)) {
          fieldOnChange(e);
        }
      } else if (inputMode === "tel") {
        const re = /^[0-9\b]{0,10}$/;
        if (e.target.value === "" || re.test(e.target.value)) {
          fieldOnChange(e);
        }
      } else if (inputMode === "decimal") {
        const re = /^[0-9\b]+(\.[0-9]{0,4})?$/;
        if (e.target.value === "" || re.test(e.target.value)) {
          fieldOnChange(e);
        }
      } else if (inputMode === "currency") {
        if (e.target.value === null || e.target.value === "") {
          fieldOnChange(e);
        } else {
          let s = e.target.value;
          s = s.replace(/,/g, "");
          if (isNaN(s)) return;
          let val = parseFloat(s);
          if (isNaN(val)) return;
          fieldOnChange({
            target: {
              value: val.toLocaleString("en-IN"),
              name: e.target.name,
            },
          });
        }
      } else {
        fieldOnChange(e);
      }
    };
    return (
      <div className="form_input_container">
        <div className="wrapper">
          {icon && <div className="icon">{icon}</div>}
          <input
            className={icon ? "input" : "input no_icon"}
            placeholder={placeholder}
            ref={ref}
            {...field}
            inputMode={inputMode || "text"}
            disabled={isDisabled}
            onChange={(e) => {
              if (handleChange) handleChange(e);
              handleOnChange(e);
            }}
            defaultValue={defaultValue}
            {...props}
          />
        </div>
      </div>
    );
  },
);
FormInput.displayName = "FormInput";

export default FormInput;
