import React, { Fragment, useState } from "react";
import "./index.scss";
import CustomModal from "../CustomModal/CustomModal";
import VerifiedModal from "../VerifiedModal/VerifiedModal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "../../Button/CustomButton/CustomButton";
import useAxiosPrivate from "../../../axios/services";
import { SEND_GST_DETAILS } from "../../../axios/apiendPoint";
import { toast } from "react-hot-toast";
import FormInput from "../../FormInput/FormInput";
import { Info } from "react-feather";
import { isExactAddress } from "../../../constants/Constants";
import CustomField from "../../CustomFields/CustomFields";
import { useDispatch } from "react-redux";
import { fetchdashboardModalStates } from "../../../pages/Dashboard/ModalSection/modalSectionStore/store";
import Loader from "../../Loader/Loader";

const GstDetailsModal = (props) => {
  const { isOpen, toggle } = props || {};

  const dispatch = useDispatch();

  const { axiosPost, axiosGet, isLoading } = useAxiosPrivate();

  const [haveGST, setHaveGST] = useState(["Yes"]);
  const [isGSTSuccess, setIsGSTSuccess] = useState(false);
  const [isGSTSubmit, setIsGSTSubmit] = useState(false);
  const [gstDetails, setGstDetails] = useState();

  const schema = yup.object().shape({
    gstNumber: yup.string().required("GST number is required"),
    gstBusinessName: yup.string().required("Business name is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      gstNumber: "",
      gstBusinessName: "",
    },
  });

  const handleToSubmit = async (data) => {
    const payload = {
      documentType: "GST",
      documentInfo: {
        isGstAvailable: haveGST[0] === "Yes" ? true : false,
        gstNumber: data?.gstNumber ? data?.gstNumber : "",
        gstBusinessName: data?.gstBusinessName ? data?.gstBusinessName : "",
        consentText:
          "I hear by declare my consent agreement for fetching my information via ZOOP API",
        consent: true,
      },
    };

    const response = await axiosPost(SEND_GST_DETAILS, payload);
    if (response?.success) {
      toast.success(response?.msg);
      if (haveGST[0] === "Yes") {
        reset();
        setIsGSTSubmit(true);
        setGstDetails(response?.data);
      } else {
        toggle();
        dispatch(fetchdashboardModalStates({ axiosGet }));
      }
    }
  };

  const handleToCloseGST = () => {
    setIsGSTSubmit(false);
    setIsGSTSuccess(true);
    setTimeout(() => {
      toggle();
    }, 2000);
    dispatch(fetchdashboardModalStates({ axiosGet }));
  };

  return (
    <div>
      <CustomModal
        isOpen={isOpen}
        toggle={toggle}
        width="592px"
        modaltitle={isGSTSuccess ? null : "GST details"}
        modalbody={
          <div className="gst_modal_container">
            {isGSTSuccess ? (
              <VerifiedModal verifiedText="GST details Submitted Successfully!" />
            ) : (
              <Fragment>
                {isGSTSubmit ? (
                  <Fragment>
                    <div className="gst_details">
                      <div className="detail_content row">
                        <p className="gst_lable col-6">GST Number</p>
                        <p className="gst_value col-6">
                          {gstDetails?.gstNumber ?? ""}
                        </p>
                      </div>

                      <div className="detail_content row">
                        <p className="gst_lable col-6">
                          Legal name of business
                        </p>
                        <p className="gst_value col-6">
                          {gstDetails?.businessLegalName ?? ""}
                        </p>
                      </div>

                      <div className="detail_content row">
                        <p className="gst_lable col-6">Center jurisdiction</p>
                        <p className="gst_value col-6">
                          {gstDetails?.centralJurisdiction ?? ""}
                        </p>
                      </div>

                      <div className="detail_content row">
                        <p className="gst_lable col-6">State jurisdiction</p>
                        <p className="gst_value col-6">
                          {gstDetails?.stateJurisdiction ?? ""}
                        </p>
                      </div>

                      <div className="detail_content row">
                        <p className="gst_lable col-6">Date of registration</p>
                        <p className="gst_value col-6">
                          {gstDetails?.registerationDate ?? ""}
                        </p>
                      </div>

                      <div className="detail_content row">
                        <p className="gst_lable col-6">
                          Constitution of business
                        </p>
                        <p className="gst_value col-6">
                          {gstDetails?.businessConstitution ?? ""}
                        </p>
                      </div>

                      <div className="detail_content row">
                        <p className="gst_lable col-6">Taxpayer type</p>
                        <p className="gst_value col-6">
                          {gstDetails?.taxPayerType ?? ""}
                        </p>
                      </div>

                      <div className="detail_content row">
                        <p className="gst_lable col-6">Gst in status</p>
                        <p className="gst_value col-6">
                          {gstDetails?.gstStatus ?? ""}
                        </p>
                      </div>

                      <div className="detail_content row">
                        <p className="gst_lable col-6">
                          Nature of business activities
                        </p>
                        <p className="gst_value col-6">
                          {gstDetails?.businessNature ?? ""}
                        </p>
                      </div>

                      <div className="detail_content row">
                        <p className="gst_lable col-6">
                          Principal place address
                        </p>
                        <p className="gst_value col-6">
                          {gstDetails?.address ?? ""}
                        </p>
                      </div>
                    </div>

                    <div className="mt-4">
                      <CustomButton
                        handleClick={handleToCloseGST}
                        buttonName="Submit"
                        alignCenter
                        fullWidth
                      />
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <p className="have_gst_number">Do you have GST number ?</p>
                    <CustomField
                      value={haveGST}
                      setvalue={setHaveGST}
                      options={isExactAddress}
                    />
                    {haveGST[0] === "Yes" ? (
                      <Fragment>
                        <form onSubmit={handleSubmit(handleToSubmit)}>
                          <div className="mb-3">
                            <Controller
                              control={control}
                              name="gstNumber"
                              render={({ field }) => (
                                <FormInput
                                  field={field}
                                  placeholder="Enter your GST number"
                                />
                              )}
                            />
                            {errors.gstNumber && (
                              <div className="error_class">
                                {errors.gstNumber.message}
                              </div>
                            )}
                            <div
                              className={
                                errors.gstNumber
                                  ? "gst_helping_text mt-3"
                                  : "gst_helping_text mt-1"
                              }
                            >
                              <Info size={14} /> Please enter the GST number
                              that is linked to the registered mobile number
                            </div>
                          </div>

                          <div className="mt-3 mb-4">
                            <Controller
                              control={control}
                              name="gstBusinessName"
                              render={({ field }) => (
                                <FormInput
                                  field={field}
                                  placeholder="Enter your Business name"
                                />
                              )}
                            />
                            {errors.gstBusinessName && (
                              <div className="error_class">
                                {errors.gstBusinessName.message}
                              </div>
                            )}
                          </div>

                          <p className="gst_authorised">
                            By continuing, I authorise BimaGuide to fetch my GST
                            details
                          </p>
                          <CustomButton
                            type="submit"
                            buttonName="Fetch Details"
                            alignCenter
                            fullWidth
                          />
                        </form>
                      </Fragment>
                    ) : (
                      <div className="mt-3">
                        <CustomButton
                          handleClick={() => handleToSubmit()}
                          buttonName="Submit"
                          alignCenter
                          fullWidth
                        />
                      </div>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        }
      />
    </div>
  );
};

export default GstDetailsModal;
