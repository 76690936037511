import React from "react";
import "./index.scss";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import GlobalLoader from "../components/GlobalLoader/GlobalLoader";
import { isMobileDevice } from "../utils/utils";
import MobileNavbar from "./MobileWebNavbar/MobileNavbar";
import MobileSidebar from "./MobileWebSidebar/MobileSidebar";

const LayoutWrapper = (props) => {
  return (
    <div className="d-flex layout_wrapper" id="layout_wrapper">
      <Sidebar />
      <div className="w-100 layout_body">
        {isMobileDevice() ? <MobileNavbar /> : <Navbar />}
        <div className="layout_main">{props.children}</div>
        {isMobileDevice() && <MobileSidebar />}
      </div>
      <GlobalLoader />
    </div>
  );
};

export default LayoutWrapper;
