import React, { Fragment, useState } from "react";
import "./index.scss";
import CustomModal from "../CustomModal/CustomModal";
import Looking from "../../../assets/lottie/looking.json";
import CheckCircle from "../../../assets/images/common/check_circle.svg";
import CustomField from "../../CustomFields/CustomFields";
import { isExactAddress, isObjEmpty } from "../../../constants/Constants";
import CustomButton from "../../Button/CustomButton/CustomButton";
import VerifiedModal from "../VerifiedModal/VerifiedModal";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  fetchdashboardModalStates,
  setAadharDetails,
  setIsAadharModalDetailsToggle,
  setIsAadharValid,
} from "../../../pages/Dashboard/ModalSection/modalSectionStore/store";
import { toast } from "react-hot-toast";
import {
  GET_AADHAR_DETAILS,
  GET_PIN_CODE_CITY,
  UPDATE_AADHAR_ADDRESS,
} from "../../../axios/apiendPoint";
import useAxiosPrivate from "../../../axios/services";
import FormInput from "../../FormInput/FormInput";
import { useEffect } from "react";
import { isMobileDevice } from "../../../utils/utils";
import LottiePlayer from "../../LottiePlayer/LottiePlayer";

const AadharDetails = () => {
  const dispatch = useDispatch();
  const { axiosPost, axiosGet } = useAxiosPrivate();

  const modalDetails = useSelector(
    (state) => state.dashboardModalReducer.modalStateDetails,
  )?.data;
  const { page } = modalDetails || {};

  const isOpen = useSelector(
    (state) => state.dashboardModalReducer.isAadharDetailsModalOpen,
  );
  const isAadharValid = useSelector(
    (state) => state.dashboardModalReducer.isAadharValid,
  );
  const aadharDetails = useSelector(
    (state) => state.dashboardModalReducer.aadharDetails,
  );
  // const aadharDetails = {
  //   aadhaarNumber: "123456789012",
  //   name: "John Doe",
  //   address: "123, ABC Street, XYZ City ",
  //   dob: "01-01-1990",
  //   // photo: "../../../assets/images/common/aadhar_icon.svg",
  //   photo: "https://picsum.photos/200/200",
  // };

  const [sameAddress, setSameAddress] = useState(["Yes"]);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [city, SetCity] = useState(null);

  const toggle = () => dispatch(setIsAadharModalDetailsToggle());

  const { control, handleSubmit, reset } = useForm({
    mode: "onChange",
  });

  const handleToGetPinCode = async (e) => {
    let pincode = e.target.value;

    if (pincode.length === 6) {
      const response = await axiosGet(GET_PIN_CODE_CITY, `pincode=${pincode}`);
      if (response?.success) {
        SetCity(response.data);
      } else {
        SetCity(null);
      }
    } else SetCity(null);
  };

  const handleToConfirmAadhar = async (data) => {
    const payload = {
      isSameAddress: sameAddress[0],
      address: data.address || aadharDetails?.address,
      pincode: data.pincode || aadharDetails?.pincode,
    };

    const response = await axiosPost(UPDATE_AADHAR_ADDRESS, payload);
    if (response?.success) {
      toast.success(response?.msg);
      reset();
      toggle();
      setSubmitFlag(true);
      dispatch(setIsAadharValid(false));
      dispatch(fetchdashboardModalStates({ axiosGet }));
    } else {
      setSubmitFlag(false);
      dispatch(fetchdashboardModalStates({ axiosGet }));
    }
  };

  const getAadharDetails = async () => {
    if (isObjEmpty(aadharDetails)) {
      const response = await axiosGet(GET_AADHAR_DETAILS);
      if (response?.success) {
        toast.success(response?.msg);
        dispatch(setAadharDetails(response?.data));
      }
    }
  };

  useEffect(() => {
    if (page === "ADDRESS_DETAILS_PAGE") getAadharDetails();
  }, [page]);

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      modaltitle={
        submitFlag ||
        (isAadharValid && isMobileDevice() ? null : "Your Aadhaar Info")
      }
      width="592px"
      modalbody={
        <Fragment>
          {submitFlag ? (
            <VerifiedModal verifiedText="Aadhar Verified Successfully!" />
          ) : (
            <Fragment>
              {isAadharValid ? (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="mb-5">
                    <LottiePlayer
                      animationData={Looking}
                      height={172}
                      width={174}
                    />
                  </div>
                  <p className="m-0 p-0 modal_header mb-3 text-center">
                    We are fetching your Aadhaar details
                  </p>
                </div>
              ) : (
                <Fragment>
                  <div className="aadhar_information_container d-flex align-items-start justify-content-between gap-2">
                    <div className="user_image_block w-20">
                      <img
                        src={`data:image/png;base64,${aadharDetails?.photo}`}
                        alt="profile"
                        className="user_image"
                      />
                    </div>

                    <div className="aadhar_user_details_content  w-75">
                      <Fragment>
                        <div className="d-flex align-items-start justify-content-between mb-4">
                          <span className="detail_heading">Aadhaar Number</span>
                          <span className="detail_answer">
                            <img
                              src={CheckCircle}
                              alt="circle"
                              className="me-1"
                            />
                            {aadharDetails?.aadhaarNumber}
                          </span>
                        </div>

                        <div className="d-flex align-items-start justify-content-between mb-4">
                          <span className="w-25 detail_heading">Name</span>{" "}
                          <span className="detail_answer">
                            {aadharDetails?.name}
                          </span>
                        </div>

                        <div className="d-flex align-items-start justify-content-between mb-4">
                          <span className="w-25 detail_heading">Address</span>{" "}
                          <span className="detail_answer">
                            {aadharDetails?.address}
                          </span>
                        </div>

                        <div className="d-flex align-items-start justify-content-between">
                          <span className="w-25 detail_heading">DOB</span>{" "}
                          <span className="detail_answer">
                            {aadharDetails?.dob}
                          </span>
                        </div>
                      </Fragment>
                    </div>
                  </div>
                  <p className="p-0 m-0 same_aadhar_content mt-4 mb-2">
                    Is your current address same as aadhaar?
                  </p>
                  <CustomField
                    value={sameAddress}
                    setvalue={setSameAddress}
                    options={isExactAddress}
                  />

                  <form onSubmit={handleSubmit(handleToConfirmAadhar)}>
                    {sameAddress[0] === "No" && (
                      <Fragment>
                        <div className="my-3">
                          <Controller
                            control={control}
                            name="address"
                            render={({ field }) => (
                              <FormInput
                                field={field}
                                placeholder="Enter complete address"
                              />
                            )}
                          />
                        </div>

                        <div className="my-3">
                          <Controller
                            control={control}
                            name="pincode"
                            render={({ field }) => (
                              <FormInput
                                field={field}
                                handleChange={handleToGetPinCode}
                                placeholder="Enter Your Area Pincode"
                              />
                            )}
                          />
                        </div>
                        {city && (
                          <p className="pincode_city">{`${city.city}, ${city.state}`}</p>
                        )}
                      </Fragment>
                    )}

                    <div className="mt-3">
                      <CustomButton
                        fullWidth={isMobileDevice() ? true : false}
                        alignCenter
                        type="submit"
                      />
                    </div>
                  </form>
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      }
    />
  );
};

export default AadharDetails;
