import React, { useCallback, useEffect, useState } from "react";
import { newQuoteRequestHeader } from "../../constants/Constants";
import "./index.scss";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPolicyDeals,
  fetchoptionsData,
  resetPolicyDeals,
  updatePolicyDeals,
} from "./Store/store";
import useAxiosPrivate from "../../axios/services";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "../../components/Button/CustomButton/CustomButton";
import "flatpickr/dist/flatpickr.css";
import PolicyDeals from "./PolicyDeals/PolicyDeals";
import Loader from "../../components/Loader/Loader";
import {
  JARVIS_BASE_URL,
  MARK_FAVORITE_POLICY_DEAL,
  UNMARK_FAVORITE_POLICY_DEAL,
} from "../../axios/apiendPoint";
import { formatDate, debounce, isMobileDevice } from "../../utils/utils";
import DatePicker from "../../components/DatePicker/Datepicker";

const GridPage = () => {
  const isFirstLoad = useSelector((state) => state.gridReducer.isFirstLoad);
  const [activeHeader, setActiveHeader] = useState(newQuoteRequestHeader[0]);

  const dispatch = useDispatch();
  const { axiosJarvisApi, axiosCustomAll } = useAxiosPrivate();
  const responseData = useSelector((state) => state.gridReducer.optionsData);
  const policyDeals = useSelector((state) => state.gridReducer.policyDeals);

  const gvwRangeOptions = [
    { label: "1 - 2.5T", value: "1-2.5" },
    { label: "2.5T - 3.5T", value: "2.5-3.5" },
    { label: "3.5T - 7.5T", value: "3.5-7.5" },
    { label: "7.5T - 12T", value: "7.5-12" },
    { label: "12T - 16T", value: "12-16" },
    { label: "16T - 20T", value: "16-20" },
    { label: "20T - 40T", value: "20-40" },
    { label: "40T+", value: "40-99" },
  ];

  const initialData = {
    product: "",
    insurerIds: [],
    rtocode: "",
    businessType: "",
    make: "",
    model: "",
    date: new Date(),
    policyType: "",
    fuelType: "",
    vehicleSubclass: "",
    gvw: "",
  };

  const handleHeaderChange = (item) => {
    setActiveHeader(item);
    if (item.key === "CAR") {
      resetValues({ ...initialData, vehicleSubclass: null, gvwData: null });
    } else {
      resetValues({ ...initialData, fuelType: null, gvwData: null });
    }
    if (item.key === "GCV") {
      resetValues({ ...initialData, gvwData: "", fuelType: null });
    }
  };

  // Assuming resetValues is a function that resets the values in your form
  const resetValues = (data) => {
    for (const key in data) {
      setValue(key, data[key]);
    }
  };

  const transformInsurerData = (insurerData) => {
    return insurerData?.map((insurer) => ({
      value: insurer.insurerId,
      label: insurer.insurerName,
    }));
  };

  const transformStringArray = (data) => {
    return data?.map((item) => ({
      value: item,
      label: item,
    }));
  };

  const insurerOptions = transformInsurerData(responseData.insurerData);

  const getOptions = (item) => {
    switch (item) {
      case "insurerData":
        return insurerOptions;

      case "rtoData":
        return responseData.rtoData;

      case "makes":
        return responseData.makes;

      case "models":
        return responseData.models;
      case "gvwRangeOptions":
        return gvwRangeOptions;
      default:
        return transformStringArray(responseData[item]);
    }
  };

  const searchKeys = ["rtoSubstring", "makeSubstring", "modelSubstring"];

  const fixedOptionKeys = [
    {
      key: "vehicleSubclass",
      label: "Vehicle Subclass",
      placeholder: "Vehicle Subclass",
      isMulti: false,
      optionsKey: "vehicleSubclass",
      formKey: "vehicleSubclass",
      isDisplay: activeHeader?.key !== "CAR",
    },
    {
      key: "rtoSubstring",
      label: "RTO",
      placeholder: "Enter RTO",
      isMulti: false,
      optionsKey: "rtoData",
      formKey: "rtocode",
      isDisplay: true,
    },
    {
      key: "businessType",
      label: "Business Type",
      placeholder: "Select Business Type",
      isMulti: false,
      optionsKey: "businessType",
      formKey: "businessType",
      isDisplay: true,
    },
    {
      key: "policyType",
      label: "Policy Type",
      placeholder: "Select Policy Type",
      isMulti: false,
      optionsKey: "policyType",
      formKey: "policyType",
      isDisplay: true,
    },
    {
      key: "fuelType",
      label: "Fuel Type",
      placeholder: "Select Fuel Type",
      isMulti: false,
      optionsKey: "fuelType",
      formKey: "fuelType",
      isDisplay: activeHeader?.key === "CAR",
    },
    {
      key: "gvw",
      label: "GVW",
      placeholder: "GVW Range",
      isMulti: false,
      optionsKey: "gvwRangeOptions",
      formKey: "gvwData",
      isDisplay: activeHeader?.key === "GCV",
    },
    {
      key: "insurerId",
      label: "Insurer",
      placeholder: "Enter Insurer",
      isMulti: true,
      optionsKey: "insurerData",
      formKey: "insurerIds",
      allowSelectAll: true,
      isDisplay: true,
    },
    {
      key: "makeSubstring",
      label: "Make",
      placeholder: "Enter Make",
      isMulti: false,
      optionsKey: "makes",
      formKey: "make",
      isDisplay: true,
    },
    {
      key: "modelSubstring",
      label: "Model",
      placeholder: "Enter Model",
      isMulti: false,
      optionsKey: "models",
      formKey: "model",
      isDisplay: true,
    },
  ];

  const handleInputChange = (key, inputValue, reason) => {
    if (!searchKeys.includes(key)) return;
    let value = inputValue.trim();
    let makeCall = false;
    const formData = getValues();

    let searchParams = {};

    // if inputType is rtoSubstring and the length of the input value is greater than or equal to 2

    if (key === "rtoSubstring") {
      let optionsForKey = getOptions("rtoData");
      // check if rtoData already has the value then don't make the call
      if (
        optionsForKey &&
        optionsForKey.some((item) =>
          item.label.toLowerCase().includes(value.toLowerCase()),
        )
      ) {
        return;
      }

      if (value.length >= 2 && reason === "input-change") {
        searchParams.rtoSubstring = value;
        makeCall = true;
      }
    }

    if (key === "makeSubstring") {
      let optionsForKey = getOptions("makes");
      if (
        reason === "input-change" &&
        optionsForKey &&
        optionsForKey.some((item) =>
          item.label.toLowerCase().includes(value.toLowerCase()),
        )
      ) {
        return;
      }
      if (value.length > 2) {
        searchParams.makeSubstring = value;
        makeCall = true;
      }
    }

    if (key === "modelSubstring") {
      let optionsForKey = getOptions("models");
      if (
        optionsForKey &&
        optionsForKey.some((item) =>
          item.label.toLowerCase().includes(value.toLowerCase()),
        )
      ) {
        return;
      }
      if (value.length > 2 && reason === "input-change") {
        searchParams.modelSubstring = value;
        searchParams.makeSubstring = formData.make;
        makeCall = true;
      }
    }
    // Fetch data based on search params
    if (makeCall) {
      searchParams.product = activeHeader?.key;
      debouncedDispatch(searchParams, key);
    }
  };

  const policyDealsForm = yup.object().shape({
    rtocode: yup.string().required("RTO is required"),
    make: yup.string().required("Make is required"),
    model: yup.string().required("Model is required"),
    policyType: yup.string().required("Policy Type is required"),
    businessType: yup.string().required("Business Type is required"),
    fuelType:
      activeHeader?.key === "CAR"
        ? yup.string().required("Fuel Type is required")
        : null,
    vehicleSubclass:
      activeHeader?.key !== "CAR"
        ? yup.string().required("Vehicle Subclass is required ")
        : null,
    insurerIds: yup
      .array()
      .min(1, "Insurer is required")
      .required("Insurer is required"),
    gvwData:
      activeHeader?.key === "GCV"
        ? yup.string().required("GVW Range is required")
        : null,
    date: yup.date().nullable().required("Date is required"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: "onChange", // "onSubmit
    resolver: yupResolver(policyDealsForm),
    defaultValues: {},
  });

  const handlePolicyDealsSubmit = (data) => {
    let params = `product=${activeHeader?.key}`;
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        if (Array.isArray(data[key])) {
          params += `&${key}=${data[key].join(",")}`;
        } else if (key === "date") {
          let formattedDate = formatDate(data[key], "DD-MM-YY");
          params += `&${key}=${formattedDate}`;
        } else {
          if (key === "gvwData") {
            let gvwRange = data[key].split("-");
            params += `&gvwStartRange=${gvwRange[0]}&gvwEndRange=${gvwRange[1]}`;
          } else {
            params += `&${key}=${data[key]}`;
          }
        }
      }
    });

    dispatch(
      fetchPolicyDeals({
        axiosGet: axiosJarvisApi,
        params: params,
      }),
    );
  };
  const debouncedDispatch = useCallback(
    debounce((searchParams, searchKey) => {
      const searchParamsList = Object.entries(searchParams).map(
        ([key, value]) => `${key}=${value}`,
      );

      dispatch(
        fetchoptionsData({
          params: searchParamsList.join("&"),
          axiosGet: axiosJarvisApi,
          searchKey: searchKey,
        }),
      );
    }, 500),
    [dispatch],
  );

  useEffect(() => {
    dispatch(
      fetchoptionsData({
        params: `product=${activeHeader?.key}`,
        axiosGet: axiosJarvisApi,
      }),
    );
    setValue("date", new Date());
  }, [activeHeader]);

  useEffect(() => {
    dispatch(resetPolicyDeals());
  }, []);

  const handleChangeFavourite = async (policyDetails) => {
    if (policyDetails.favouriteId) {
      let response = await axiosCustomAll({
        baseUrl: JARVIS_BASE_URL,
        url: UNMARK_FAVORITE_POLICY_DEAL,
        method: "POST",
        data: {
          favouriteId: policyDetails.favouriteId,
        },
      });
      if (response) {
        // remove favourite id from policy details
        dispatch(
          updatePolicyDeals({
            ...policyDetails,
            favouriteId: null,
          }),
        );
      }
      return;
    }
    let {
      product = "",
      rtocode = "",
      businessType = "",
      make = "",
      model = "",
      date = new Date(),
      policyType = "",
      fuelType = "",
      vehicleSubclass = "",
      gvwData = "",
    } = getValues();

    let searchQuery = [
      product,
      rtocode,
      businessType,
      make,
      model,
      formatDate(date, "DD-MMM-YYYY"),
      policyType,
      fuelType,
      vehicleSubclass,
      gvwData,
    ];
    searchQuery =
      searchQuery.filter((item) => item !== "" && item !== null) || [];

    searchQuery = searchQuery.join(" - ");

    let response = await axiosCustomAll({
      baseUrl: JARVIS_BASE_URL,
      url: MARK_FAVORITE_POLICY_DEAL,
      method: "POST",
      data: {
        dealId: policyDetails.dealId,
        product: policyDetails.product,
        searchQuery: searchQuery,
      },
    });
    if (response && response.data) {
      dispatch(
        updatePolicyDeals({
          ...policyDetails,
          favouriteId: response.data.favouriteId,
        }),
      );
    }
  };
  if (
    policyDeals &&
    (policyDeals.declineDeals.length > 0 ||
      policyDeals.specialDeals.length > 0 ||
      policyDeals.marketDeals.length > 0)
  ) {
    let formData = getValues();
    let policyHeaderData = {
      product: activeHeader?.key,
      insurerIds: insurerOptions?.filter((item) =>
        formData.insurerIds?.includes(item.value),
      ),
      rtocode: formData.rtocode,
      businessType: formData.businessType,
      make: formData.make,
      model: formData.model,
      date: formatDate(formData.date, "DD-MMM-YYYY"),
      policyType: formData.policyType,
      fuelType: formData.fuelType,
      vehicleSubclass: formData.vehicleSubclass,
      gvw: formData.gvwData,
    };

    return (
      <PolicyDeals
        policyHeaderData={policyHeaderData}
        policyOffersData={policyDeals}
        handleChangeFavourite={handleChangeFavourite}
      />
    );
  }
  const hasNoDeals =
    policyDeals &&
    !policyDeals.isLoading &&
    policyDeals.declineDeals.length === 0 &&
    policyDeals.specialDeals.length === 0 &&
    policyDeals.marketDeals.length === 0;
  return (
    <div className="grid-component-container">
      <div className="grid_modal_header">
        {newQuoteRequestHeader?.map((item, index) => (
          <div
            key={index}
            className={`d-flex align-items-center justify-content-center flex-column header_items cursor_pointer
                    ${activeHeader?.id === index ? "active_header_item" : ""}`}
            onClick={() => handleHeaderChange(item)}
          >
            {/* <img src={item.image} alt={item.id} height={"12px"} width={"20px"} /> */}
            <item.image />
            <div
              className={
                activeHeader?.id === index
                  ? "active_veichle_name"
                  : "veichle_name"
              }
            >
              {item.name}
            </div>
          </div>
        ))}
      </div>
      <form
        onSubmit={handleSubmit(handlePolicyDealsSubmit)}
        className="d-flex align-items-center justify-content-center"
      >
        <div className="grid-options">
          {fixedOptionKeys.map(
            (item, index) =>
              item.isDisplay && (
                <div
                  className="grid-options-container"
                  key={`fixed-options-${index}`}
                >
                  <Controller
                    control={control}
                    name={item.formKey}
                    render={({ field }) => {
                      let { value, onChange, ...rest } = field;
                      return (
                        <MultiSelect
                          {...rest}
                          options={getOptions(item.optionsKey)}
                          placeholder={item.placeholder}
                          isMulti={item.isMulti}
                          isSearchable
                          isClearable
                          // isLoading={isLoading}
                          allowSelectAll={item.allowSelectAll}
                          onInputChange={(inputValue, actionMeta) =>
                            handleInputChange(
                              item.key,
                              inputValue,
                              actionMeta.action,
                            )
                          }
                          isDisabled={
                            item.key === "modelSubstring" && !getValues("make")
                          }
                          onChange={(selectedOptions, actionMeta) => {
                            if (item.isMulti) {
                              onChange(
                                selectedOptions
                                  ? selectedOptions.map((item) => item.value)
                                  : [],
                              );
                            } else {
                              if (item.key === "makeSubstring") {
                                setValue("modelSubstring", "");
                                setValue("model", "");
                                handleInputChange(
                                  item.key,
                                  selectedOptions?.value || "",
                                  actionMeta.action,
                                );
                              }
                              onChange(
                                selectedOptions ? selectedOptions.value : "",
                              );
                            }
                          }}
                          value={
                            item.isMulti
                              ? value &&
                                getOptions(item.optionsKey) &&
                                getOptions(item.optionsKey).filter((item) =>
                                  value.includes(item.value),
                                )
                              : value &&
                                getOptions(item.optionsKey) &&
                                getOptions(item.optionsKey).find(
                                  (item) => item.value === value,
                                )
                          }
                        />
                      );
                    }}
                  />
                  <span className="error_class">
                    {errors && errors[item.formKey] && (
                      <small> {errors[item.formKey]?.message}</small>
                    )}
                  </span>
                </div>
              ),
          )}

          <div className="grid-options-container">
            <Controller
              control={control}
              name="date"
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  value={value}
                  options={{
                    dateFormat: "d-m-y",
                  }}
                  onChange={(date) => {
                    if (date && date.length > 0) {
                      onChange(date[0]);
                    } else {
                      onChange(null);
                    }
                  }}
                  placeholder="Select Date"
                  className="flat_picker_date"
                />
              )}
            />
            <span className="error_class">
              {errors && errors.date && <small> {errors.date?.message}</small>}{" "}
            </span>
          </div>
          <div className="grid-options-container">
            <CustomButton
              fullwidth
              alignCenter
              className="secondary_button"
              buttonName="Search"
              // icon={<Search />}
              type="submit"
            />
          </div>

          {isMobileDevice() && !isFirstLoad && hasNoDeals && (
            <div className="no_data-mweb">No Data Found</div>
          )}
        </div>
      </form>
      {!isMobileDevice() && (
        <div className="policy_deals_container">
          {policyDeals && policyDeals.isLoading && (
            <div>
              <Loader /> <span className="loader_text">Loading...</span>
            </div>
          )}
          {!isFirstLoad && hasNoDeals && <div>No Data Found</div>}
        </div>
      )}
    </div>
  );
};

export default GridPage;
