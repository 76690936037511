import React, { useState } from "react";
import "./index.scss";
import { sidebarMenu } from "../Sidebar/Sidebar";
import { NavLink } from "react-router-dom";
import RaiseIcon from "../../assets/images/sidebar/raisemwebicon.svg";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../components/Modal/CustomModal/CustomModal";
import { ReactComponent as QRIcon } from "../../assets/images/sidebar/quoteRequestIcon.svg";
import { ReactComponent as MRIcon } from "../../assets/images/sidebar/mappingRequestIcon.svg";
import {
  setShowRequestNewQuoteModal,
  setShowPolicyMappingModal,
} from "../../pages/RequestNewQuote/Store/store";
import RequestNewQuoteModal from "../../pages/RequestNewQuote";
import CreatePolicyMappingRequest from "../../components/CreatePolicyMappingRequest/CreatePolicyMappingRequest";
const MobileSidebar = () => {
  const isPartnerRegistered = useSelector(
    (state) => state.dashboardModalReducer.isPartnerRegistered,
  );
  const dispatch = useDispatch();
  const [isRaiseRequestOpen, setIsRaiseRequestOpen] = useState(false);
  const showPolicyMappingModal = useSelector(
    (state) => state.newQuoteRequestReducer.showPolicyMappingModal,
  );

  return (
    <div className="sidebar-main">
      {isRaiseRequestOpen && (
        <CustomModal
          isOpen={isRaiseRequestOpen}
          toggle={() => setIsRaiseRequestOpen(!isRaiseRequestOpen)}
          style={{
            position: "unset",
            top: "100px !important", // Force position
            left: "50% !important",
            transform: "translateX(-50%) !important",
            bottom: "unset !important", // Remove bottom positioning
          }}
          background={"#F9F9F9"}
          modalbody={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "200px",
                gap: "30px",
              }}
            >
              <div
                style={{ fontSize: "16px", color: "black", fontWeight: "600" }}
              >
                Raise New Request{" "}
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    width: "40%",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    dispatch(setShowRequestNewQuoteModal());
                    setIsRaiseRequestOpen(false);
                  }}
                >
                  <div className="m-web-raise-header">
                    <QRIcon className="m-web-raise-icon" />
                  </div>
                  <span className="m-web-raise-modal-text">
                    Raise Quote Request
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    width: "40%",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    dispatch(setShowPolicyMappingModal());
                    setIsRaiseRequestOpen(false);
                  }}
                >
                  <div className="m-web-raise-header">
                    <MRIcon className="m-web-raise-icon" />
                  </div>
                  <span className="m-web-raise-modal-text">
                    Raise Mapping Request
                  </span>
                </div>
              </div>
            </div>
          }
        />
      )}
      <div className="inner-menu-mweb">
        <div className="raise-request-sidebar">
          <div
            className="raise-icon"
            onClick={() => setIsRaiseRequestOpen(!isRaiseRequestOpen)}
          >
            <img src={RaiseIcon} alt="raiseicon" />
          </div>
          <div className="menu-name-raise">Raise Request</div>
        </div>
        {sidebarMenu
          .filter((item) => item.mobileName)
          .map((item, index) => {
            const isDisabled =
              item.isRegisteredRequired && !isPartnerRegistered;

            return isDisabled ? (
              <div key={index} className="inner-menu-item">
                <div className="mweb-sidebar-icon">{item.icon}</div>
                <div className="menu-name">{item.mobileName}</div>
              </div>
            ) : (
              <NavLink
                key={index}
                to={item.link}
                className={({ isActive }) =>
                  isActive
                    ? "inner-menu-item active-sidebar"
                    : "inner-menu-item"
                }
              >
                <div className="mweb-sidebar-icon">{item.icon}</div>
                <div className="menu-name">{item.mobileName}</div>
              </NavLink>
            );
          })}
      </div>
      <RequestNewQuoteModal />
      {showPolicyMappingModal && (
        <CreatePolicyMappingRequest
          toggle={() => dispatch(setShowPolicyMappingModal())}
        />
      )}
    </div>
  );
};

export default MobileSidebar;
