import HDFCGenImg from "../assets/images/Insurers/Hdfc Ergo.svg";
import CholaImg from "../assets/images/Insurers/Cholamandalam.svg";
import GoDigitImg from "../assets/images/Insurers/Digit.svg";
import BajajImg from "../assets/images/Insurers/Bajaj Allianz.svg";
import ICICIImg from "../assets/images/Insurers/ICICI Lombard.svg";
import RelianceImg from "../assets/images/Insurers/Reliance.svg";
import TATAImg from "../assets/images/Insurers/Tata AIG.svg";
import SBIGenImg from "../assets/images/Insurers/SBI General.svg";
import IFFCOImg from "../assets/images/Insurers/Iffco Tokio.svg";
import FutureImg from "../assets/images/Insurers/Future Generali.svg";
import LibertyImg from "../assets/images/Insurers/Liberty Videocon.svg";
import ShriramImg from "../assets/images/Insurers/Shri Ram.svg";
// no image for zuno
import ZunoImg from "../assets/images/Insurers/Zuno.svg";
import UnitedImg from "../assets/images/Insurers/UnitedIndia.svg";
import NationalImg from "../assets/images/Insurers/National Insurance.svg";
import MagmaImg from "../assets/images/Insurers/Magma HDI.svg";
// rsa
import RSAImg from "../assets/images/Insurers/Royal Subdaram.svg";
import UniversalSompooImg from "../assets/images/Insurers/UniversalSompo.svg";
import KotakMahindra from "../assets/images/Insurers/Kotak Mahindra.svg";
import NewIndia from "../assets/images/Insurers/New India.svg";

//M-web-logo
import MZunoImg from "../assets/images/MInsurers/M-Zuno.svg";
import MUnitedImg from "../assets/images/MInsurers/M-UnitedIndia.svg";
import MHDFCGenImg from "../assets/images/MInsurers/M-HdfErgo.svg";
import MCholaImg from "../assets/images/MInsurers/M-Cholamandalam.svg";
import MGoDigitImg from "../assets/images/MInsurers/M-Digit.svg";
import MBajajImg from "../assets/images/MInsurers/M-Bajaj Allianz.svg";
import MICICImg from "../assets/images/MInsurers/M-ICICI Lombard.svg";
import MRelianceImg from "../assets/images/MInsurers/M-Reliance.svg";
import MTATAImg from "../assets/images/MInsurers/M-Tata AIG.svg";
import MSBIGenImg from "../assets/images/MInsurers/M-SBI General.svg";
import MIFFCOImg from "../assets/images/MInsurers/M-Iffco Tokio.svg";
import MFutureImg from "../assets/images/MInsurers/M-Future Generali.svg";
import MLibertyImg from "../assets/images/MInsurers/M-Liberty Videocon.svg";
import MKotakMahindra from "../assets/images/MInsurers/M-Kotak Mahindra.svg";
import MUniversalSompooImg from "../assets/images/MInsurers/M-usgi-logo.svg";
import MRSAImg from "../assets/images/MInsurers/M-Royal Subdaram.svg";
import MNationalImg from "../assets/images/MInsurers/M-National Insurance.svg";
import MShriramImg from "../assets/images/MInsurers/M-Shri Ram.svg";
import MMagmaImg from "../assets/images/MInsurers/M-Magma HDI.svg";
import MNewIndia from "../assets/images/MInsurers/M-NewIndia.svg";
import { isMobileDevice } from "../utils/utils";

export const InsurerLogoMap = {
  "INSURER-1": {
    insurerId: "INSURER-1",
    Logo: HDFCGenImg,
    MLogo: MHDFCGenImg,
  },
  "INSURER-2": {
    insurerId: "INSURER-2",
    Logo: CholaImg,
    MLogo: MCholaImg,
  },
  "INSURER-3": {
    insurerId: "INSURER-3",
    Logo: GoDigitImg,
    MLogo: MGoDigitImg,
  },
  "INSURER-4": {
    insurerId: "INSURER-4",
    Logo: BajajImg,
    MLogo: MBajajImg,
  },
  "INSURER-5": {
    insurerId: "INSURER-5",
    Logo: ICICIImg,
    MLogo: MICICImg,
  },
  "INSURER-6": {
    insurerId: "INSURER-6",
    Logo: RelianceImg,
    MLogo: MRelianceImg,
  },
  "INSURER-7": {
    insurerId: "INSURER-7",
    Logo: TATAImg,
    MLogo: MTATAImg,
  },
  "INSURER-8": {
    insurerId: "INSURER-8",
    Logo: SBIGenImg,
    MLogo: MSBIGenImg,
  },
  "INSURER-9": {
    insurerId: "INSURER-9",
    Logo: IFFCOImg,
    MLogo: MIFFCOImg,
  },
  "INSURER-10": {
    insurerId: "INSURER-10",
    Logo: FutureImg,
    MLogo: MFutureImg,
  },
  "INSURER-11": {
    insurerId: "INSURER-11",
    Logo: LibertyImg,
    MLogo: MLibertyImg,
  },
  "INSURER-12": {
    insurerId: "INSURER-12",
    Logo: ShriramImg,
    MLogo: MShriramImg,
  },
  "INSURER-13": {
    insurerId: "INSURER-13",
    Logo: ZunoImg,
    MLogo: MZunoImg,
  },
  "INSURER-14": {
    insurerId: "INSURER-14",
    Logo: UnitedImg,
    MLogo: MUnitedImg,
  },
  "INSURER-15": {
    insurerId: "INSURER-15",
    Logo: NationalImg,
    MLogo: MNationalImg,
  },
  "INSURER-16": {
    insurerId: "INSURER-16",
    Logo: MagmaImg,
    Mlogo: MMagmaImg,
  },
  "INSURER-17": {
    insurerId: "INSURER-17",
    Logo: RSAImg,
    MLogo: MRSAImg,
  },
  "INSURER-18": {
    insurerId: "INSURER-18",
    Logo: UniversalSompooImg,
    MLogo: MUniversalSompooImg,
  },
  "INSURER-19": {
    insurerId: "INSURER-19",
    Logo: KotakMahindra,
    MLogo: MKotakMahindra,
  },
  "INSURER-20": {
    Logo: NewIndia,
    Mlogo: MNewIndia,
  },
};

export const getInsurerLogo = (insurerId) => {
  const insurer = InsurerLogoMap[insurerId];
  return isMobileDevice() ? insurer?.MLogo : insurer?.Logo;
};
