import React from "react";
import "./index.scss";
//import car bike and bus svg from assets/images/NewQuoteRequest
import { ReactComponent as Car } from "../../../assets/images/NewQuoteRequest/Car.svg";
import { ReactComponent as Bike } from "../../../assets/images/NewQuoteRequest/Bike.svg";
import { ReactComponent as Bus } from "../../../assets/images/NewQuoteRequest/Bus.svg";
import { ReactComponent as BookingIcon } from "../../../assets/images/sidebar/bookingIcon.svg";
import { ReactComponent as NotFound } from "../../../assets/images/common/notFound.svg";
import { formatDate, isMobileDevice } from "../../../utils/utils";
import { ReactComponent as Tick } from "../../../assets/images/Dashboard/tick.svg";
import { ReactComponent as Arrow } from "../../../assets/images/Dashboard/arrow.svg";

import { navRoutes } from "../../../router/Url";
import { useNavigate } from "react-router-dom";
const QuotesCard = ({ type, cardDetails, recentDetails = {} }) => {
  const [isActive, setIsActive] = React.useState("Car");
  const handleClick = (prop) => {
    setIsActive(prop);
  };
  const navigate = useNavigate();
  const handleViewAll = () => {
    navigate(navRoutes.quoteRequestPage);
  };
  return (
    <div
      className="main-container"
      style={{ marginBottom: isMobileDevice() ? "14px" : "" }}
    >
      <div className="card-container">
        <div className="card-upperhalf">
          <div className="card-top">
            <div
              className="card-content"
              style={{ display: "flex", gap: "6px", alignItems: "center" }}
            >
              <div
                style={{
                  background: "#E8F3FC",
                  borderRadius: "25px",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <BookingIcon color={"#0691FC"} />
              </div>
              <div>Offline Quotes</div>
            </div>
            {isMobileDevice() && (
              <div>
                <span className="view-all" onClick={handleViewAll}>
                  {" "}
                  View All
                  <Arrow />
                </span>
              </div>
            )}
          </div>
          <div className="card-mid">
            <div> {cardDetails?.totalQuoteRequest} </div>
            requests
          </div>
        </div>
        <div className="card-bottom">
          <div
            className={!isMobileDevice() && isActive == "Car" ? "active" : ""}
            onClick={() => {
              if (!isMobileDevice()) {
                handleClick("Car");
              }
            }}
          >
            <Car />
            <div className="w_full">{cardDetails?.totalCarQuoteRequest}</div>
          </div>
          <div
            className={!isMobileDevice() && isActive == "TW" ? "active" : ""}
            onClick={() => {
              if (!isMobileDevice()) {
                handleClick("TW");
              }
            }}
          >
            <Bike />
            <div className="w_full">{cardDetails?.totalTWQuoteRequest}</div>
          </div>
          <div
            className={!isMobileDevice() && isActive == "CV" ? "active" : ""}
            onClick={() => {
              if (!isMobileDevice()) {
                handleClick("CV");
              }
            }}
          >
            <Bus />
            <div className="w_full">{cardDetails?.totalCVQuoteRequest}</div>
          </div>
        </div>
      </div>
      {!isMobileDevice() && (
        <div className="recent-bookings">
          <h2>Recent Motor Quote Requests</h2>
          <div className="booking-items">
            {recentDetails["recent" + isActive + type]?.map((item, idx) => (
              <div className="booking-item" key={`quote-card-${idx}`}>
                <div className="booking-details">
                  <div className="img">
                    {isActive == "Car" ? (
                      <Car />
                    ) : isActive == "TW" ? (
                      <Bike />
                    ) : (
                      <Bus />
                    )}
                  </div>
                  <div className="registration-data">
                    <div className="policy-number">
                      {item.registrationNumber}
                    </div>
                    <div className="status">
                      <Tick />
                      {item.status}
                    </div>
                  </div>
                </div>
                <div className="issued-date">{formatDate(item.timestamp)}</div>
              </div>
            ))}
            {recentDetails["recent" + isActive + type] === null ||
            recentDetails["recent" + isActive + type]?.length === 0 ? (
              <div className="notfound-card">
                <NotFound />
                <span>No new Quotes</span>
              </div>
            ) : (
              <span className="view-all" onClick={handleViewAll}>
                {" "}
                View All
                <Arrow />
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuotesCard;
