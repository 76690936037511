import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Controller } from "react-hook-form";
import FileSelector from "../../components/FileSelector/FileSelector";

const DocFields = ({
  errors,
  control,
  activeHeader,
  isNew,
  previousPolicyFlag,
}) => {
  const [docFields, setDocFields] = useState([]);

  const mandatoryDocFields = [
    {
      name: "POLICY_COPY",
      label: "Policy Copy",
      id: "fileInput_POLICY_COPY",
      fileTypes: ["pdf"],
    },
  ];

  const busDocFields = [
    {
      name: "PERMIT",
      label: "Permit Document",
      id: "fileInput_Permit",
      fileTypes: ["pdf", "jpg"],
    },
  ];

  const otherDocFields = {
    name: "OTHERS",
    label: "Other Doc",
    id: "fileInput_OTHER_DOC",
    fileTypes: ["pdf", "jpg", "jpeg", "png"],
  };

  const getDocFields = () => {
    let docFields = [...mandatoryDocFields, otherDocFields];

    if (activeHeader?.key === "BUS") {
      docFields = [...docFields, ...busDocFields];
    }
    return docFields;
  };

  useEffect(() => {
    setDocFields(getDocFields());
  }, [activeHeader, isNew, previousPolicyFlag]);

  return (
    <Fragment>
      <div className="divider mt_16">
        <div className="divider-text mb_25">Upload docs</div>

        {docFields.map((docField, index) => {
          return (
            <div className="doc_field_container" key={index}>
              <div className="doc_field">
                <div>
                  <span className="doc_name"> {docField.label}</span>{" "}
                  <span className="text-danger h6">
                    {docField.name !== "OTHERS" ? "*" : ""}
                  </span>
                  &nbsp;
                  <span className="doc_type">
                    Only {docField.fileTypes.join(", ")}
                  </span>
                </div>
                <Controller
                  name={docField.name}
                  control={control}
                  render={({ field }) => (
                    <FileSelector
                      id={docField.id}
                      file={field.value}
                      field={field}
                      fileTypes={docField.fileTypes}
                      handleFileChange={(file) => field.onChange(file)}
                    />
                  )}
                />
              </div>
              {errors[docField.name] && (
                <div className="error_class text-start mb-5">
                  {errors[docField.name].message}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default DocFields;
