import React from "react";
import "../index.scss";
import DocumentReupload from "./DocumentReupload";
import { useSelector } from "react-redux";
import DocumentVerification from "./DocumentVerification";
import QuoteShared from "./QuoteShared";
import QuoteAccepted from "./QuoteAccepted";
import DocumentVerified from "./DocumentVerified";
import CaseClosed from "./CaseClosed";

const InternalQuoteSection = () => {
  const details = useSelector(
    (state) => state.quoteRequestReducer.partnerQuoteMainModalDetails,
  );

  const { quoteState = null } = details || {};

  // Render modal section a/c to state
  const renderCurrentModal = () => {
    switch (quoteState) {
      // Documents states
      case "DOCUMENT_VERIFICATION_STARTED":
        return <DocumentVerification />;
      case "DOCUMENT_VERIFICATION":
        return <DocumentVerification />;
      case "DOCUMENT_REUPLOAD":
        return <DocumentReupload />;

      // Quote Shared
      case "SHARE_QUOTE":
        return <DocumentVerified />;
      case "QUOTE_SHARED":
      case "FILL_CUSTOMER_DETAILS":
      case "INSPECTION_PORTAL_REQUESTED":
      case "INSPECTION_DETAILS_SHARED":
      case "FILL_KYC_DOCUMENT":
      case "VERIFY_KYC_DOCUMENT":
      case "UPLOAD_POLICY_COPY":
        return <QuoteShared />;

      case "CASE_CLOSED":
        return <CaseClosed />;
      // Inspection Form
      // case "FILL_CUSTOMER_DETAILS":
      //   return <InspectionDetails />;
      // case "INSPECTION_PORTAL_REQUESTED":
      //   return <InspectionAwaited />;
      // case "INSPECTION_DETAILS_SHARED":
      //   return <InspectionProgress />;

      // Quote Accepted
      case "QUOTE_ACCEPTED":
        return <QuoteAccepted />;

      default:
        // return null;
        return <QuoteShared />;
    }
  };

  return (
    <div className="internal_quote_section_container p_24">
      {/* <p className="component_section_name mb_12">Shared Quotes</p> */}
      {renderCurrentModal()}
    </div>
  );
};

export default InternalQuoteSection;
