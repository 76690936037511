import React from "react";
import "./index.scss";
import Mail from "../../../../../assets/images/common/email.svg";
import { Info } from "react-feather";
import CustomButton from "../../../../../components/Button/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchPartnerQuoteMainModalData } from "../../../Store/store";
import useAxiosPrivate from "../../../../../axios/services";
import { toast } from "react-hot-toast";
import { POST_ACCEPT_PAYMENT } from "../../../../../axios/apiendPoint";
import FileSelector from "../../../../../components/FileSelector/FileSelector";

const PaymentLinkShared = () => {
  const dispatch = useDispatch();
  const { axiosGet2, axiosFormDataPost2 } = useAxiosPrivate();

  const currentQuote = useSelector(
    (state) => state.quoteRequestReducer.currentQuoteData,
  );
  const details = useSelector(
    (state) => state.quoteRequestReducer.partnerQuoteMainModalDetails,
  );
  const { premiumAmount = 0, email = null } = details || {};
  const [paymentProof, setPaymentProof] = React.useState(null);
  // Handle to submit Accept one quote
  const handleToSubmitPayment = async () => {
    const params = `quoteId=${currentQuote?.quoteId}`;
    if (!paymentProof) return toast.error("Please upload payment proof");

    let formData = new FormData();
    formData.append("quoteId", currentQuote?.quoteId);
    formData.append("file", paymentProof);

    // const payload = { quoteId: currentQuote?.quoteId, file: paymentProof };
    const response = await axiosFormDataPost2(POST_ACCEPT_PAYMENT, formData);
    if (response?.success) {
      toast.success(response?.msg);
      dispatch(fetchPartnerQuoteMainModalData({ axiosGet: axiosGet2, params }));
    }
  };
  return (
    <div className="mt_12 payment_container">
      <p className="component_section_name mb_12">Payment Details</p>

      <div className="p_24 background_dark_blue card_background">
        <div className="d-flex align-items-center justify-content-between py_8">
          <div className="payment_heading">Amount to be paid</div>
          <div className="payment_value">₹ {premiumAmount}</div>
        </div>
        <hr className="seperator_line m-0" />

        <div className="d-flex align-items-center justify-content-between py_8 mt_12">
          <div className="payment_heading">Payment link shared on</div>
          <div className="payment_heading">
            <img src={Mail} alt="email" />
            {email}
          </div>
        </div>

        <div className="make_payment_earliest d-flex gap-2 align-items-center justify-content-start py_8 mt_12">
          <Info size={14} className="ms-2" />
          <div>
            Please make the payment as earliest. The link is valid for 4 hours
            only.
          </div>
        </div>

        <div className="have_made_payement  mt_12">
          <div className="have_made_payment_title">
            Have you made the payment?
          </div>
          <div className="py_8 confirm_payment">
            Please make the payment as earliest. The link is valid for 4 hours
            only.
          </div>

          <div className="d-flex align-items-center py_8 mt_12 gap_8">
            <FileSelector
              id={"PaymentProof"}
              file={paymentProof}
              handleFileChange={setPaymentProof}
            />
            <CustomButton
              disabled={!paymentProof}
              style={{ height: "34px", alignItems: "center" }}
              buttonName="SUBMIT"
              handleClick={handleToSubmitPayment}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentLinkShared;
