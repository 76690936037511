import React from "react";
import LogoFull from "../../assets/images/certificates/BimaLogo.png";

const CertificateHeader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div>
        <img
          src={LogoFull}
          alt="logo"
          style={{ width: "300px", height: "70px" }}
        />
      </div>

      <div
        style={{
          border: "2px solid rgba(4, 69, 246, 0.485)",
          width: "370px",
          position: "absolute",
          right: "0px",
        }}
      ></div>
    </div>
  );
};

export default CertificateHeader;
