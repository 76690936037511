import { axiosJarvis, axiosPrivate, axiosPrivate2 } from "./axios";
import axios from "axios";
import toast from "react-hot-toast";
import { useCookies } from "react-cookie";
import { useState } from "react";
import { hideLoader, showLoader } from "./Store/store";
import { useDispatch } from "react-redux";

const useAxiosPrivate = () => {
  const [cookies] = useCookies(["token"]);
  const token = cookies?.token;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const axiosGet = async (url, params) => {
    try {
      setIsLoading(true);
      const finalUrl = params ? `${url}?${params}` : url;
      const response = await axiosPrivate.get(finalUrl, {
        headers: { Authorization: `Bearer ${token}` },
        // withCredentials: true,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.code === "INVALID_ACCESS_TOKEN") {
        if (
          !(!process.env.NODE_ENV || process.env.NODE_ENV === "development")
        ) {
          toast.error(error?.response?.data?.msg);
          document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${process.env.REACT_APP_PUBLIC_DOMAIN}; path=/;`;
          window.location.href = `${process.env.REACT_APP_LANDING_WEBSITE_URL}`;
          return null;
        }
      } else if (error?.response?.data) {
        toast.error(error?.response?.data?.msg);
      } else {
        toast.error("Somthing went wrong!");
      }
    } finally {
      setIsLoading(false);
    }
  };
  const axiosPost = async (url, data) => {
    try {
      setIsLoading(true);
      dispatch(showLoader());
      const response = await axiosPrivate.post(url, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error?.response?.data?.msg);
      }
      // else {
      //   toast.error("Somthing went wrong!");
      // }
    } finally {
      setIsLoading(false);
      dispatch(hideLoader());
    }
  };
  const axiosFormDataPost = async (url, data) => {
    try {
      setIsLoading(true);
      dispatch(showLoader());
      const response = await axiosPrivate.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error?.response?.data?.msg);
      }
      // else {
      //   toast.error("Somthing went wrong!");
      // }
    } finally {
      setIsLoading(false);
      dispatch(hideLoader());
    }
  };
  const axiosPostWithoutHeaders = async (url, data) => {
    try {
      setIsLoading(true);
      dispatch(showLoader());
      const response = await axiosPrivate.post(url, data);
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error?.response?.data?.msg);
      }
      // else {
      //   toast.error("Somthing went wrong!");
      // }
    } finally {
      setIsLoading(false);
      dispatch(hideLoader());
    }
  };
  const axiosPut = async (url, data) => {
    try {
      setIsLoading(true);
      dispatch(showLoader());
      const response = await axiosPrivate.put(
        url,
        { ...data },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error?.response?.data?.msg);
      }
      // else {
      //   toast.error("Somthing went wrong!");
      // }
    } finally {
      setIsLoading(false);
      dispatch(hideLoader());
    }
  };
  const axiosDelete = async (url) => {
    try {
      setIsLoading(true);
      dispatch(showLoader());
      const response = await axiosPrivate.delete(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error?.response?.data?.msg);
      }
      // else {
      //   toast.error("Somthing went wrong!");
      // }
    } finally {
      setIsLoading(false);
      dispatch(hideLoader());
    }
  };

  // 4000 port
  const axiosGet2 = async (url, params) => {
    try {
      setIsLoading(true);
      const finalUrl = params ? `${url}?${params}` : url;
      const response = await axiosPrivate2.get(finalUrl, {
        headers: { Authorization: `Bearer ${token}` },
        // withCredentials: true,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.code === "INVALID_ACCESS_TOKEN") {
        if (
          !(!process.env.NODE_ENV || process.env.NODE_ENV === "development")
        ) {
          toast.error(error?.response?.data?.msg);
          document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${process.env.REACT_APP_PUBLIC_DOMAIN}; path=/;`;
          window.location.href = `${process.env.REACT_APP_LANDING_WEBSITE_URL}`;
          return null;
        }
      }
      // else if (error?.response?.data) {
      //   toast.error(error?.response?.data?.msg);
      // }
      // else {
      //   toast.error("Somthing went wrong!");
      // }
    } finally {
      setIsLoading(false);
    }
  };

  // jarvis 5000 port
  const axiosJarvisApi = async (url, params) => {
    try {
      setIsLoading(true);
      const finalUrl = params ? `${url}?${params}` : url;
      const response = await axiosJarvis.get(finalUrl, {
        headers: { Authorization: `Bearer ${token}` },
        // withCredentials: true,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.code === "INVALID_ACCESS_TOKEN") {
        if (
          !(!process.env.NODE_ENV || process.env.NODE_ENV === "development")
        ) {
          toast.error(error?.response?.data?.msg);
          document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${process.env.REACT_APP_PUBLIC_DOMAIN}; path=/;`;
          window.location.href = `${process.env.REACT_APP_LANDING_WEBSITE_URL}`;
          return null;
        }
      }
    } finally {
      setIsLoading(false);
    }
  };
  // ----//
  const axiosPost2 = async (url, data) => {
    try {
      setIsLoading(true);
      dispatch(showLoader());
      const response = await axiosPrivate2.post(url, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error?.response?.data?.msg);
      }
      // else {
      //   toast.error("Somthing went wrong!");
      // }
    } finally {
      setIsLoading(false);
      dispatch(hideLoader());
    }
  };
  const axiosFormDataPost2 = async (url, data) => {
    try {
      setIsLoading(true);
      dispatch(showLoader());
      const response = await axiosPrivate2.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error?.response?.data?.msg);
      }
      // else {
      //   toast.error("Somthing went wrong!");
      // }
    } finally {
      setIsLoading(false);
      dispatch(hideLoader());
    }
  };

  const axiosCustomAll = async ({ method, baseUrl, url, data, params }) => {
    try {
      setIsLoading(true);
      dispatch(showLoader());

      const tmpUrl = `${baseUrl}/${url}`;

      const response = await axios.request({
        method,
        url: tmpUrl,
        data,
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.msg);
      }
    } finally {
      setIsLoading(false);
      dispatch(hideLoader());
    }
  };

  return {
    isLoading,
    axiosPrivate,
    axiosGet,
    axiosPost,
    axiosFormDataPost,
    axiosPostWithoutHeaders,
    axiosPut,
    axiosDelete,
    axiosGet2,
    axiosFormDataPost2,
    axiosPost2,
    axiosJarvisApi,
    axiosCustomAll,
  };
};

export default useAxiosPrivate;
