import React from "react";
import "./index.scss";
import Upload from "../../../assets/images/common/upload_btn.svg";
import { toast } from "react-hot-toast";

const UploadButton = ({
  setFileData,
  handleSubmit,
  extrHandleClick,
  isDisabled,
}) => {
  //   Function to open file browser
  const handleClick = () => document.getElementById("fileInput").click();

  const allowedFormats = ["image/jpeg", "image/png", "application/pdf"];
  const handleFileUpload = async (event) => {
    const selectedFile = event.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    if (!selectedFile) return;
    // Check if the selected file format is allowed
    if (allowedFormats.includes(selectedFile.type)) {
      // Check if the selected file size is within the allowed limit
      if (selectedFile.size <= maxSize) {
        setFileData(selectedFile);
        await handleSubmit(selectedFile);
      } else {
        // Show an error message or perform any desired validation handling
        toast.error(
          "File size exceeds the limit. Maximum file size allowed is 5MB.",
        );
        setFileData(null);
      }
    } else {
      // Show an error message or perform any desired validation handling
      toast.error("Invalid file format. Only JPEG, PNG, and PDF are allowed.");
      setFileData(null);
    }
  };

  return (
    <div className="upload_btn_container">
      <input
        accept="image/jpeg, image/png, application/pdf"
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
      <button
        onClick={() => {
          !isDisabled && handleClick();
          extrHandleClick && extrHandleClick();
        }}
        className="upload_btn"
      >
        <img src={Upload} alt="upload" />
        Upload
      </button>
    </div>
  );
};

export default UploadButton;
