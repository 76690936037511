import React from "react";
// import Upload from "../../assets/images/common/upload_btn.svg";
import { ReactComponent as UploadIcon } from "../../assets/images/common/Upload.svg";
import { ReactComponent as AttachFileIcon } from "../../assets/images/common/attached_file.svg";
import { ReactComponent as BinIcon } from "../../assets/images/common/bin_icon.svg";
import "./FileSelector.scss";
import toast from "react-hot-toast";
const FileSelector = ({
  id,
  file,
  field,
  handleFileChange,
  fileTypes = ["pdf", "png", "jpeg", "jpg"],
  fileNameToDisplay = "",
  style = {},
  title,
}) => {
  const MAX_FILE_SIZE = 5242880; // Maximum file size in MB
  const handleButtonClick = () => {
    // Trigger the hidden file input when the custom button is clicked
    document.getElementById(id).click();
  };

  const handleFileValidation = (selectedFile) => {

    if (selectedFile.size > MAX_FILE_SIZE) {
      toast.error("File size should not exceed 5MB.");
      return false;
    }
    return true;
  };

  let acceptFiles = fileTypes.map((type) => `.${type}`).join(", ");
  return (
    <div className="file_selector_container" style={style}>
      {!file ? (
        <div className="file_upload_btn_container">
          <input
            type="file"
            id={id} // Unique ID for Invoice_COPY
            style={{ display: "none" }}
            accept={acceptFiles}
            onChange={(e) => {
              const selectedFile = e.target.files[0];
              if (selectedFile && handleFileValidation(selectedFile)) {
                if (field) field.onChange(selectedFile);
                else handleFileChange(selectedFile);
              }
            }}
          />
          <button
            type="button"
            onClick={handleButtonClick} // Pass the corresponding input ID
            className="file_upload_doc_field_btn"
          >
            <UploadIcon color="#0691FC" />
            {title || "Upload"}
          </button>
        </div>
      ) : (
        <div className="file_name_container">
          <AttachFileIcon className="file_icon" width="28px" height="24px" />
          <span className="file_name">
            {fileNameToDisplay || file.name || "File Attached"}
          </span>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className="remove_file_btn"
            role="button"
            tabIndex={0}
            onClick={() => handleFileChange(null)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleFileChange(null);
              }
            }}
          >
            <BinIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileSelector;
