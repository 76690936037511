// ** Reducers Imports
import dashboardModalReducer from "../pages/Dashboard/ModalSection/modalSectionStore/store";
import dashboardRootReducer from "../pages/Dashboard/Store/store";
import trainingModalReducer from "../pages/Dashboard/TrainingSection/TrainingModal/trainingModalStore/store";
import quoteRequestReducer from "../pages/QuoteRequest/Store/store";
import newQuoteRequestReducer from "../pages/RequestNewQuote/Store/store";
import { searchBoxReducer } from "../components/SearchBox/SearchBoxReducer";
import mappingRequestReducer from "../pages/MappingRequest/Store/store";
import bookingReducer from "../pages/Bookings/Store/store";
import glossaryReducer from "../layout/Navbar/store/store";
import gridReducer from "../pages/Grid/Store/store";
import profileReducer from "../components/Profiling/store/store";
import loaderReducer from "../axios/Store/store";

const rootReducer = {
  dashboardModalReducer,
  dashboardRootReducer,
  trainingModalReducer,
  quoteRequestReducer,
  newQuoteRequestReducer,
  searchBoxReducer,
  mappingRequestReducer,
  bookingReducer,
  glossaryReducer,
  gridReducer,
  profileReducer,
  loaderReducer,
};

export default rootReducer;
