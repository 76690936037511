import React, { forwardRef } from "react";
import Flatpickr from "react-flatpickr";
import { Calendar, X } from "react-feather";
import { checkEmptyFunction } from "../../utils/utils";

const DatePicker = forwardRef(
  (
    {
      placeholder = "Select Date",
      options = {},
      value = "",
      onChange = () => {},
      className,
      style = {},
      maxDate = "",
      shortFormat = false,
      ...props
    },
    ref,
  ) => {
    if (!ref) {
      ref = React.createRef();
    }

    return (
      <div
        style={{
          position: "relative",
          display: "inline-flex",
          width: "100%",
        }}
      >
        <Flatpickr
          ref={ref}
          placeholder={placeholder}
          options={{
            dateFormat: "d-m-Y",
            maxDate: maxDate,
            disableMobile: true,
            ...options,
          }}
          className={`date-picker ${className || ""}`}
          value={value}
          onChange={onChange}
          style={{ ...style }}
          {...props}
        />

        {!checkEmptyFunction(value) ? (
          <X
            size={20}
            color="#828282"
            className="margin__30"
            onClick={() => {
              ref.current.flatpickr.clear();
            }}
            style={{
              position: "absolute",
              right: "5%",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
          />
        ) : (
          <Calendar
            color="#0691FC"
            className="margin__30"
            style={{
              position: "absolute",
              right: "5%",
              top: "50%",
              transform: "translateY(-50%)",
              pointerEvents: "none", // Prevent icon from interfering with input focus
              color: "#0090FF",
              cursor: "pointer",
            }}
            onClick={() => {
              ref.current.flatpickr.open();
            }}
          />
        )}
      </div>
    );
  },
);

DatePicker.displayName = "DatePicker";

export default DatePicker;
