import React, { useState } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../../../../axios/services";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import Home from "../../../../../assets/images/common/home.svg";
import { toast } from "react-hot-toast";
import {
  GET_PIN_CODE_CITY,
  SEND_FILL_QUOTE_DETAILS_FORM,
} from "../../../../../axios/apiendPoint";
import {
  fetchPartnerQuoteMainModalData,
  fetchQuoteRequestData,
} from "../../../Store/store";
import CustomButton from "../../../../../components/Button/CustomButton/CustomButton";
import FormInput from "../../../../../components/FormInput/FormInput";

const InspectionDetails = () => {
  const dispatch = useDispatch();
  const { axiosGet, axiosGet2, axiosPost2 } = useAxiosPrivate();

  const currentQuote = useSelector(
    (state) => state.quoteRequestReducer.currentQuoteData,
  );

  const [city, SetCity] = useState(null);

  const detailSchema = yup.object().shape({
    address: yup.string().required("Address is required"),
    pincode: yup
      .string()
      .matches(/^[0-9]{6}$/, "Pin code is not valid")
      .required("Pin code is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(detailSchema),
    defaultValues: { address: "", pincode: "" },
  });

  // Handle form submission
  const onSubmit = async (data) => {
    const payload = { ...data, quoteId: currentQuote?.quoteId };
    const response = await axiosPost2(SEND_FILL_QUOTE_DETAILS_FORM, payload);
    if (response?.success) {
      toast.success(response?.msg);
      const params = `quoteId=${currentQuote?.quoteId}`;
      dispatch(fetchPartnerQuoteMainModalData({ axiosGet: axiosGet2, params }));
      dispatch(fetchQuoteRequestData({ axiosGet: axiosGet2 }));
      reset();
    }
  };

  const handleToGetPinCode = async (e) => {
    let pincode = e.target.value;
    if (pincode.length === 6) {
      const response = await axiosGet(GET_PIN_CODE_CITY, `pincode=${pincode}`);
      if (response?.success) {
        SetCity(response.data);
      } else {
        SetCity(null);
      }
    } else SetCity(null);
  };

  return (
    <div className="mt_24">
      <div className="d-flex align-items-center gap-3 mb_12">
        <p className="component_section_name mb-0">Inspection Details</p>
        <div className="pending_badge">Pending</div>
      </div>

      <div className="quote_requeste_doc_upload_container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="my-3">
            <Controller
              control={control}
              name="address"
              render={({ field }) => (
                <FormInput
                  field={field}
                  icon={<img src={Home} alt="home" />}
                  placeholder="Enter Address"
                />
              )}
            />
            {errors.address && (
              <div className="error_class">{errors.address.message}</div>
            )}
          </div>

          <div className="my-3">
            <div className="my-3">
              <Controller
                control={control}
                name="pincode"
                render={({ field }) => (
                  <FormInput
                    field={field}
                    handleChange={handleToGetPinCode}
                    placeholder="Enter Your Area Pincode"
                  />
                )}
              />
            </div>
            {city && (
              <p className="fill_details_pin_code">{`${city.city}, ${city.state}`}</p>
            )}
            {errors.pincode && (
              <div className="error_class">{errors.pincode.message}</div>
            )}
          </div>

          <div className="d-flex align-items-center justify-content-end my-4">
            <CustomButton type="submit" alignCenter />
          </div>
        </form>
      </div>
    </div>
  );
};

export default InspectionDetails;
