import React, { useCallback, useEffect, useState } from "react";
import BookingTable from "./BookingTable";
import { useSelector, useDispatch } from "react-redux";
import { fetchBookingData, fetchBookingModalData } from "./Store/store";
import useAxiosPrivate from "../../axios/services";
import "./index.scss";
import CustomTabing from "../../components/CustomTabing/CustomTabing";
import { debounce, formatDate, isMobileDevice } from "../../utils/utils";
import BookingDataModal from "./BookingDataModal";
import FormInput from "../../components/FormInput/FormInput";
import DatePicker from "../../components/DatePicker/Datepicker";
import DropDown from "../../components/DropDown/DropDown";
import { Search } from "react-feather";
import { ReactComponent as NotFound } from "../../assets/images/common/notFound.svg";
import { CustomPagination } from "../../components/Pagination/Pagination";
import { Product } from "../../constants/Constants";
import Loader from "../../components/Loader/Loader";
import MWebBookingModal from "./MWebBookingModal";
import MobileTabing from "../../components/MobileTabing";
import BookingTableMweb from "./BookingTableMweb";

const Bookings = () => {
  const { axiosGet2 } = useAxiosPrivate();

  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [bookingId, setBookingId] = useState(null);
  const [isOpen, setIsOpen] = useState(false); // Manages modal open/close
  const [filterByDate, setFilterByDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [product, setProduct] = useState("");

  // sales bookingList
  const {
    bookingList,
    bookingBucket,
    pagination,
    loading,
    isModalLoading,
    modalData,
  } = useSelector((state) => state?.bookingReducer);

  const {
    headerData,
    vehicleDetails,
    policyDetails,
    premiumDetails,
    businessType,
    policyCopyUrl,
  } = modalData || {};

  useEffect(() => {
    if (bookingId) {
      dispatch(
        fetchBookingModalData({
          axiosGet: axiosGet2,
          searchParams: `bookingId=${bookingId}`,
        }),
      );
    }
  }, [bookingId]);

  // const loading = useSelector((state) => state?.salesBooking?.loading);

  // Debounce the search input
  const debounceSearch = useCallback(
    debounce((searchValue) => {
      if (searchValue.length >= 3) {
        setDebouncedSearch(searchValue);
      } else {
        setDebouncedSearch(""); // Clear search if less than 3 characters
      }
    }, 500),
    [],
  );

  const [currentPage, setCurrentPage] = useState(pagination?.curPage || 1);
  const [currentBucket, setcurrentBucket] = useState();
  const [debouncedSearch, setDebouncedSearch] = useState("");
  //  SEARCH LIST
  const handleSearch = (evt) => {
    const searchValue = evt.target.value;
    const validInput = searchValue.replace(/[^a-zA-Z0-9-\s]/g, "").slice(0, 15);
    setSearch(validInput);
    debounceSearch(validInput);
  };
  const handleDateChange = (date) => {
    if (date && date.length == 2) {
      setFilterByDate({ startDate: date[0], endDate: date[1] });
    }

    if (date && date.length === 0) {
      setFilterByDate({ startDate: "", endDate: "" });
    }
  };

  const handleTabChange = (newValue) => {
    if (!loading) {
      setcurrentBucket(newValue);
      setCurrentPage(1); // Reset to page 1 when bucket changes
    }
  };

  // Modal Handlers
  const handleOpenModal = (booking) => {
    setBookingId(booking?.bookingId); // Set the clicked booking
    setIsOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setBookingId(null);
    setIsOpen(false); // Close the modal
  };

  const handleProductSelect = (selectedOption) => {
    setProduct(
      selectedOption?.value === "No Product" ? "" : selectedOption?.value,
    ); // Set product or empty string
  };

  // page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Trigger API call to fetch the booking list for the selected bucket
  useEffect(() => {
    let searchParams = {};

    if (currentBucket) {
      searchParams["curBucket"] = currentBucket;
    }

    if (currentPage) {
      searchParams["curPage"] = currentPage;
    }
    if (debouncedSearch) {
      searchParams["searchQuery"] = debouncedSearch;
    }
    if (product) {
      searchParams["product"] = product;
    }

    if (filterByDate.startDate && filterByDate.endDate) {
      searchParams["startDate"] = formatDate(filterByDate.startDate);
      searchParams["endDate"] = formatDate(filterByDate.endDate);
    }

    searchParams = Object.entries(searchParams).map(
      ([key, value]) => `${key}=${value.toString()}`,
    );
    searchParams = searchParams.join("&");
    dispatch(fetchBookingData({ axiosGet: axiosGet2, searchParams }));
  }, [currentBucket, currentPage, filterByDate, debouncedSearch, product]);

  return (
    <div className="main">
      {isMobileDevice() ? (
        <div className="mobile-quote-request-card-container">
          <MobileTabing
            value={bookingBucket?.curBucket}
            handleChange={handleTabChange}
            tabList={bookingBucket?.bucketList || []}
          />
          <div
            className=""
            style={{
              margin: "0% 2%",
              width: "70%",
            }}
          >
            <div className="m-web-booking-filters-container ">
              <div style={{ width: "170px" }}>
                <FormInput
                  icon={<Search size={24} color="#0691fc" strokeWidth={1.5} />}
                  value={search}
                  onChange={handleSearch}
                  placeholder="Search"
                  style={{ height: "40px", width: "170px" }}
                />
              </div>

              <div style={{ width: "170px" }}>
                <DropDown
                  options={Product}
                  width="170px"
                  isClearable={true}
                  fontSize="16px"
                  placeholder="Select"
                  onSelect={handleProductSelect}
                />
              </div>

              <div style={{ width: "170px" }}>
                <DatePicker
                  placeholder="Date Range"
                  options={{ mode: "range" }}
                  value={
                    filterByDate.startDate && filterByDate.endDate
                      ? [filterByDate.startDate, filterByDate.endDate]
                      : ""
                  }
                  style={{ width: "170px" }}
                  onChange={handleDateChange}
                />
              </div>
            </div>

            <BookingTableMweb
              bookingList={bookingList}
              handleClick={handleOpenModal}
            />

            {isOpen && (
              <MWebBookingModal
                handleClose={handleCloseModal}
                isOpen={isOpen}
                headerData={headerData}
                vehicleDetails={vehicleDetails}
                policyDetails={policyDetails}
                premiumDetails={premiumDetails}
                policyCopyUrl={policyCopyUrl}
                businessType={businessType}
                isModalLoading={isModalLoading}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="booking-filters-item">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "400px" }}>
              <FormInput
                icon={<Search size={24} color="#0691fc" strokeWidth={1.5} />}
                value={search}
                onChange={handleSearch}
                placeholder="Type to Search"
                style={{ height: "40px" }}
              />
            </div>
            <div
              className=""
              style={{ display: "flex", gap: "20px", alignItems: "center" }}
            >
              <label htmlFor="partner-dropdown" className="filter-label">
                Filters :
              </label>
              <div>
                <DropDown
                  options={Product}
                  isClearable={true}
                  placeholder="Select Product"
                  onSelect={handleProductSelect}
                />
              </div>
              <div>
                <DatePicker
                  placeholder="Date Range"
                  options={{ mode: "range" }}
                  value={
                    filterByDate.startDate && filterByDate.endDate
                      ? [filterByDate.startDate, filterByDate.endDate]
                      : ""
                  }
                  onChange={handleDateChange}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {!isMobileDevice() &&
        (loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh", // Full height of the viewport
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div className="custom-tabing-wrapper">
              <CustomTabing
                value={bookingBucket?.curBucket}
                handleChange={handleTabChange}
                tabList={bookingBucket?.bucketList || []}
              />
            </div>

            {bookingList?.length !== 0 ? (
              <BookingTable
                bookingList={bookingList}
                handleClick={handleOpenModal}
              />
            ) : (
              <div className="notfound-card">
                <NotFound />
                <span>No Data Found</span>
              </div>
            )}
            <CustomPagination
              currentPage={currentPage}
              totalPage={pagination?.totalPage || 1}
              handlePageChange={handlePageChange}
            />

            {/* Render BookingModal conditionally */}
            {isOpen && (
              <BookingDataModal
                handleClose={handleCloseModal}
                headerData={headerData}
                vehicleDetails={vehicleDetails}
                policyDetails={policyDetails}
                premiumDetails={premiumDetails}
                policyCopyUrl={policyCopyUrl}
                businessType={businessType}
                isModalLoading={isModalLoading}
              />
            )}
          </>
        ))}
    </div>
  );
};

export default Bookings;
