import React from "react";
import QuoteDynamicHeader from "../../../../../components/QuoteDynamicHeader/QuoteDynamicHeader";
import Clock from "../../../../../assets/lottie/clock.json";

const KycVerification = () => {
  return (
    <div className="mt_12">
      <p className="component_section_name mb_12">Customer KYC Documents</p>
      <div className="card_background p_40 background_light_orange">
        <QuoteDynamicHeader
          title="Our team is verifying your KYC documents"
          subTitle="We will update you shortly on the status"
          noCard
          customImage
          lottieImage={Clock}
        />
      </div>
    </div>
  );
};

export default KycVerification;
