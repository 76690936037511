import React, { Fragment, useEffect } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../components/Modal/CustomModal/CustomModal";
import {
  fetchQuoteRequestData,
  fetchQuoteRequestUploadDocumentsData,
  toggleQuoteDocModal,
} from "../Store/store";
import CustomButton from "../../../components/Button/CustomButton/CustomButton";
import useAxiosPrivate from "../../../axios/services";
import {
  creataMultipleDocUploadPayload,
  quoteDocumentsObj,
  uploadFileAllowedFormats,
} from "../../../constants/Constants";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import { UPLOAD_QUOTE_REQUEST_DOCUMENTS } from "../../../axios/apiendPoint";
import FileSelector from "../../../components/FileSelector/FileSelector";

const DocUploadModal = () => {
  const dispatch = useDispatch();
  const { axiosGet2, axiosFormDataPost2 } = useAxiosPrivate();

  const isOpen = useSelector(
    (state) => state.quoteRequestReducer.showQuoteDocModal,
  );
  const currentQuote = useSelector(
    (state) => state.quoteRequestReducer.currentQuoteData,
  );
  const docList = useSelector(
    (state) => state.quoteRequestReducer.quoteRequestUploadDocumnetList,
  );

  const toggle = () => dispatch(toggleQuoteDocModal());

  const schema = yup.object().shape(
    docList?.reduce((acc, item) => {
      return {
        ...acc,
        [item.documentType]: yup
          .mixed()
          .required("Please select a file")
          .test("fileType", "Invalid file format", (value) => {
            if (!value) return false;
            const supportedFormats = uploadFileAllowedFormats;
            return supportedFormats.includes(value.type);
          })
          .test("fileSize", "The file is too large", (value) => {
            return value && value.size <= 5242880; // 5MB maximum file size
          }),
      };
    }, {}),
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const finalData = { ...data, quoteId: currentQuote?.quoteId };
    const formData = creataMultipleDocUploadPayload(finalData);

    const response = await axiosFormDataPost2(
      UPLOAD_QUOTE_REQUEST_DOCUMENTS,
      formData,
    );
    if (response?.success) {
      toast.success(response?.msg);
      reset();
      toggle();
      dispatch(fetchQuoteRequestData({ axiosGet: axiosGet2 }));
    }
  };

  //  Render documents for upload
  const renderQuoteDocuments = () => {
    return (
      <div>
        {docList?.map((item, index) => (
          <div key={index}>
            <Controller
              name={item.documentType}
              control={control}
              defaultValue={undefined}
              render={({ field }) => (
                <div>
                  <div className="doc_card">
                    <div className="doc_name_content">
                      <p className="doc_name mb-0">
                        {quoteDocumentsObj[item?.documentType] ??
                          item?.documentType}
                      </p>
                    </div>
                    {!field.value && (
                      <div className="doc_status_content">
                        <p className="doc_status mb-0">
                          {item.documentRejectReason}
                        </p>
                      </div>
                    )}
                    <div className="doc_btn_content">
                      <FileSelector
                        id={item.documentType}
                        file={field.value}
                        field={field}
                        handleFileChange={(file) => field.onChange(file)}
                      />
                    </div>
                  </div>
                  {errors[item.documentType] && (
                    <p className="error_class text-start mb-3 mt-0">
                      {errors[item.documentType]?.message}
                    </p>
                  )}
                </div>
              )}
            />
          </div>
        ))}
      </div>
    );
  };

  //Fetch quote request list
  const getQuoteRequestDetails = (params) =>
    dispatch(
      fetchQuoteRequestUploadDocumentsData({ axiosGet: axiosGet2, params }),
    );

  useEffect(() => {
    if (isOpen) {
      const params = `quoteId=${currentQuote?.quoteId}`;
      getQuoteRequestDetails(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuote?.quoteId, isOpen]);

  return (
    <Fragment>
      <CustomModal
        isOpen={isOpen}
        toggle={toggle}
        width="733px"
        modaltitle="Upload Documents"
        modalbody={
          <div className="quote_requeste_doc_upload_container">
            <form onSubmit={handleSubmit(onSubmit)}>
              {renderQuoteDocuments()}
              <div className="d-flex align-items-center justify-content-end">
                <CustomButton
                  type="submit"
                  alignCenter
                  className="doc_submit"
                />
              </div>
            </form>
          </div>
        }
      />
    </Fragment>
  );
};

export default DocUploadModal;
