// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isReadTheoryOpen: false,
  theoryReadStatus: true,
  isRegulationModalOpen: false,

  // Question modal states
  isQuestionModalOpen: false,
  isQuestionLoading: false,
  trainingQuestionArray: [],
};

// Fetch training modal question data
export const fetchTrainingModalQuestion = createAsyncThunk(
  "trainingModal/fetchTrainingModalQuestion",
  async (thunkparam) => {
    const { axiosGet, api } = thunkparam;
    const response = await axiosGet(api);
    return { data: response };
  },
);

export const trainingModal = createSlice({
  name: "trainingModal",
  initialState,
  reducers: {
    toggleReadTheoryModal: (state) => {
      state.isReadTheoryOpen = !state.isReadTheoryOpen;
    },
    setTheoryReadStatus: (state, action) => {
      state.theoryReadStatus = action.payload;
    },
    toggleRegulationModal: (state) => {
      state.isRegulationModalOpen = !state.isRegulationModalOpen;
    },
    toggleQuestionModal: (state) => {
      state.isQuestionModalOpen = !state.isQuestionModalOpen;
    },
  },

  extraReducers: (builder) => {
    builder
      // fetchTrainingModalQuestion
      .addCase(fetchTrainingModalQuestion.fulfilled, (state, action) => {
        state.trainingQuestionArray = action.payload?.data?.data;
        state.isQuestionLoading = false;
      })
      .addCase(fetchTrainingModalQuestion.pending, (state) => {
        state.isQuestionLoading = true;
      })
      .addCase(fetchTrainingModalQuestion.rejected, (state) => {
        state.isQuestionLoading = false;
      });
  },
});

export const {
  toggleReadTheoryModal,
  toggleRegulationModal,
  setTheoryReadStatus,
  toggleQuestionModal,
} = trainingModal.actions;

export default trainingModal.reducer;
