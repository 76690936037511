import "./IconInput.scss";

const IconInput = ({
  name,
  errors,
  icon,
  placeholder,
  isDisabled,
  defaultValue,
  register,
  handleChange,
  inputMode,
}) => {
  return (
    <div className="icon_input_container">
      <div className="wrapper">
        {icon && (
          <div
            className={
              errors && errors[name] && errors[name]
                ? "icon errro_icon"
                : "icon"
            }
          >
            {icon}
          </div>
        )}
        <input
          className={icon ? "input" : "input no_icon"}
          id={name}
          // invalid={errors && errors[name] && errors[name]}
          placeholder={placeholder}
          disabled={!!isDisabled}
          defaultValue={defaultValue}
          {...register}
          onChange={handleChange ? handleChange : null}
          inputMode={inputMode ? inputMode : "text"}
        />
        <p className="error_class">
          {errors && errors[name] && errors[name] && (
            <small> {errors[name]?.message}</small>
          )}
        </p>
      </div>
    </div>
  );
};

export default IconInput;
