import React from "react";
import QuoteDynamicHeader from "../../../../../components/QuoteDynamicHeader/QuoteDynamicHeader";
import Clock from "../../../../../assets/lottie/clock.json";
import AgencyDetails from "./AgencyDetails";

const PaymentPending = () => {
  return (
    <div className="mt_12">
      <p className="component_section_name mb_12">Payment Details</p>
      <div className="card_background p_40 background_light_orange">
        <QuoteDynamicHeader
          title="Payment Link is pending at our End!"
          subTitle="Our Team is working on it and will share the payment link with you shortly"
          noCard
          customImage
          lottieImage={Clock}
        />
      </div>

      {/* <p className="component_section_name mt_24 mb_25">Agency Details</p> */}
      <AgencyDetails />
    </div>
  );
};

export default PaymentPending;
