import React, { useState } from "react";
import { Button, Nav, NavItem, NavLink } from "reactstrap";
import "./CustomTabing.scss";

const CustomTabing = ({ value, tabList, handleChange }) => {
  const fixTabCount = 10;
  const [count, setCount] = useState(0);
  const startRange = fixTabCount * count;
  const endRange = fixTabCount * (count + 1);
  const maxCount = Math.ceil(tabList.length / fixTabCount);

  const handleTabChange = (val) => {
    setCount(count + val);
  };

  return (
    <div style={{ position: "relative" }}>
      {/* Left Scroll Button */}
      {count !== 0 && (
        <Button
          style={{
            border: "none",
            height: "70px",
            textAlign: "center",
            position: "absolute",
            zIndex: 10,
            background: "#E8F3FC ",
            boxShadow: "2px 0px 5px rgba(0, 0, 0, 0.2)",
            minWidth: "48px",
            padding: "0px",
            color: "#0691FC",
            fontSize: "12px",
            fontWeight: "600",
          }}
          onClick={() => handleTabChange(-1)}
        >
          {"< +"}
          {count * fixTabCount}
        </Button>
      )}

      {/* Tab Navigation */}
      <div
        style={{
          width: "100%",
          margin: "26px auto",
          backgroundColor: "white",
          borderRadius: "12px",
          boxShadow: "0px 1px 8px 0px #0000001A",
        }}
      >
        <Nav
          tabs
          style={{
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "space-between",
            height: "70px",
          }}
        >
          {tabList.slice(startRange, endRange).map((item, index) => (
            <NavItem
              className="Nav-items"
              key={index}
              style={{
                flex: "1 1 auto", // Flexible width
                width: "10%",
                textAlign: "center",
              }}
            >
              <div className="set-nav-link" style={{ borderRadius: "12px" }}>
                <NavLink
                  onClick={() => handleChange(item.bucket)}
                  className={`tab-set ${value === item.bucket ? "active" : ""}`}
                  style={{
                    border: "0px",
                    height: "70px",
                    color: value === item.bucket ? "#0691FC" : "",
                    padding: "10px 5px",
                    background:
                      value === item.bucket
                        ? "linear-gradient(5.64deg, #D4ECFF 5.59%, rgba(255, 255, 255, 0) 60.82%)"
                        : "white",
                  }}
                >
                  <div>
                    <h6
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: value === item.bucket ? "#0691FC" : "#00223C",
                      }}
                    >
                      {item.count}
                    </h6>
                    <p
                      style={{
                        fontSize: "11px",
                        fontWeight: "400",
                        color: "#666666",
                        lineHeight: "15px",
                      }}
                    >
                      {item.bucketName}
                    </p>
                  </div>
                </NavLink>
              </div>
            </NavItem>
          ))}
        </Nav>
      </div>

      {/* Right Scroll Button */}
      {count < maxCount - 1 && (
        <Button
          onClick={() => handleTabChange(1)}
          style={{
            height: "70px",
            position: "absolute",
            right: "0px",
            zIndex: 10,
            boxShadow: "-2px 0px 5px  rgba(0, 0, 0, 0.2)",
            top: "0%",
            minWidth: "48px",
            padding: "0px",
            background: "#E8F3FC ",
            color: "#0691FC",
            fontSize: "12px",
            fontWeight: "600",
            textAlign: "center",
            border: "none",
          }}
        >
          {"+"} {tabList.length - (count + 1) * fixTabCount} {" >"}
        </Button>
      )}
    </div>
  );
};

export default CustomTabing;
