import React from "react";
import "./index.scss";
import CustomField from "../CustomFields/CustomFields";
import CustomButton from "../Button/CustomButton/CustomButton";
import NewVeichle from "./NewVeichle";
import OldVeichle from "./OldVeichle";
import { setShowPolicyMappingModal } from "../../pages/RequestNewQuote/Store/store";
import { useDispatch } from "react-redux";

export const isVeichleState = ["Vehicle is New", "Vehicle is old"];

const MappingRequestForm = ({
  veichleState,
  setVeichleState,
  handleSubmit,
  handleToSubmit,
  errors,
  control,
  reset,
  resetField,
  veichleType,
  setVeichleType,
  activeHeader,
}) => {
  const dispatch = useDispatch();

  return (
    <div className="newQuoteRequestFormContainer ">
      <form className="min_height_form" onSubmit={handleSubmit(handleToSubmit)}>
        <div className="form_data">
          <div className="mt_16">
            <h6 className="form_header">Raise Policy Mapping</h6>
          </div>
          <div className="py_16">
            <CustomField
              value={veichleState}
              setvalue={setVeichleState}
              options={isVeichleState}
            />
          </div>
          {veichleState[0] === "Vehicle is New" ? (
            <NewVeichle
              errors={errors}
              control={control}
              setVeichleType={setVeichleType}
              veichleType={veichleType}
              activeHeader={activeHeader}
              resetField={resetField}
            />
          ) : (
            <OldVeichle
              errors={errors}
              control={control}
              setVeichleType={setVeichleType}
              veichleType={veichleType}
              activeHeader={activeHeader}
              resetField={resetField}
            />
          )}
        </div>
        <div className="new_quote_form_buttons">
          <div className="form_footer d-flex align-items-center justify-content-between">
            <CustomButton
              type="button"
              buttonName="CANCEL"
              btnNameClass="cancel_btn_name"
              className="cancel_btn"
              handleClick={() => {
                reset();
                dispatch(setShowPolicyMappingModal(false));
              }}
              alignCenter
            />
            <CustomButton
              className="submit_btn button "
              type="submit"
              alignCenter
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default MappingRequestForm;
