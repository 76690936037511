import React from "react";
import "./index.scss";
import QuoteCardMweb from "../QuoteCardMweb";
const QuoteTableMweb = ({ isLoading, data }) => {

  return (
    <div className="quote-table-mweb-container">
      <div className="quote-table-mweb">
        {data?.length > 0 &&
          data?.map((item, index) => <QuoteCardMweb key={index} data={item} />)}
      </div>
    </div>
  );
};

export default QuoteTableMweb;
