import React from "react";

const RmInfo = (rmData) => {
    const { rmName, rmCode } = rmData?.rmData || {};
    return (
        <div>
            <div className="navbar_rm_info_border d-flex  align-items-start justify-content-center cursor_pointer">
                <div className="d-flex flex-column align-items-start justify-content-center">
                    <span className="m-0 p-0 dedicated_rm">Your Dedicated RM</span>
                    <span className="m-0 p-0 rm_name">{rmName}</span>
                    <span className="m-0 p-0 rm_code">{rmCode}</span>
                </div>
            </div>
        </div>
    );
};

export default RmInfo;