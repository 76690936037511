import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import DragDrop from "../../assets/images/common/drag_drop.svg";
import { toast } from "react-hot-toast";
import { Progress } from "reactstrap";
import { X } from "react-feather";

const DragAndDropFileUpload = ({ fileData, setFileData, sentence }) => {
  const fileInputRef = useRef(null);

  const [progressValue, setProgressValue] = useState(0);
  const [currentProgressValue, setCurrentProgressValue] = useState(0);

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleUploadedFile(file);
    resetDropAreaStyle();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    // Add a visual indication for the drag enter event
    // event.target.style.border = "2px dashed #aaa";
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    // Remove the visual indication for the drag leave event
    resetDropAreaStyle();
  };

  const allowedFormats = ["image/jpeg", "image/png", "application/pdf"];
  const handleFileSelect = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      //   const file = e.target.files[0];
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes

      // Check if the selected file format is allowed
      if (allowedFormats.includes(file.type)) {
        // Check if the selected file size is within the allowed limit
        if (file.size <= maxSize) {
          //   handleFiles(e.target.files);
          handleUploadedFile(file);
          setCurrentProgressValue(
            setInterval(() => setProgressValue((prev) => prev + 1), 10),
          );
        } else {
          // Show an error message or perform any desired validation handling
          setFileData(null);
          toast.error(
            "File size exceeds the limit. Maximum file size allowed is 5MB.",
          );
        }
      } else {
        // Show an error message or perform any desired validation handling
        setFileData(null);
        toast.error(
          "Invalid file format. Only JPEG, PNG, and PDF are allowed.",
        );
      }
    }
  };

  const handleOpenFileDialog = () => {
    fileInputRef.current.click();
  };

  const handleUploadedFile = async (file) => {
    await setFileData(file);
  };

  const resetDropAreaStyle = () => {
    // const dropArea = document.getElementById("dropArea");
    // dropArea.style.border = "2px dashed #ccc";
  };

  useEffect(() => {
    if (progressValue === 100) {
      clearInterval(currentProgressValue);
    }
  }, [progressValue, currentProgressValue]);

  return (
    <div className="drag_and_drop_container">
      {fileData ? (
        <div className="uploading_container">
          <span className="uploading_text"> Uploading...</span>

          <>
            <p className="file_name_container d-flex align-items-center justify-content-between m-0 p-0">
              {fileData?.name}{" "}
              <X
                size={15}
                color="white"
                className="file_cross"
                //   onClick={handleToReset}
              />
            </p>
            <Progress className="file_progress" value={progressValue} />
          </>
        </div>
      ) : (
        <div
          id="dropArea"
          onDrop={handleDrop}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onClick={handleOpenFileDialog}
        >
          <input
            type="file"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileSelect}
            multiple
            accept="image/jpeg, image/png, application/pdf"
          />
          <label id="label-file-upload" htmlFor="input-file-upload">
            <img src={DragDrop} alt="drag_and_drop" className="drag_image" />
            <div className="d-flex align-items-center justify-content-center drag_drop_text">
              <p>{sentence} &nbsp;</p>
              <div className="drag_drop_line">
                Drag & drop your highest education marksheet (minimum 10th
                marksheet) &nbsp;
                <span className="upload-button mb-0">Browse </span>
              </div>
            </div>
            <p className="supported_format">
              Supported format: JPEG, PNG and PDF{" "}
            </p>
            <p className="supported_format">
              File size should be less than 5 Mb{" "}
            </p>
          </label>
        </div>
      )}
    </div>
  );
};

export default DragAndDropFileUpload;
