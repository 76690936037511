import React from "react";
import "./index.scss";
import { MobileTabingIcons } from "../../constants/Constants";
const MobileTabing = ({ value, tabList, handleChange }) => {
  return (
    <div className="mobile-tab-container">
      {tabList.map((tab, index) => {
        const IconComponent = MobileTabingIcons[tab.bucket];
        return (
          <div
            key={`mobile-tab-container-${index}`}
            className={`mobile-tab-item ${value === tab.bucket ? "active" : ""}`}
            onClick={() => handleChange(tab.bucket)}
          >
            <div
              className={`mobile-tab-count ${value === tab.bucket ? "active" : ""}`}
            >
              {tab.count}
            </div>
            {IconComponent && (
              <IconComponent
                className="icon"
                style={{
                  width: "16px",
                  height: "16px",
                  display: "flex",
                  justifyContent: "center",
                  padding: "0px",
                  color: value === tab.bucket ? "#0691FC" : "#000",
                }}
              />
            )}
            <div className="label">{tab.bucketName}</div>
          </div>
        );
      })}
    </div>
  );
};

export default MobileTabing;
