import React from "react";
import QuoteDynamicHeader from "../../../../../components/QuoteDynamicHeader/QuoteDynamicHeader";
import CustomButton from "../../../../../components/Button/CustomButton/CustomButton";
import {
  creataMultipleDocUploadPayload,
  quoteDocumentsObj,
  uploadFileAllowedFormats,
} from "../../../../../constants/Constants";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../../../../axios/services";
import { UPLOAD_QUOTE_REQUEST_DOCUMENTS } from "../../../../../axios/apiendPoint";
import { fetchPartnerQuoteMainModalData } from "../../../Store/store";
import FileSelector from "../../../../../components/FileSelector/FileSelector";

const DocumentReupload = () => {
  const dispatch = useDispatch();
  const { axiosGet2, axiosFormDataPost2 } = useAxiosPrivate();

  const currentQuote = useSelector(
    (state) => state.quoteRequestReducer.currentQuoteData,
  );
  const details = useSelector(
    (state) => state.quoteRequestReducer.partnerQuoteMainModalDetails,
  );
  const { reuploadDocument } = details || {};

  const schema = yup.object().shape(
    reuploadDocument?.reduce((acc, item) => {
      return {
        ...acc,
        [item.documentType]: yup
          .mixed()
          .required("Please select a file")
          .test("fileType", "Invalid file format", (value) => {
            if (!value) return false;
            const supportedFormats = uploadFileAllowedFormats;
            return supportedFormats.includes(value.type);
          })
          .test("fileSize", "The file is too large", (value) => {
            return value && value.size <= 5242880; // 5MB maximum file size
          }),
      };
    }, {}),
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const params = `quoteId=${currentQuote?.quoteId}`;
    const finalData = { ...data, quoteId: currentQuote?.quoteId };
    const formData = creataMultipleDocUploadPayload(finalData);

    const response = await axiosFormDataPost2(
      UPLOAD_QUOTE_REQUEST_DOCUMENTS,
      formData,
    );
    if (response?.success) {
      toast.success(response?.msg);
      reset();
      // dispatch(fetchQuoteRequestData({ axiosGet: axiosGet2 }));
      dispatch(fetchPartnerQuoteMainModalData({ axiosGet: axiosGet2, params }));
    }
  };

  return (
    <>
      <p className="component_section_name mb_12">Shared Quotes</p>
      <div className="card_background">
        '
        <QuoteDynamicHeader
          title="No Quotes Shared yet!"
          subTitle="Please re-upload the documents as mentioned below"
          noCard
        />
        <div className="quote_requeste_doc_upload_container p_24">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              {reuploadDocument?.map((item, index) => (
                <div key={index}>
                  <Controller
                    name={item.documentType}
                    control={control}
                    defaultValue={undefined}
                    render={({ field }) => (
                      <div>
                        <div className="doc_card">
                          <div className="doc_name_content">
                            <p className="doc_name mb-0">
                              {quoteDocumentsObj[item?.documentType] ??
                                item?.documentType}
                            </p>
                          </div>
                          {!field.value && (
                            <div className="doc_status_content">
                              <p className="doc_status mb-0">
                                {item.documentRejectReason}
                              </p>
                            </div>
                          )}

                          <div className="doc_btn_content">
                            <FileSelector
                              id={item.documentType}
                              file={field.value}
                              field={field}
                              handleFileChange={(file) => field.onChange(file)}
                            />
                          </div>
                        </div>
                        {errors[item.documentType] && (
                          <p className="error_class text-start mb-3 mt-0">
                            {errors[item.documentType]?.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
              ))}
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <CustomButton type="submit" alignCenter className="doc_submit" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DocumentReupload;
