import moment from "moment/moment";

export const formatDate = (date, format = "DD-MM-YYYY") => {
  return date ? moment(date).format(format) : "";
};

// Is Mobile device check function using device type
export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
};

export function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
export const checkEmptyFunction = (value) => {
  if (typeof value === "string") {
    return value.trim() === "";
  }
  if (value instanceof Date) {
    return isNaN(value.getTime());
  }
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  return !value;
};
