import React from "react";
import "./index.scss";
import { productTypeImageObj } from "../../../constants/Constants";
import { ChevronRight } from "react-feather";

const QuoteCardMweb = ({ data }) => {
  return (
    <div className="quote-card-mweb">
      <div className="quote-header-mweb">
        <div className="vehicle-container-mweb">
          <div className="vehicle-image-mweb">
            {productTypeImageObj[data.productType]}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="quote-id-mweb">{data.registrationNumber}</span>
            <span className="quote-id-mweb">{data.customerName}</span>
          </div>
        </div>
        <div className="view-more-container-mweb">
          <span className="view-more-mweb">View more</span>
          <div className="chevron-container">
            <ChevronRight size={20} color="#0691FC" />
          </div>
        </div>
      </div>
      <div className="quote-details-mweb">
        <div className="quote-item-mweb">
          <span className="label-mweb">Quote-Id</span>

          <span className={`value-mweb wrap ${data.quoteState.toLowerCase()}`}>
            {data.quoteId}
          </span>
        </div>
        <div className="quote-item-mweb">
          <span className="label-mweb">Date</span>
          <span className="value-mweb">
            {new Date(data.quoteCreationTime).toLocaleDateString()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default QuoteCardMweb;
