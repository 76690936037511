import React, { useEffect, useState } from "react";
import { Sidebar } from "react-pro-sidebar";
import "./index.scss";
import usericon from "../../../assets/images/navbar/Ellipse 1006.svg";
import { Button } from "reactstrap";
import callIcon from "../../../assets/images/navbar/call_symbols.svg";
import Messege from "../../../assets/images/navbar/message_icon.svg";
import ArrowBack from "../../../assets/images/navbar/arrow-back-icon.svg";
import ArrowRight from "../../../assets/images/navbar/arrow-outlined-right.svg";
import RaiseIcon from "../../../assets/images/navbar/Raise-icon.svg";
import { sidebarMenu } from "../../Sidebar/Sidebar";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Profiling from "../../../components/Profiling/Profiling";

const MobileSidebarMenu = ({ isOpen, toggle }) => {
  const handleLogout = () => {
    // clear cookies and redirect to login page
    document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${process.env.REACT_APP_PUBLIC_DOMAIN}; path=/;`;
    window.location.href = `${process.env.REACT_APP_LANDING_WEBSITE_URL}`;
  };
  const { rmData, name, email, profilePic } = useSelector(
    (state) => state.dashboardModalReducer,
  );
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);

  const handleProfile = () => {
    setProfileModalOpen(true);
  };

  const handleCloseProfile = () => {
    setProfileModalOpen(false);
  };

  return (
    <div className={`mobile-sidebar ${isOpen ? "open" : ""}`}>
      <Sidebar className="sidebar-top" style={{ width: "100%" }}>
        <div className="menu-main">
          <div
            className="Arrow-back-icon"
            style={{ position: "absolute", left: "10px", top: "3px" }}
            onClick={toggle}
          >
            <img
              src={ArrowBack}
              alt="ArrowBack"
              style={{ width: "16px", height: "16px" }}
            />
          </div>
          {/* profile-section */}
          <div className="profile-section">
            <div className="profile-inner">
              <div className="mweb-profile-image">
                <span>
                  <img
                    src={
                      profilePic
                        ? `data:image/jpeg;base64,${profilePic}`
                        : usericon
                    }
                    alt="User Icon"
                    style={{
                      width: "47px",
                      height: "47px",
                      borderRadius: "50%",
                    }}
                  />
                </span>
              </div>
              <div className="profile-name">
                <div>Hello, {name}</div>
                <div className="profile-email">{email}</div>
              </div>
              <div className="Edit-profile">
                <Button
                  onClick={handleProfile}
                  style={{
                    background: "rgba(6, 145, 252, 1)",
                    borderRadius: "20px",
                    border: "none",
                    width: "106px",
                    height: "34px",
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  Edit Profile
                </Button>
              </div>
            </div>
            <div className="rm-partner-info">
              <div style={{ marginLeft: "5%" }}>
                <span style={{ fontWeight: "400", fontSize: "14px" }}>
                  Dedicated RM:
                </span>
                <span style={{ fontWeight: "500", fontSize: "14px" }}>
                  {""} {rmData?.rmName} ( {rmData?.rmCode} )
                </span>
              </div>
              {/* <div
                className="phone-msg"
                style={{
                  marginRight: "5%",
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <span>
                  <img
                    src={callIcon}
                    alt="call Icon"
                    style={{ width: "20px", height: "20px" }}
                  />
                </span>

                <span>
                  <img
                    src={Messege}
                    alt="messege Icon"
                    style={{ width: "20px", height: "20px" }}
                  />
                </span>
              </div> */}
            </div>
          </div>
          {/*  menu-items-section  */}
          <div className="menu-sidebar-items">
            {sidebarMenu.map((item, index) => (
              <NavLink
                key={index}
                to={item.link}
                onClick={toggle}
                style={{ color: "black", textDecoration: "none" }}
              >
                <div className="inner-sidebar-items" key={index}>
                  <div className="sidebar-item-text">
                    <div style={{ width: "24px", height: "24px" }}>
                      {item.icon}
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        letterSpacing: "0%",
                        color: "rgba(0, 0, 0, 1)",
                        border: "none",
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                  <div>
                    <img
                      src={ArrowRight}
                      alt="ArrowRight"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </div>
                </div>
              </NavLink>
            ))}

            <div>
              <Button
                style={{
                  background: "rgba(6, 145, 252, 1)",
                  borderRadius: "12px",
                  border: "none",
                  width: "100%",
                  height: "42px",
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "600",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={handleLogout}
              >
                Logout
              </Button>
            </div>
          </div>
        </div>
      </Sidebar>
      {isProfileModalOpen && <Profiling toggle={handleCloseProfile} />}
    </div>
  );
};

export default MobileSidebarMenu;
