import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { isMobileDevice } from "../../utils/utils";

const CircularProgressBar = ({
  percentage,
  text,
  maxValue = 100,
  width,
  textColor,
  className = "",
}) => {
  return (
    <div
      style={{
        width: width || (isMobileDevice() ? "100px" : "69px"),
      }}
      className={`ml_24 ${className}`}
    >
      <CircularProgressbar
        value={percentage}
        text={text}
        maxValue={maxValue}
        styles={buildStyles({
          textColor: textColor || "#fff",
          textSize: "16px",
          fontWeight: "bold",
        })}
      />
    </div>
  );
};

export default CircularProgressBar;
