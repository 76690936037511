import React from "react";
import "./index.scss";
import QuoteDynamicHeader from "../../../../../components/QuoteDynamicHeader/QuoteDynamicHeader";
import Car from "../../../../../assets/lottie/car.json";

import AgencyDetails from "./AgencyDetails";

const InspectionProgress = () => {
  return (
    <>
      <div className="d-flex align-items-center gap-3 mb_12">
        <p className="component_section_name mb-0">Inspection Details</p>
        {/* <div className="pending_badge">Pending</div> */}
      </div>
      <div className="card_background p_16 pt-0">
        <QuoteDynamicHeader
          title="Inspection In Progress!"
          subTitle="Our Agency will shortly complete the inspection."
          noCard
          customImage
          lottieImage={Car}
        />

        <AgencyDetails />
      </div>
    </>
  );
};

export default InspectionProgress;
