import React, { useEffect, useState } from "react";
import CustomModal from "../Modal/CustomModal/CustomModal";
import headerImage from "../../assets/images/Profiling/Profiling-header-img.png";
import { ReactComponent as POSCertificate } from "../../assets/images/Profiling/POS-Cert.svg";
import { ReactComponent as Ellipse } from "../../assets/images/Profiling/Ellipse.svg";
import "./index.scss";
import { Progress } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileData } from "./store/store";
import useAxiosPrivate from "../../axios/services";
import { Eye, Check, X, Info } from "react-feather";
import BankDetailsModal from "../Modal/BankDetailsModal/BankDetailsModal";
import GstDetailsModal from "../Modal/GstModal/GstModal";
import { isMobileDevice } from "../../utils/utils";
import { handleDownloadCertificate } from "../Certificate/DownloadCertificate";
import Profile from "../../assets/images/navbar/Ellipse 1006.svg";
const Profiling = ({ toggle }) => {
  const profileData = useSelector((state) => state.profileReducer.profileData);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { profilePic } = useSelector((state) => state.dashboardModalReducer);

  const currentModal = useSelector(
    (state) => state.dashboardModalReducer.currentModal,
  );
  const handleClick = () => {
    setIsModalOpen(true);
  };

  const dispatch = useDispatch();
  const { axiosGet } = useAxiosPrivate();
  useEffect(() => {
    dispatch(fetchProfileData({ axiosGet, setIsOpen, toggle }));
  }, []);

  const handleColor = (value) => {
    if (value < 100) return "warning";
    else return "success";
  };
  const renderCurrentModal = () => {
    if (isModalOpen) {
      switch (currentModal) {
        case "BANK_VERIFICATION_PAGE":
          return (
            <BankDetailsModal
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(false)}
            />
          );
        case "CANCEL_CHEQUE_UPLOAD_PAGE":
          return (
            <BankDetailsModal
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(false)}
            />
          );
        case "GST_REGISTRATION_PAGE":
          return (
            <GstDetailsModal
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(false)}
            />
          );

        default:
          return null;
      }
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      background={"#F9F9F9"}
      noModalPadding={"true"}
      nopadding={"true"}
      fullHeight={"true"}
      souldHaveborderRadius={"true"}
      // modalsize={"xl"}
      maxWidth={"940px"}
      height={isMobileDevice() ? "100vh ! important " : "auto"}
      modalbody={
        <div className="profiling-container">
          {/* {downloadCertificate && (
            <CertificatePage certificateDetails={profileData} />
          )} */}
          {renderCurrentModal()}
          {/* Header Section */}
          <div className="profiling-header">
            <img
              src={headerImage}
              alt="header-img"
              className="profiling-header-image"
            />
            <div className="profiling-x" onClick={toggle}>
              <X size={20} />
            </div>
          </div>

          {/* Profile Information Section */}
          <div className="profiling-body">
            {isMobileDevice() ? (
              <div className="profiling-body-top">
                <div className="profiling-info">
                  <div className="profile-image">
                    <img
                      src={
                        profilePic
                          ? `data:image/jpeg;base64,${profilePic}`
                          : Profile
                      }
                      alt="Profile"
                      className="profile-img"
                    />
                    <span className="profile-id">{profileData?.partnerId}</span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      marginLeft: "12px",
                    }}
                  >
                    <h1 className="profile-name">{profileData?.name}</h1>
                    <p className="profile-joined">
                      Joined on {profileData?.doj}
                      <br />
                      {profileData?.email}
                    </p>

                    {/* Profile Progress */}
                    <div className="profile-progress">
                      <Progress
                        value={profileData?.progress}
                        color={handleColor(profileData?.progress)}
                        style={{ height: "6px" }}
                      />
                      <div>
                        <span>{profileData?.progress}</span>% Profile Completed
                      </div>
                    </div>
                  </div>
                </div>

                {/* POS Certificate Button (Full width on mobile) */}
                <button
                  className="pos-certificate-btn"
                  onClick={() => handleDownloadCertificate(profileData)}
                  style={{
                    width: "100%",
                    marginTop: "16px",
                  }}
                >
                  <POSCertificate size={16} /> My POS Certificate
                </button>
              </div>
            ) : (
              <div className="profiling-body-top">
                {/* Profile Image and POS Certificate */}
                <div className="profiling-info">
                  <div className="profile-image">
                    <img
                      src={
                        profilePic
                          ? `data:image/jpeg;base64,${profilePic}`
                          : Profile
                      }
                      alt="Profile"
                      className="profile-img"
                    />
                    <span className="profile-id">{profileData?.partnerId}</span>
                  </div>
                  <button
                    className="pos-certificate-btn"
                    onClick={() => handleDownloadCertificate(profileData)}
                  >
                    <POSCertificate size={16} />
                    My POS Certificate
                  </button>
                </div>

                {/* Name, Joining Date, and Profile Progress */}
                <div className="profiling-summary">
                  <div>
                    <h1 className="profile-name">{profileData?.name}</h1>
                    <p className="profile-joined">
                      Joined on {profileData?.doj} &nbsp;
                      <Ellipse />
                      &nbsp;
                      {profileData?.email}
                    </p>
                  </div>
                  <div className="profile-progress">
                    <Progress
                      value={profileData?.progress}
                      color={handleColor(profileData?.progress)}
                      style={{ height: "6px" }}
                    />
                    <div>
                      <span>{profileData?.progress}</span>% Profile Completed
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Details Section */}
            <div className="profiling-details">
              {/* Bank and GST Information */}
              <div className="detail-section">
                {!profileData?.bankAccountNumber && (
                  <div className="bank-details profiling-card-container profiling-red">
                    <div
                      className="bank-details-top-container"
                      style={{ color: "#DF0000" }}
                    >
                      <h3>Bank Info</h3>
                      <p className="profiling-red-text">
                        {" "}
                        <X size={14} style={{ marginRight: "10px" }} />
                        Not Updated
                      </p>
                    </div>
                    <div
                      className="profiling-bank-details-button"
                      onClick={() => handleClick()}
                    >
                      Update Bank Details
                    </div>
                  </div>
                )}
                {profileData?.bankAccountNumber && (
                  <div className="bank-details profiling-card-container">
                    <div>
                      <h3>Bank Details</h3>
                      {!profileData?.isBankOpsVerified && (
                        <p className="profiling-yellow">
                          Please wait while out operations team is checking this
                        </p>
                      )}
                      <p
                        className={
                          profileData?.isBankOpsVerified
                            ? "profiling-green-text"
                            : "profiling-small-text"
                        }
                      >
                        {!profileData?.isBankOpsVerified && (
                          <Info
                            size={14}
                            style={{ marginRight: "10px" }}
                            color="#ff9500"
                          />
                        )}{" "}
                        Account number - {profileData?.bankAccountNumber}
                      </p>

                      <p className="profiling-small-text">
                        {profileData?.isBankOpsVerified ? (
                          <Check
                            size={14}
                            color="#23963D"
                            style={{ marginRight: "10px" }}
                          />
                        ) : (
                          <Info
                            size={14}
                            style={{ marginRight: "10px" }}
                            color="#ff9500"
                          />
                        )}
                        IFSC code - {profileData?.ifscCode}
                      </p>
                    </div>
                  </div>
                )}
                <div
                  className={`gst-info profiling-card-container ${profileData?.gstUploaderShow ? "profiling-red" : ""}`}
                >
                  <div>
                    <h3>GST Info</h3>
                    {profileData?.gstUploaderShow ? (
                      <p className="profiling-red-text">
                        <X size={14} style={{ marginRight: "10px" }} />
                        Not Updated
                      </p>
                    ) : profileData?.gstNumber ? (
                      <div>
                        <p className="profiling-green-text">
                          GST Number - {profileData?.gstNumber}
                        </p>
                        <p className="profiling-small-text">
                          <Check
                            size={14}
                            color="#23963D"
                            style={{ marginRight: "10px" }}
                          />
                          Gst Business Name - {profileData?.gstBusinessName}
                        </p>
                      </div>
                    ) : (
                      <p className="profiling-small-text">
                        <Check
                          size={14}
                          color="#23963D"
                          style={{ marginRight: "10px" }}
                        />
                        No gst number provided
                      </p>
                    )}
                  </div>
                  {profileData?.gstUploaderShow && (
                    <div className="gst-button-container">
                      <div
                        className="profiling-bank-details-button"
                        onClick={() => handleClick()}
                      >
                        Update GST Details
                      </div>
                      <div
                        className="gst-underline"
                        onClick={() => handleClick()}
                      >
                        Don’t have GST
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Other Information */}
              <div className="detail-section ">
                <div className="pan-info other-info">
                  <h3>PAN Info</h3>
                  <h4>{profileData?.panNumber}</h4>
                  <p className="profiling-small-text">
                    {" "}
                    <Check
                      size={14}
                      color="#23963D"
                      style={{ marginRight: "10px" }}
                    />{" "}
                    IIB portal check{" "}
                  </p>
                  <p>
                    {" "}
                    <Check
                      size={14}
                      color="#23963D"
                      style={{ marginRight: "10px" }}
                    />{" "}
                    POS portal check{" "}
                  </p>
                </div>
                <div className="aadhaar-info other-info">
                  <h3>Aadhaar Info</h3>
                  <h4>{profileData?.aadharNumber}</h4>
                  <p>
                    <Check
                      size={14}
                      color="#23963D"
                      style={{ marginRight: "10px" }}
                    />{" "}
                    Aadhaar linked with PAN{" "}
                  </p>
                </div>
                <div className="education-doc other-info">
                  <div>
                    <h3>Education Doc</h3>
                    <h4>10th Certificate</h4>
                    <p>
                      <Check
                        size={14}
                        color="#23963D"
                        style={{ marginRight: "10px" }}
                      />{" "}
                      Verified{" "}
                    </p>
                  </div>

                  <a
                    href={profileData?.marksheetUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Eye color=" #0691FC" size={16} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default Profiling;
