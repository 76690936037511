import React, { useState } from "react";
import "./index.scss";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import LogoSmall from "../../assets/logo/logo_small.svg";
import LogoFull from "../../assets/logo/logo_full.svg";
import { Home, Percent, PlusCircle } from "react-feather";
import { navRoutes } from "../../router/Url";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowNewQuoteRequestMenu,
  setShowRequestNewQuoteModal,
  setShowPolicyMappingModal,
} from "../../pages/RequestNewQuote/Store/store";
import NewQuoteRequest from "../../assets/images/NewQuoteRequest/NewQuoteRequest.svg";
import RaisePolicyMapping from "../../assets/images/NewQuoteRequest/RaisePolicyMapping.svg";
import BlurOverlay from "../../components/BlurOverlay/BlurOverlay";
import RequestNewQuoteModal from "../../pages/RequestNewQuote";
import { ReactComponent as QuoteRequestIcon } from "../../assets/images/sidebar/quoteRequestIcon.svg";
import { ReactComponent as MappingRequestIcon } from "../../assets/images/sidebar/mappingRequestIcon.svg";
import { ReactComponent as BookingIcon } from "../../assets/images/sidebar/bookingIcon.svg";
import GridNewLogo from "../../assets/images/sidebar/gridNewLogo.gif";
import CreatePolicyMappingRequest from "../../components/CreatePolicyMappingRequest/CreatePolicyMappingRequest";
export const sidebarMenu = [
  {
    name: "Dashboard",
    mobileName: "Dashboard",
    icon: <Home />,
    link: navRoutes.defaultPage,
    isRegisteredRequired: false,
  },
  {
    name: "Quote Requests",
    mobileName: "Quote",
    icon: <QuoteRequestIcon />,
    link: navRoutes.quoteRequestPage,
    isRegisteredRequired: true,
  },

  {
    name: "Mapping Requests",
    mobileName: "Mapping",
    icon: <MappingRequestIcon />,
    link: navRoutes.mappingRequestPage,
    isRegisteredRequired: true,
  },
  {
    name: "Bookings",
    // mobileName: "Bookings",
    icon: <BookingIcon />,
    link: navRoutes.bookingsPage,
    isRegisteredRequired: true,
  },
  {
    name: "Grid",
    mobileName: "Grid",
    icon: <Percent />,
    link: navRoutes.grid,
    isRegisteredRequired: true,
  },
];
const CustomSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [collapsed] = useState(false);

  const showNewQuoteMenu = useSelector(
    (state) => state.newQuoteRequestReducer.showNewQuoteRequestMenu,
  );
  const showPolicyMappingModal = useSelector(
    (state) => state.newQuoteRequestReducer.showPolicyMappingModal,
  );
  const isPartnerRegistered = useSelector(
    (state) => state.dashboardModalReducer.isPartnerRegistered,
  );
  // const handleMouseEnter = () => {
  //   if (collapsed) setCollapsed(false);
  // };

  // const handleMouseLeave = () => {
  //   if (!collapsed) setCollapsed(true);
  // };

  const handleToNavigate = (link) => navigate(link);

  return (
    <div className="layout_sidebar">
      <Sidebar
        // width="255px"
        collapsed={collapsed}
        // onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
        className="custom_sidebar_container"
      >
        <div className="d-flex align-items-center justify-content-start">
          <img
            src={collapsed ? LogoSmall : LogoFull}
            alt="logo"
            className={collapsed ? "logo_small" : "logo_full"}
            onClick={() => handleToNavigate(navRoutes.defaultPage)}
          />
        </div>
        <div
          className={`sidenav_btn_container ${collapsed ? "collapsed" : ""}`}
        >
          <button
            className={`sidenav_btn`}
            onClick={() => dispatch(setShowNewQuoteRequestMenu())}
            disabled={!isPartnerRegistered}
          >
            <PlusCircle />
            <span className="">Raise New Request</span>
          </button>
          {showNewQuoteMenu && (
            <>
              <div className="new_quote_request_menu cursor_pointer">
                <div
                  className="menu_item menu_bottom"
                  onClick={() => dispatch(setShowRequestNewQuoteModal())}
                >
                  <img
                    src={NewQuoteRequest}
                    alt="new quote request"
                    className="me-2"
                  />{" "}
                  <span className="menu_heading">New Quote Request</span>
                </div>
                <div
                  className="menu_item"
                  onClick={() => dispatch(setShowPolicyMappingModal())}
                >
                  <img
                    src={RaisePolicyMapping}
                    alt="new quote request"
                    className="me-2"
                  />
                  <span className="menu_heading"> Raise Policy Mapping</span>
                </div>
              </div>
              <BlurOverlay
                isBlur={true}
                onOuterClick={() => dispatch(setShowNewQuoteRequestMenu())}
                position="fixed"
                top="0"
                left="0"
                width="100%"
                height="100%"
              />
            </>
          )}
        </div>
        <Menu
          className="sidebar_menu"
          menuItemStyles={{
            button: {
              // the active class will be added automatically by react router
              // so we can use it to style the active menu item
              [`&.active`]: {
                background: "#E8F3FC",
                borderRadius: "30px",
                color: "#0691FC",
              },
              "&:hover": {
                borderRadius: "30px",
                color: "#0691FC",
              },
            },
          }}
        >
          {sidebarMenu.map((menu, index) => (
            <MenuItem
              key={index}
              icon={menu.icon}
              component={
                menu.isRegisteredRequired && !isPartnerRegistered ? (
                  <div className="cursor_pointer disabled_menu">
                    {menu.name}
                  </div>
                ) : (
                  <NavLink
                    className={(navData) => (navData.isActive ? "active" : "")}
                    to={menu.link}
                  />
                )
              }
            >
              {menu.name}{" "}
              {menu.name === "Grid" ? (
                <div className="">
                  <img
                    src={GridNewLogo}
                    alt="grid-logo"
                    style={{
                      width: "50px",
                      height: "50px ",
                      position: "absolute",
                      top: "-2px ",
                      left: "100px",
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </MenuItem>
          ))}
        </Menu>
      </Sidebar>
      <RequestNewQuoteModal />
      {showPolicyMappingModal && (
        <CreatePolicyMappingRequest
          toggle={() => dispatch(setShowPolicyMappingModal())}
        />
      )}
    </div>
  );
};

export default CustomSidebar;
