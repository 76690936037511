import Bookings from "../pages/Bookings";
import Dashboard from "../pages/Dashboard/Dashboard";
import MappingRequest from "../pages/MappingRequest";
import QuoteRequest from "../pages/QuoteRequest";
import { navRoutes } from "./Url";
import GridPage from "../pages/Grid";
import FavoritesPage from "../pages/Grid/FavoritesPage/FavoritesPage";

// ** Merge Routes
export const AllRoutes = [
  {
    path: navRoutes.defaultPage,
    element: <Dashboard />,
  },
  {
    path: navRoutes.dashboardPage,
    element: <Dashboard />,
  },
  {
    path: navRoutes.quoteRequestPage,
    element: <QuoteRequest />,
    isRegisteredRequired: true,
  },
  {
    path: navRoutes.bookingsPage,
    element: <Bookings />,
    isRegisteredRequired: true,
  },
  {
    path: navRoutes.mappingRequestPage,
    element: <MappingRequest />,
    isRegisteredRequired: true,
  },
  {
    path: navRoutes.grid,
    element: <GridPage />,
    isRegisteredRequired: true,
  },
  {
    path: navRoutes.gridFavorites,
    element: <FavoritesPage />,
    isRegisteredRequired: true,
  },
];
